
// Vue
import Vue from "vue";
import {computed, defineComponent, ref, watch} from "vue";

// Helpers
import BarredoresDesconectados from "@/helpers/BarredoresDesconectados";

// Tipos
import {Barredor} from "@/typings/store/plugins/easyFirestore/barredores";
import {DataAlertasBarredores} from "@/typings/components/alertasBarredores/alertasBarredores";

// Componentes
import InfoBarredor from "@/components/alertasBarredores/mapMarkers/InfoBarredor.vue";

export default defineComponent({
  name: "MapMarkers",
  components: {
    InfoBarredor,
  },
  props: {
    barredores: {
      required: true,
      type: Array as () => Barredor[],
    },
    desconectados: {
      required: true,
      type: Object as () => BarredoresDesconectados,
    },
    dataAlertasBarredores: {
      required: true,
      type: Object as () => DataAlertasBarredores,
    },
  },
  setup(props) {
    // Data

    const infoWindows = ref<{ [key: string]: boolean }>({});

    // Computed

    const barredoresWatch = computed(() => props.barredores);

    // Methods

    const onClickMarker = ({id}: Barredor) => {
      if (!id) return;
      for (const barredor of props.barredores) {
        if (!barredor.id) continue;
        Vue.set(infoWindows.value, barredor.id, false);
      }
      Vue.set(infoWindows.value, id, true);
    };

    const estaDesconectado = ({id}: Barredor) => props.desconectados.ids.includes(id ?? "");

    const getPosicion = ({id}: Barredor) => id ? props.dataAlertasBarredores[id]?.posicion ?? null : null;

    // Watch

    watch(barredoresWatch, (value) => {
      for (const barredor of value) {
        if (!barredor.id) continue;
        Vue.set(infoWindows.value, barredor.id, false);
      }
    }, {
      immediate: true,
    });

    return {
      // Data
      infoWindows,
      // Methods
      getPosicion,
      onClickMarker,
      estaDesconectado,
    };
  },
});
