
//decoradores
import { Component, Emit, Prop, VModel, Vue } from "vue-property-decorator";
//tipos
import { Permisos } from "@/typings/store/modules/permisos";
import { TipoServicio } from "../../typings/store/plugins/easyFirestore/servicios";
import { FiltrosServicio } from "../../typings/components/servicios/filtros";
//componentes
import AddButton from "@/components/custom/AddButton.vue";
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: {
    AddButton,
    SvgIcon,
  },
})
export default class FiltrosServicios extends Vue {
  @VModel() filtros!: FiltrosServicio;
  @Prop() buttonDisabled!: boolean;
  @Prop() readonly permisos!: Permisos;

  get mostrarBotonAgregar(): boolean {
    return this.permisos.servicios !== "ver";
  }

  get tipos(): Array<{ text: string; value: TipoServicio }> {
    return [
      {
        text: this.$t("servicios.filtro.tipo.values[0]") as string,
        value: "Egreso",
      },
      {
        text: this.$t("servicios.filtro.tipo.values[1]") as string,
        value: "Ingreso",
      },
    ];
  }

  get search(): string {
    return this.filtros.search;
  }

  set search(value: string) {
    this.filtros.search = value;
  }

  get filtroTipo(): TipoServicio | null {
    return this.filtros.tipo;
  }

  set filtroTipo(value: TipoServicio | null) {
    this.filtros.tipo = value;
  }

  @Emit("click:agregar")
  onClickAgregar(): void {
    return;
  }
}
