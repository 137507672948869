
// decoradores
import { Component, Emit, VModel, Vue } from "vue-property-decorator";
// componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
// tipos
import { EstadoUsuario } from "@/typings/store/plugins/easyFirestore/usuarios";

@Component({
  components: { SvgIcon },
})
export default class FiltrosUsuario extends Vue {
  @VModel() buscar!: string;

  estado: EstadoUsuario | null | undefined = null;

  get estados(): Array<{
    text: string;
    value: "habilitado" | "deshabilitado";
  }> {
    return [
      {
        text: this.$t("users.filters.estado.values.0") as string,
        value: "habilitado",
      },
      {
        text: this.$t("users.filters.estado.values.1") as string,
        value: "deshabilitado",
      },
    ];
  }

  @Emit("cambio-estado")
  onCambioEstado(): EstadoUsuario | null {
    return this.estado ?? null;
  }
}
