
// composition-api
import { defineComponent, computed, PropType } from "vue";
//tipos
import { Pesaje } from "@/typings/store/plugins/easyFirestore/pesajes";
import { PesajeMonitoreoDesecho } from "@/typings/components/monitoreo/desecho";
// composables
import { useKgM3 } from "@/composables/kgM3";
//componentes
import TipoDesechos from "@/components/monitoreo/desechos/Tipo.vue";
import TotalDesechos from "@/components/monitoreo/desechos/Total.vue";
import TipoClienteDesechos from "@/components/monitoreo/desechos/TipoCliente.vue";
import TotalExtendidoDesechos from "@/components/monitoreo/desechos/TotalExtendido.vue";

export default defineComponent({
  components: {
    TipoClienteDesechos,
    TipoDesechos,
    TotalExtendidoDesechos,
    TotalDesechos,
  },
  props: {
    arrayPesajes: {
      type: Array as PropType<Pesaje[]>,
      required: true,
    },
    periodo: {
      type: Number as PropType<number>,
      required: true,
    },
    fechas: {
      type: Array as PropType<Date[]>,
      required: true,
    },
    unidadDesechos: {
      type: String as PropType<"kg" | "m3" | "t">,
      required: true,
    },
  },
  setup(props) {
    const _kgM3 = useKgM3();

    const valor = (pesoNeto: number, kgM3final: number) => {
      if (props.unidadDesechos !== "m3") {
        return pesoNeto;
      }
      return kgM3final >= 0 ? kgM3final : 0;
    };

    const items = computed((): Array<PesajeMonitoreoDesecho> => {
      const result: Array<PesajeMonitoreoDesecho> = [];
      for (const pesaje of props.arrayPesajes) {
        const fecha = pesaje.entrada.fecha.toDate();
        const tipoCliente = pesaje.cliente?.tipo;
        const tipoDesecho = pesaje.desecho?.nombre;
        const valores = pesaje.cliente?.id
          ? _kgM3.get.value[pesaje.cliente.id]
          : null;
        const kgM3 = valores?.[fecha.getTime()] ?? 0;
        const pesosMayoresCero =
          pesaje.salida.peso > 0 && pesaje.entrada.peso > 0;
        const pesoNeto = pesosMayoresCero
          ? Math.abs(pesaje.salida.peso - pesaje.entrada.peso)
          : 0;
        const kgM3final = _kgM3.getM3(
          pesoNeto,
          pesaje.capacidad,
          pesaje.cobro,
          kgM3
        );
        const _valor = valor(pesoNeto, kgM3final);
        const item: PesajeMonitoreoDesecho = {
          fecha,
          tipoCliente,
          tipoDesecho,
          valor: _valor,
          pesaje,
        };
        result.push(item);
      }
      return result;
    });

    return { items };
  },
});
