
// decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// tipos
import { Desecho } from "@/typings/store/plugins/easyFirestore/desechos";
import { ItemTablaDesechos } from "@/typings/components/desechos/tabla";
// componentes
import IconButton from "@/components/custom/IconButton.vue";

@Component({
  components: {
    IconButton,
  },
})
export default class OpcionesTabla extends Vue {
  @Prop() readonly item!: ItemTablaDesechos;
  @Prop() readonly seleccionado!: Desecho | null;

  get isSelected(): boolean {
    return this.seleccionado !== null && this.seleccionado.id === this.item.id;
  }

  @Emit("click:informacion")
  onClickInfo(): ItemTablaDesechos {
    return this.item;
  }
}
