
// Vue
import Vue from "vue";
import {computed, defineComponent, onUnmounted, ref} from "vue";

// Helpers
import routeGuard from "@/helpers/routeGuard";
import BarredoresDesconectados from "@/helpers/BarredoresDesconectados";

// Vuex
import {mapGetters} from "vuex";

// Tipos
import {Empresa} from "@/typings/store/plugins/easyFirestore/empresas";
import {Barredor} from "@/typings/store/plugins/easyFirestore/barredores";
import {PingRastreador} from "@/typings/store/plugins/easyFirestore/pingsRastreador";
import {DataAlertaBarredor, DataAlertasBarredores} from "@/typings/components/alertasBarredores/alertasBarredores";

// Componentes
import MapMarkers from "@/components/alertasBarredores/MapMarkers.vue";
import AlertaBarredores from "@/components/alertasBarredores/AlertaBarredores.vue";
import DialogoDesconectados from "@/components/alertasBarredores/DialogoDesconectados.vue";

export default defineComponent({
  name: "BarredoresAlerta",
  components: {
    MapMarkers,
    AlertaBarredores,
    DialogoDesconectados,
  },
  beforeRouteEnter(_to, _from, next) {
    next(routeGuard);
  },
  created() {
    if (!routeGuard()) return false;
  },
  setup: function () {
    // Vuex

    const getters = mapGetters({
      getEmpresa: "usuario/getEmpresa",
      getArrayBarredores: "barredores/getArray",
      getArrayPingsRastreador: "pingsRastreador/getArray",
    });

    const empresa = computed<Empresa>(getters.getEmpresa);
    const barredores = computed<Barredor[]>(getters.getArrayBarredores);
    const pings = computed<PingRastreador[]>(getters.getArrayPingsRastreador);

    // Data

    const zoom = ref(14);
    const fecha = ref(new Date());
    const dialogoDesconectados = ref(false);
    const intervalId = window.setInterval(() => fecha.value = new Date(), 1000 * 60);

    // Computed

    const horaActual = computed(() => fecha.value.format("HH:mm"));

    const mapCenter = computed(() => empresa.value.posicion ?? {
      lat: -35.4265524,
      lng: -71.6659902,
    });

    const pingsOrdenados = computed(() => {
      const copiaPings = pings.value.slice();
      copiaPings.sort((a, b) => {
        const fechaA = new Date(a.fecha).getTime();
        const fechaB = new Date(b.fecha).getTime();
        return fechaB - fechaA;
      });
      return copiaPings;
    });

    const dataAlertasBarredores = computed<DataAlertasBarredores>(() => {
      const result: DataAlertasBarredores = {};
      let i = 0;
      for (const ping of pingsOrdenados.value) {
        for (const barredor of ping.barredores) {
          const id = barredor.id;
          // Si el barredor no tiene id o si la tiene pero ya existe en la variable result continua con el siguiente barredor
          if (!id || (id && result[id])) continue;
          const data: DataAlertaBarredor = {
            posicion: ping.posicion,
            fecha: new Date(ping.fecha),
          };
          Vue.set(result, id, data);
          i++;
        }
        // Si ya se han agregado todos los barredores a result se termina el for
        if (i === barredores.value.length) break;
      }
      return result;
    });

    const barredoresValidos = computed(() => barredores.value.filter(_barredor => {
      const [horaInicio, horaFin] = _barredor.horario.split("-");
      const flagHorario = horaActual.value >= horaInicio && horaActual.value <= horaFin;
      const flagData = _barredor.id && !!dataAlertasBarredores.value[_barredor.id];
      return flagHorario && flagData;
    }));

    const desconectados = computed<BarredoresDesconectados>(() => {
      const barredoresDesconectados = new BarredoresDesconectados();
      for (const barredor of barredores.value) {
        if (!barredor.id) continue;
        const [horaInicio, horaFin] = barredor.horario.split("-");
        if (horaActual.value >= horaInicio && horaActual.value <= horaFin) {
          const data = dataAlertasBarredores.value[barredor.id];
          if (!data) {
            barredoresDesconectados.desconectados.push(barredor);
            continue;
          }
          const diferencia = fecha.value.getTime() - data.fecha.getTime();
          const minutosAlerta = empresa.value.alertaBarredores;
          if (diferencia > 1000 * 60 * minutosAlerta) barredoresDesconectados.hibernando.push(barredor);
        }
      }
      barredoresDesconectados.desconectados.sort((a, b) => a.numero - b.numero);
      barredoresDesconectados.hibernando.sort((a, b) => a.numero - b.numero);
      return barredoresDesconectados;
    });

    // Unmounted

    onUnmounted(() => {
      window.clearInterval(intervalId);
    });

    return {
      // Data
      zoom,
      fecha,
      dialogoDesconectados,
      // Computed
      empresa,
      mapCenter,
      barredores,
      desconectados,
      pingsOrdenados,
      barredoresValidos,
      dataAlertasBarredores,
    };
  },
});
