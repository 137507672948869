
// composition-api
import { defineComponent, computed } from "vue";

export default defineComponent({
  props: {
    value: Boolean,
  },
  emits: ["input"],
  setup(props, ctx) {
    const vModel = computed({
      get() {
        return props.value;
      },
      set(val) {
        ctx.emit("input", val);
      },
    });
    return {
      vModel,
    };
  },
});
