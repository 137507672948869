
// vue
import { defineComponent, computed, ref, reactive } from "vue";
// composables
import { useI18n } from "@/composables/i18n";
//tipos
import { Archivo } from "@/typings/store/plugins/easyFirestore/archivos";
import { Vehiculo } from "@/typings/store/plugins/easyFirestore/vehiculos";
import {
  EstadoIdentificador,
  Identificador,
} from "@/typings/store/plugins/easyFirestore/identificadores";
// componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
// validaciones
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default defineComponent({
  components: { SvgIcon },
  props: {
    identificador: {
      default: null,
      type: Object as () => Identificador | null,
    },
    cargando: {
      default: false,
      type: Boolean,
    },
    arrayVehiculos: {
      default: () => [],
      type: Array as () => Vehiculo[],
    },
  },
  created(): void {
    if (this.identificador) {
      this.id = this.identificador.id ?? "";
      this.nombre = this.identificador.nombre;
      this.epc = this.identificador.epc;
      this.vehiculo = this.identificador.vehiculo;
      this.estado = this.identificador.estado;
      this.fotos = this.identificador.fotos;
    }
  },
  emits: ["click:guardar"],
  setup(props, ctx) {
    const { t } = useI18n();

    const id = ref("");
    const nombre = ref("");
    const epc = ref("");
    const vehiculo = ref<Vehiculo | null>(null);
    const estado = ref<EstadoIdentificador>("habilitado");
    const fotos = ref<Array<Archivo>>([]);

    const titulo = computed((): string => {
      const index = props.identificador ? 0 : 1;
      const textos = t(
        `transporte.tab.identificadores.formulario.title`
      ) as unknown as string[];
      return textos[index];
    });

    const textoBoton = computed((): string => {
      const index = props.identificador ? 0 : 1;
      const textos = t(
        `transporte.tab.identificadores.formulario.boton.text`
      ) as unknown as string[];
      return textos[index];
    });

    const state = reactive({
      nombre,
      epc,
      vehiculo,
    });

    const rules = {
      nombre: { required },
      epc: { required },
      vehiculo: { required },
    };

    const v$ = useVuelidate(rules, state);

    const erroresNombre = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.nombre.$dirty) return errores;
      if (v$.value.nombre.required.$invalid)
        errores.push(
          t(
            "transporte.tab.identificadores.formulario.nombre.error-messages[0]"
          ) as string
        );
      if (nombre.value.length > 15)
        errores.push(
          t(
            "transporte.tab.identificadores.formulario.nombre.error-messages[1]"
          ) as string
        );
      return errores;
    });

    const erroresEPC = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.epc.$dirty) return errores;
      if (v$.value.epc.required.$invalid)
        errores.push(
          t(
            "transporte.tab.identificadores.formulario.epc.error-messages[0]"
          ) as string
        );
      if (epc.value.length > 24)
        errores.push(
          t(
            "transporte.tab.identificadores.formulario.epc.error-messages[1]"
          ) as string
        );
      return errores;
    });

    const erroresVehiculo = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.vehiculo.$dirty) return errores;
      if (v$.value.vehiculo.required.$invalid)
        errores.push(
          t(
            "transporte.tab.identificadores.formulario.vehiculo.error-message"
          ) as string
        );
      return errores;
    });

    const guardar = (): void => {
      v$.value.$touch();
      if (
        v$.value.$invalid ||
        nombre.value.length > 15 ||
        epc.value.length > 24 ||
        !vehiculo.value
      )
        return;
      const identificador: Identificador = {
        nombre: nombre.value,
        epc: epc.value,
        vehiculo: vehiculo.value,
        estado: estado.value,
        fotos: fotos.value,
      };
      if (id.value) identificador.id = id.value;
      ctx.emit("click:guardar", identificador);
    };

    return {
      t,
      v$,
      id,
      nombre,
      epc,
      vehiculo,
      estado,
      fotos,
      titulo,
      textoBoton,
      erroresNombre,
      erroresEPC,
      erroresVehiculo,
      guardar,
    };
  },
});
