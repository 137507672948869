
//decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
//tipos
import { Vehiculo } from "@/typings/store/plugins/easyFirestore/vehiculos";
import { Permisos } from "@/typings/store/modules/permisos";
import { Identificador } from "@/typings/store/plugins/easyFirestore/identificadores";
//componentes
import DetalleIdentificador from "@/components/transporte/identificadores/Detalle.vue";
import TablaIdentificadores from "@/components/transporte/identificadores/Tabla.vue";
import FormularioIdentificador from "@/components/transporte/identificadores/FormularioIdentificador.vue";

@Component({
  components: {
    TablaIdentificadores,
    DetalleIdentificador,
    FormularioIdentificador,
  },
})
export default class TabIdentificadores extends Vue {
  @Prop() readonly arrayIdentificadores!: Array<Identificador>;
  @Prop() readonly arrayVehiculos!: Array<Vehiculo>;
  @Prop() readonly mostrarFormulario!: boolean;
  @Prop() readonly mostrarDetalle!: boolean;
  @Prop() readonly identificador!: Identificador | null;
  @Prop() readonly search!: string;
  @Prop() readonly cargando!: boolean;
  @Prop() readonly permisos!: Permisos;

  get vehiculosOrdenados(): Array<Vehiculo> {
    const vehiculos = this.arrayVehiculos.filter(
      (vehiculo) => vehiculo.estado === "habilitado"
    );
    vehiculos.sort((a, b) => a.patente.localeCompare(b.patente));
    return vehiculos;
  }

  @Emit("click:guardar")
  onClickGuardar(data: Identificador): Identificador {
    return data;
  }

  @Emit("click:informacion")
  onClickInformacion(identificador: Identificador): Identificador {
    return identificador;
  }

  @Emit("click:switch-estado")
  onClickSwitchEstado(identificador: Identificador): Identificador {
    return identificador;
  }

  @Emit("click:editar")
  onClickEditar(): void {
    return;
  }

  @Emit("click:eliminar")
  onClickEliminar(): void {
    return;
  }
}
