
//composition
import {defineComponent, computed} from "vue";
//i18n
import i18n from "@/plugins/i18n";
//tipos
import {Cliente} from "@/typings/store/plugins/easyFirestore/clientes";
import {Permisos} from "@/typings/store/modules/permisos";
import {Item} from "@/typings/components/clientes/tabla";
//componentes
import TableItem from "@/components/custom/TableItem.vue";
import OpcionesTabla from "@/components/clientes/tabla/Opciones.vue";
import DataTableFooter from "@/components/custom/DataTableFooter.vue";

export default defineComponent({
  name: "TablaClientes",
  emits: [
    "click:switch-estado",
    "click:informacion"
  ],
  components: {
    DataTableFooter,
    TableItem,
    OpcionesTabla
  },
  props: {
    clientes: {
      type: Array as () => Cliente[],
      required: true
    },
    cliente: {
      type: Object as () => Cliente | null
    },
    search: {
      type: String,
      required: true
    },
    permisos: {
      type: Object as () => Permisos,
      required: true
    }
  },
  setup(props, ctx) {
    const headers = [{
      text: i18n.t("clientes.tabla.headers.0") as string,
      value: "nombre",
      align: "center"
    }, {
      text: i18n.t("clientes.tabla.headers.1") as string,
      value: "rut",
      align: "center"
    }, {
      text: i18n.t("clientes.tabla.headers.2") as string,
      value: "tipo",
      align: "center"
    }, {
      text: i18n.t("clientes.tabla.headers.4") as string,
      value: "ciudad",
      align: "center"
    }, {
      text: "",
      value: "opciones",
      align: "center",
      sortable: false
    }];

    const noValueMessage = i18n.t("clientes.tabla.no-value-message") as string;

    const items = computed(() => {
      return props.clientes.map((cliente) => {
        const id = cliente.id ?? "";
        const nombre = cliente.nombre;
        const rut = cliente.rut || noValueMessage;
        const tipo = cliente.tipo || noValueMessage;
        const ciudad = cliente.ciudad || noValueMessage;
        return {
          id,
          nombre,
          rut,
          tipo,
          ciudad,
          cliente
        };
      });
    });

    const onClickInformacion = ({cliente}: Item) => {
      ctx.emit("click:informacion", cliente);
    };

    const onClickSwitchEstado = ({cliente}: Item) => {
      ctx.emit("click:switch-estado", cliente);
    };

    const getClasses = ({cliente}: Item, uppercase?: boolean) => {
      const classes = [`pa-0 metropolis text-center text-body-1 text-${uppercase ? 'upercase' : 'capitalize'}`];
      if (props.cliente?.id === cliente.id) classes.push("primary--text font-weight-bold");
      return classes.join(" ");
    };

    return {
      headers,
      items,
      onClickInformacion,
      onClickSwitchEstado,
      getClasses
    };
  }
});
