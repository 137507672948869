
// Vue
import {computed, defineComponent} from "vue";

// Helpers
import BarredoresDesconectados from "@/helpers/BarredoresDesconectados";

// i18n
import {Values} from "vue-i18n";
import i18n from "@/plugins/i18n";

// Componentes
import ItemDialogo from "@/components/alertasBarredores/dialogoDesconectados/ItemDialogo.vue";

export default defineComponent({
  name: "DialogoDesconectados",
  components: {
    ItemDialogo,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    desconectados: {
      required: true,
      type: Object as () => BarredoresDesconectados,
    },
  },
  emits: ["input"],
  setup(props, ctx) {
    // VModel

    const dialogo = computed({
      get() {
        return props.value;
      },
      set(value: boolean) {
        ctx.emit("input", value);
      },
    });

    // Computed

    const textoBoton = computed(() => t("boton", {desconectados: props.desconectados.length}));

    // Methods

    const t = (key: string, values?: Values) => i18n.t(`alertas-barredores.dialogo-desconectados.${key}`, values) as string;

    const abrirDialogo = () => {
      if (props.desconectados.length === 0) return;
      dialogo.value = true;
    };

    return {
      // Data
      dialogo,
      // Computed
      textoBoton,
      // Methods
      t,
      abrirDialogo,
    };
  },
});
