
//decoradores
import {Component, Prop, Vue} from "vue-property-decorator";
//tipos
import {Pesaje} from "@/typings/store/plugins/easyFirestore/pesajes";
//componentes
import TotalVehiculos from "@/components/monitoreo/vehiculos/Total.vue";
import TotalExtendidoVehiculos from "@/components/monitoreo/vehiculos/TotalExtendido.vue";
import TipoClienteVehiculos from "@/components/monitoreo/vehiculos/TipoCliente.vue";
import TiempoDescargaVehiculos from "@/components/monitoreo/vehiculos/TiempoDescarga.vue";

@Component({
  components: {
    TiempoDescargaVehiculos,
    TipoClienteVehiculos,
    TotalExtendidoVehiculos,
    TotalVehiculos
  }
})
export default class TabVehiculo extends Vue {
  @Prop() readonly pesajes!: Array<Pesaje>;
  @Prop() readonly periodo!: number;
  @Prop() readonly fechas!: Array<Date>;

}
