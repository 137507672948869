
// composition
import { defineComponent, ref, computed, watch } from "vue";
// helpers
import { log } from "@/helpers/env";
// tipos
import { Item } from "@/typings/components/clientes/tabla";
import { Permisos } from "@/typings/store/modules/permisos";
import { Cliente } from "@/typings/store/plugins/easyFirestore/clientes";
// componentes
import IconButton from "@/components/custom/IconButton.vue";

export default defineComponent({
  name: "OpcionesTabla",
  components: {
    IconButton,
  },
  emits: ["click:informacion", "click:switch"],
  props: {
    item: {
      type: Object as () => Item,
      required: true,
    },
    permisos: {
      type: Object as () => Permisos,
      required: true,
    },
    seleccionado: {
      type: Object as () => Cliente | null,
    },
  },
  setup(props, ctx) {
    const mostrarSwitch = props.permisos.clientes !== "ver";

    const cargando = ref(false);

    const isSelected = computed(
      () =>
        !!props.seleccionado && props.seleccionado.id === props.item.cliente.id
    );

    const estado = computed(() => {
      const estado = props.item.cliente.estado;
      if (!estado) {
        return "habilitado";
      }
      return estado;
    });

    const switchValue = computed({
      get() {
        return estado.value !== "deshabilitado";
      },
      set(value: boolean) {
        if (log) {
          console.log("set switchValue", value);
        }
        ctx.emit("click:switch", props.item);
        cargando.value = true;
      },
    });

    watch(estado, () => {
      cargando.value = false;
    });

    const onClickInformacion = () => {
      ctx.emit("click:informacion", props.item);
    };

    return {
      mostrarSwitch,
      isSelected,
      switchValue,
      cargando,
      estado,
      onClickInformacion,
    };
  },
});
