
//decoradores
import { Component, Vue } from "vue-property-decorator";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: { SvgIcon },
})
export default class FooterPagina extends Vue {
  get informaciones(): Array<{ id: number; titulo: string; texto: string }> {
    return [
      {
        id: 1,
        titulo: this.$t("pagina.footer.informacion.direccion.titulo") as string,
        texto: this.$t("pagina.footer.informacion.direccion.texto") as string,
      },
      {
        id: 2,
        titulo: this.$t("pagina.footer.informacion.email.titulo") as string,
        texto: this.$t("pagina.footer.informacion.email.texto") as string,
      },
      {
        id: 3,
        titulo: this.$t("pagina.footer.informacion.horario.titulo") as string,
        texto: this.$t("pagina.footer.informacion.horario.texto") as string,
      },
      {
        id: 4,
        titulo: this.$t("pagina.footer.informacion.telefono.titulo") as string,
        texto: this.$t("pagina.footer.informacion.telefono.texto") as string,
      },
    ];
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.mobile;
  }
}
