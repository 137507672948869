
//decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
//tipos
import { Vehiculo } from "@/typings/store/plugins/easyFirestore/vehiculos";
import { Permisos } from "@/typings/store/modules/permisos";
import { Item } from "@/typings/components/transporte/flota";
//componentes
import DataTableFooter from "@/components/custom/DataTableFooter.vue";
import OpcionesTabla from "@/components/transporte/vehiculos/tabla/Opciones.vue";

@Component({
  components: {
    DataTableFooter,
    OpcionesTabla,
  },
})
export default class TablaVehiculos extends Vue {
  @Prop() readonly arrayVehiculos!: Array<Vehiculo>;
  @Prop() readonly search!: string;
  @Prop() readonly vehiculo!: Vehiculo | null;
  @Prop() readonly permisos!: Permisos;

  itemsPerPage = 9;

  get mostrarSwitch(): boolean {
    return this.permisos.transporte !== "ver";
  }

  get headers(): Array<{ value: string; text: string; sortable?: boolean }> {
    return [
      {
        text: this.$t("transporte.tab.flota.tabla.headers[0]") as string,
        value: "patente",
      },
      {
        text: this.$t("transporte.tab.flota.tabla.headers[1]") as string,
        value: "tipo",
      },
      {
        text: this.$t("transporte.tab.flota.tabla.headers[2]") as string,
        value: "marca",
      },
      {
        text: this.$t("transporte.tab.flota.tabla.headers[3]") as string,
        value: "modelo",
      },
      {
        text: this.$t("transporte.tab.flota.tabla.headers[4]") as string,
        value: "año",
      },
      {
        text: "",
        value: "opciones",
        sortable: false,
      },
    ];
  }

  get tipos(): { [key: string]: string } {
    return {
      camioneta: this.$t("transporte.tab.flota.tabla.tipos.0") as string,
      camion: this.$t("transporte.tab.flota.tabla.tipos.1") as string,
      auto: this.$t("transporte.tab.flota.tabla.tipos.2") as string,
    };
  }

  get noValueMessage(): string {
    return this.$t("transporte.tab.flota.no-value-message") as string;
  }

  get items(): Array<Item> {
    return this.arrayVehiculos.map((vehiculo) => {
      const id = vehiculo.id ?? "";
      const patente = vehiculo.patente;
      const tipo = vehiculo.tipo
        ? this.tipos[vehiculo.tipo]
        : this.noValueMessage;
      const marca = vehiculo.marca || this.noValueMessage;
      const modelo = vehiculo.modelo || this.noValueMessage;
      const año = vehiculo.año?.toString() || this.noValueMessage;
      return {
        id,
        patente,
        tipo,
        marca,
        modelo,
        año,
        vehiculo,
      };
    });
  }

  @Emit("click:switch-estado")
  onClickSwitchEstado(vehiculo: Vehiculo): Vehiculo {
    return vehiculo;
  }

  @Emit("click:informacion")
  onClickInformacion(vehiculo: Vehiculo): Vehiculo {
    return vehiculo;
  }

  getClasses({ vehiculo }: Item): {
    capitalize: string;
    uppercase: string;
    normal: string;
  } {
    const seleccionado = this.vehiculo?.id === vehiculo.id;
    return {
      capitalize: `pa-0 metropolis text-center text-body-1 text-capitalize ${
        seleccionado ? "primary--text font-weight-bold" : ""
      }`,
      uppercase: `pa-0 metropolis text-center text-body-1 text-uppercase ${
        seleccionado ? "primary--text font-weight-bold" : ""
      }`,
      normal: `pa-0 metropolis text-center text-body-1 ${
        seleccionado ? "primary--text font-weight-bold" : ""
      }`,
    };
  }

  clickSwitchEstado({ vehiculo }: Item): void {
    this.onClickSwitchEstado(vehiculo);
  }

  onResize(): void {
    const height = window.innerHeight;
    this.itemsPerPage = Math.floor((height * 0.96 - 439) / 50);
  }
}
