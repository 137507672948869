
//decoradores
import { Component, Prop, Vue } from "vue-property-decorator";
//tipos
import { Servicio } from "@/typings/store/plugins/easyFirestore/servicios";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: { SvgIcon },
})
export default class MenuInfoServicio extends Vue {
  @Prop() readonly servicio!: Servicio | null;
  @Prop({ type: Boolean }) readonly top!: boolean;
  @Prop() readonly parent!: string;

  width = 0;

  get activatorCard(): HTMLElement | null {
    return ((this.$refs["activator-card"] as (Vue & { $el: HTMLElement }) | null)?.$el ?? null);
  }

  get noValueMessage(): string {
    return this.$t(`${this.parent}.menu.no-value-message`) as string;
  }

  get nombre(): string {
    return this.servicio?.nombre || this.noValueMessage;
  }

  get tipo(): string {
    return this.servicio?.tipo || this.noValueMessage;
  }

  get descripcion(): string {
    return this.servicio?.descripcion || this.noValueMessage;
  }

  openMenu(): void {
    const bounds = this.activatorCard?.getBoundingClientRect();
    this.width = bounds?.width ?? 0;
  }
}
