
//composition
import {computed, defineComponent} from "vue";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "ActionButton",
  emits: [
    "click"
  ],
  components: {
    SvgIcon
  },
  props: {
    color: {
      type: String,
      default: "primary"
    },
    icon: {
      type: String,
      default: ""
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const validColors = ["primary", "secondary", "error", "warning"];

    const classes = computed(() => {
      const classes = ["rounded-input"];
      if (validColors.includes(props.color)) {
        if (props.dialog) {
          classes.push("elevation-dark");
        } else {
          classes.push(`elevation-${props.color}`);
        }
        classes.push(`gradient-${props.color}`);
      } else {
        classes.push(props.color);
      }
      if (props.disabled) {
        classes.push("button-disabled button-inactive");
      }
      return classes.join(" ");
    });

    const onClick = (ev: MouseEvent): void => {
      ctx.emit("click", ev);
    };

    return {
      classes,
      onClick
    };
  }
});
