
// vue
import { defineComponent, computed, ref, reactive } from "vue";
// composables
import { useI18n } from "@/composables/i18n";
//tipos
import {
  EstadoRutaPesaje,
  RutaPesaje,
  TipoRutaPesaje,
} from "@/typings/store/plugins/easyFirestore/rutasPesaje";
// componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
// validaciones
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default defineComponent({
  components: { SvgIcon },
  props: {
    ruta: {
      default: null,
      type: Object as () => RutaPesaje | null,
    },
    cargando: {
      default: false,
      type: Boolean,
    },
  },
  created(): void {
    if (this.ruta) {
      this.id = this.ruta.id ?? "";
      this.nombre = this.ruta.nombre;
      this.tipo = this.ruta.tipo;
      this.estado = this.ruta.estado ?? "habilitado";
      this.descripcion = this.ruta.descripcion ?? "";
    }
  },
  emits: ["click:guardar"],
  setup(props, ctx) {
    const { t } = useI18n();

    const id = ref("");
    const nombre = ref("");
    const tipo = ref<TipoRutaPesaje | null>(null);
    const estado = ref<EstadoRutaPesaje>("habilitado");
    const descripcion = ref("");

    const tipos = computed(
      (): Array<{ text: string; value: TipoRutaPesaje }> => {
        return [
          {
            text: t("transporte.tab.rutas.formulario.tipo.values[0]") as string,
            value: "rural",
          },
          {
            text: t("transporte.tab.rutas.formulario.tipo.values[1]") as string,
            value: "urbano",
          },
        ];
      }
    );

    const titulo = computed((): string => {
      const index = props.ruta ? 0 : 1;
      return t(`transporte.tab.rutas.formulario.title.${index}`) as string;
    });

    const textoBoton = computed((): string => {
      const index = props.ruta ? 0 : 1;
      return t(`transporte.tab.rutas.formulario.boton.text.${index}`) as string;
    });

    const state = reactive({
      nombre,
      tipo,
    });

    const rules = {
      nombre: { required },
      tipo: { required },
    };

    const v$ = useVuelidate(rules, state);

    const erroresNombre = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.nombre.$dirty) return errores;
      if (v$.value.nombre.required.$invalid)
        errores.push(
          t(
            "transporte.tab.rutas.formulario.nombre.error-messages[0]"
          ) as string
        );
      if (nombre.value.length > 30)
        errores.push(
          t(
            "transporte.tab.rutas.formulario.nombre.error-messages[1]"
          ) as string
        );
      return errores;
    });

    const erroresTipo = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.tipo.$dirty) return errores;
      if (v$.value.tipo.required.$invalid)
        errores.push(
          t("transporte.tab.rutas.formulario.tipo.error-message") as string
        );
      return errores;
    });

    const guardar = (): void => {
      v$.value.$touch();
      if (v$.value.$invalid || nombre.value.length > 30) return;
      const ruta: RutaPesaje = {
        nombre: nombre.value,
        tipo: tipo.value ?? "rural",
        estado: estado.value,
        descripcion: descripcion.value,
      };
      if (props.ruta) ruta.id = props.ruta.id;
      ctx.emit("click:guardar", ruta);
    };

    return {
      t,
      v$,
      id,
      nombre,
      tipo,
      estado,
      descripcion,
      tipos,
      titulo,
      textoBoton,
      erroresNombre,
      erroresTipo,
      guardar,
    };
  },
});
