
//decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
//tipos
import { Permisos } from "@/typings/store/modules/permisos";
import { Identificador } from "@/typings/store/plugins/easyFirestore/identificadores";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: {
    SvgIcon,
  },
})
export default class InfoRfid extends Vue {
  @Prop() readonly identificador!: Identificador;
  @Prop() readonly permisos!: Permisos;

  get mostrarBotonEditar(): boolean {
    return this.permisos.transporte !== "ver";
  }

  get mostrarBotonEliminar(): boolean {
    return this.permisos.transporte === "eliminar";
  }

  get noValueMessage(): string {
    return this.$t("transporte.tab.identificadores.no-value-message") as string;
  }

  get nombre(): string {
    return this.identificador.nombre || this.noValueMessage;
  }

  get epc(): string {
    return this.identificador.epc || this.noValueMessage;
  }

  get vehiculo(): string {
    return this.identificador.vehiculo?.patente || this.noValueMessage;
  }

  get estado(): string {
    return this.identificador.estado || this.noValueMessage;
  }

  @Emit("click:editar")
  onClickEditar(): void {
    return;
  }

  @Emit("click:eliminar")
  onClickEliminar(): void {
    return;
  }
}
