
// vue
import { defineComponent, computed, ref, reactive } from "vue";
// composables
import { useI18n } from "@/composables/i18n";
//tipos
import { RutaBarredor } from "@/typings/store/plugins/easyFirestore/rutasBarredor";
import {
  PingRastreador,
  Posicion,
} from "@/typings/store/plugins/easyFirestore/pingsRastreador";
// validaciones
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

declare interface GoogleMapClickEvent {
  latLng: {
    lat: () => number;
    lng: () => number;
  };
}

export default defineComponent({
  props: {
    rutaBarredor: {
      default: null,
      type: Object as () => RutaBarredor | null,
    },
    pingsRastreador: {
      default: () => [],
      type: Array as () => PingRastreador[],
    },
  },
  created(): void {
    if (this.rutaBarredor) {
      this.nombre = this.rutaBarredor.nombre;
      this.path = this.rutaBarredor.posiciones.slice();
      this.id = this.rutaBarredor.id ?? "";
      this.color = this.rutaBarredor.color ?? "#FF0000";
      this.center = this.path[this.path.length - 1];
    } else {
      const values = this.pingsRastreador.slice();
      values.sort(
        (a, b) => b.fecha.toDate().getTime() - a.fecha.toDate().getTime()
      );
      const primera = values[0];
      if (primera) this.center = primera.posicion;
    }
  },
  emits: ["click:cerrar", "click:guardar"],
  setup(props, ctx) {
    const { t } = useI18n();

    const center = ref({
      lat: -35.4320809,
      lng: -71.6546965,
    });
    const path = ref<Array<Posicion>>([]);
    const id = ref("");
    const nombre = ref("");
    const color = ref("#FF0000");

    const titulo = computed((): string => {
      return props.rutaBarredor
        ? (t("barredores.tab.rutas.formulario.title[0]") as string)
        : (t("barredores.tab.rutas.formulario.title[1]") as string);
    });

    const textoBoton = computed((): string => {
      const index = props.rutaBarredor ? 0 : 1;
      return t(`barredores.tab.rutas.formulario.boton.text.${index}`) as string;
    });

    const ultima = computed((): Posicion | null => {
      return path.value.length > 0 ? path.value[path.value.length - 1] : null;
    });

    const state = reactive({
      nombre,
    });

    const rules = {
      nombre: { required },
    };

    const v$ = useVuelidate(rules, state);

    const nombreErrors = computed((): Array<string> => {
      const errors: Array<string> = [];
      if (!v$.value.nombre.$dirty) return errors;
      if (v$.value.nombre.required.$invalid)
        errors.push(
          t("barredores.tab.rutas.formulario.nombre.error-message") as string
        );
      return errors;
    });

    const onClickCerrar = (): void => {
      ctx.emit("click:cerrar");
    };

    const onClickGuardar = (data: RutaBarredor): void => {
      ctx.emit("click:guardar", data);
    };

    const mapClickHandler = ({ latLng }: GoogleMapClickEvent): void => {
      const temp = path.value.slice();
      temp.push({
        lat: latLng.lat(),
        lng: latLng.lng(),
      });
      path.value = temp;
    };

    const polylineClickHandler = ({ latLng }: GoogleMapClickEvent): void => {
      const temp = path.value.slice();
      temp.push({
        lat: latLng.lat(),
        lng: latLng.lng(),
      });
      path.value = temp;
    };

    const eliminarUltimo = (): void => {
      const temp = path.value.slice();
      temp.pop();
      path.value = temp;
    };

    const guardar = (): void => {
      v$.value.$touch();
      if (v$.value.$invalid) return;
      const rutaBarredor: RutaBarredor = {
        nombre: nombre.value,
        posiciones: path.value,
        color: color.value,
      };
      if (id.value) rutaBarredor.id = id.value;
      onClickGuardar(rutaBarredor);
    };

    return {
      t,
      v$,
      center,
      path,
      id,
      nombre,
      color,
      titulo,
      textoBoton,
      ultima,
      nombreErrors,
      onClickCerrar,
      onClickGuardar,
      mapClickHandler,
      polylineClickHandler,
      eliminarUltimo,
      guardar,
    };
  },
});
