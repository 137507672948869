
// decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// tipos
import { Servicio } from "@/typings/store/plugins/easyFirestore/servicios";
import { ItemTablaServicios } from "@/typings/components/servicios/tabla";
// componentes
import IconButton from "@/components/custom/IconButton.vue";

@Component({
  components: {
    IconButton,
  },
})
export default class OpcionesTabla extends Vue {
  @Prop() readonly item!: ItemTablaServicios;
  @Prop() readonly seleccionado!: Servicio | null;

  get isSelected(): boolean {
    return (
      this.seleccionado !== null && this.seleccionado.id === this.item.data.id
    );
  }

  @Emit("click:informacion")
  onClickInfo(): ItemTablaServicios {
    return this.item;
  }
}
