
//composition
import { defineComponent, ref, computed } from "vue";
//i18n
import i18n from "@/plugins/i18n";
//tipos
import { PingRastreador } from "@/typings/store/plugins/easyFirestore/pingsRastreador";
//componentes
import DataTableFooter from "@/components/custom/DataTableFooter.vue";

export default defineComponent({
  name: "SinMovimiento",
  components: {
    DataTableFooter,
  },
  props: {
    pingsFiltrados: {
      type: Array as () => Array<PingRastreador>,
      required: true,
    },
  },
  setup(props) {
    const itemsPerPage = ref(3);

    const headers = computed(() => {
      return [
        {
          text: i18n.t(
            "barredores.tab.monitoreo.tab.sin-movimiento.headers[0]"
          ) as string,
          value: "hora",
        },
        {
          text: i18n.t(
            "barredores.tab.monitoreo.tab.sin-movimiento.headers[1]"
          ) as string,
          value: "diferencia",
        },
      ];
    });

    const items = computed(() => {
      const result: Array<{ hora: string; diferencia: number }> = [];
      let i = 0;
      for (const ping of props.pingsFiltrados) {
        if (ping.velocidad < 1) {
          const fechaActual = ping.fecha.toDate();
          const siguiente = props.pingsFiltrados[i + 1];
          if (siguiente) {
            const fechaSiguiente = siguiente.fecha.toDate();
            const diferencia = fechaSiguiente.getTime() - fechaActual.getTime();
            result.push({
              hora: fechaActual.format("HH:mm:ss"),
              diferencia,
            });
          }
        }
        i++;
      }
      return result;
    });

    const totalSinMovimiento = computed(() =>
      items.value.reduce((acc, cur) => acc + cur.diferencia, 0)
    );

    const onResize = () => {
      const height = window.innerHeight;
      itemsPerPage.value = Math.floor((height - 736) / 50);
    };

    return {
      itemsPerPage,
      headers,
      items,
      totalSinMovimiento,
      onResize,
    };
  },
});
