
// vue
import { defineComponent, computed, ref, reactive } from "vue";
// composables
import { useI18n } from "@/composables/i18n";
//tipos
import { Desecho } from "@/typings/store/plugins/easyFirestore/desechos";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
// validaciones
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default defineComponent({
  components: {
    SvgIcon,
  },
  props: {
    desecho: {
      default: null,
      type: Object as () => Desecho | null,
    },
    cargando: {
      default: false,
      type: Boolean,
    },
  },
  created(): void {
    const desecho = this.desecho;
    if (desecho?.id) {
      this.id = desecho.id;
      this.nombre = desecho.nombre;
      this.descripcion = desecho.descripcion ?? "";
    }
  },
  emits: ["click:guardar"],
  setup(props, ctx) {
    const { t } = useI18n();

    const id = ref<string | null>(null);
    const nombre = ref("");
    const tipo = ref<string | null>(null);
    const descripcion = ref("");
    const flag = ref(true);

    const titulo = computed((): string => {
      const index = props.desecho ? 1 : 0;
      return t(`desechos.formulario.title.${index}`) as string;
    });

    const state = reactive({
      nombre,
    });

    const rules = {
      nombre: { required },
    };

    const v$ = useVuelidate(rules, state);

    const nombreErrors = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.nombre.$dirty) return errores;
      if (v$.value.nombre.required.$invalid) {
        const error = t(
          "desechos.formulario.nombre.error-messages.0"
        ) as string;
        errores.push(error);
      }
      if (nombre.value.length > 20) {
        const error = t(
          "desechos.formulario.nombre.error-messages.1"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const crearDisabled = computed((): boolean => {
      if (props.desecho) return false;
      return !nombre.value || nombreErrors.value.length > 0;
    });

    const onClickGuardar = (data: Desecho) => {
      ctx.emit("click:guardar", data);
    };

    const guardar = (): void => {
      v$.value.$touch();
      if (v$.value.$invalid) return;
      flag.value = false;
      const desecho: Desecho = {
        nombre: nombre.value.toLowerCase(),
        descripcion: descripcion.value.toLowerCase(),
      };
      if (props.desecho) desecho.id = props.desecho.id;
      onClickGuardar(desecho);
    };

    return {
      t,
      v$,
      id,
      nombre,
      tipo,
      descripcion,
      flag,
      titulo,
      nombreErrors,
      crearDisabled,
      guardar,
    };
  },
});
