
// Vue
import {computed, defineComponent, ref, watch} from "vue";

// Helpers
import {log} from "@/helpers/env";

export default defineComponent({
  name: "CargandoWeighTruck",
  props: {
    cargando: {
      type: Boolean,
    },
  },
  emits: [
    "cerrar",
  ],
  setup(props, ctx) {
    // Data

    const startDate = ref(new Date());
    const animationEnded = ref(false);

    // Computed

    const loadingBarStyle = computed(() => {
      let duration = "20s";
      if (!props.cargando) {
        if (!animationEnded.value) {
          const seconds = (new Date().getTime() - startDate.value.getTime()) / 1000;
          duration = `${seconds + 1}s`;
        } else {
          duration = "5s";
        }
      }
      if (log) console.log("loading duration:", duration);
      return {
        "animation-duration": duration,
      };
    });

    // Watch

    watch(() => props.cargando, (cargando: boolean) => {
      if (!cargando && animationEnded.value) onCerrar();
    });

    // Methods

    const onAnimationEndHandler = () => {
      animationEnded.value = true;
      if (!props.cargando) onCerrar();
    };

    // Emits

    const onCerrar = () => {
      ctx.emit("cerrar");
    };

    return {
      // Computed
      loadingBarStyle,
      // Methods
      onAnimationEndHandler,
    };
  },
});
