
//componentes
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
//tipos
import { Permisos } from "@/typings/store/modules/permisos";
import { RutaBarredor } from "../../../typings/store/plugins/easyFirestore/rutasBarredor";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: { SvgIcon },
})
export default class ListaRutasBarredor extends Vue {
  @Prop() readonly rutasBarredor!: Array<RutaBarredor>;
  @Prop() readonly permisos!: Permisos;

  get mostrarBotonAgregar(): boolean {
    return this.permisos.barredores !== "ver";
  }

  get mostrarBotonEditar(): boolean {
    return this.permisos.barredores !== "ver";
  }

  get mostrarBotonEliminar(): boolean {
    return this.permisos.barredores === "eliminar";
  }

  @Emit("click:agregar")
  onClickAgregar(): void {
    return;
  }

  @Emit("click:informacion")
  onClickInformacion(rutaBarredor: RutaBarredor): RutaBarredor {
    return rutaBarredor;
  }

  @Emit("click:editar")
  onClickEditar(rutaBarredor: RutaBarredor): RutaBarredor {
    return rutaBarredor;
  }

  @Emit("click:eliminar")
  onClickEliminar(rutaBarredor: RutaBarredor): RutaBarredor {
    return rutaBarredor;
  }
}
