
//decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
//tipos
import { Permisos } from "@/typings/store/modules/permisos";
import { RutaPesaje } from "@/typings/store/plugins/easyFirestore/rutasPesaje";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: {
    SvgIcon,
  },
})
export default class InfoRuta extends Vue {
  @Prop() readonly ruta!: RutaPesaje;
  @Prop({ type: Boolean }) readonly showTitulo!: boolean;
  @Prop() readonly permisos!: Permisos;

  get mostrarBotonEditar(): boolean {
    return this.permisos.transporte !== "ver";
  }

  get mostrarBotonEliminar(): boolean {
    return this.permisos.transporte === "eliminar";
  }

  get noValueMessage(): string {
    return this.$t("transporte.tab.rutas.no-value-message") as string;
  }

  get nombre(): string {
    return this.ruta.nombre || this.noValueMessage;
  }

  get tipo(): string {
    return this.ruta.tipo || this.noValueMessage;
  }

  get estado(): string {
    return this.ruta.estado || this.noValueMessage;
  }

  get descripcion(): string {
    return this.ruta.descripcion ?? this.noValueMessage;
  }

  @Emit("click:editar")
  onClickEditar(): void {
    return;
  }

  @Emit("click:eliminar")
  onClickEliminar(): void {
    return;
  }
}
