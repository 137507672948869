
//decoradores
import {
  Component,
  Emit,
  Prop,
  VModel,
  Vue,
  Watch,
} from "vue-property-decorator";
//tipos
import { Cliente } from "@/typings/store/plugins/easyFirestore/clientes";
import { Desecho } from "@/typings/store/plugins/easyFirestore/desechos";
import { Vehiculo } from "@/typings/store/plugins/easyFirestore/vehiculos";
import { Servicio } from "@/typings/store/plugins/easyFirestore/servicios";
import { FiltrosReporte } from "@/typings/components/reportes/filtros";
//componentes
import SvgIcon from "../custom/SvgIcon.vue";

@Component({
  components: {
    SvgIcon,
  },
})
export default class FiltrosReportes extends Vue {
  @VModel() filtros!: FiltrosReporte;
  @Prop() readonly vehiculos!: Array<Vehiculo>;
  @Prop() readonly clientes!: Array<Cliente>;
  @Prop() readonly servicios!: Array<Servicio>;
  @Prop() readonly desechos!: Array<Desecho>;
  @Prop() readonly clienteUsuario!: Cliente | null;
  @Prop() readonly cantidadRegistros!: number;

  menu = false;

  get fechas(): Array<string> {
    return this.filtros.fechas;
  }

  set fechas(value: Array<string>) {
    this.filtros.fechas = value;
  }

  get fechasMostrar(): string {
    return this.fechas
      .map((fecha) => fecha.toDate().format("DD/MM/YY"))
      .join(" ~ ");
  }

  get filtroVehiculo(): Vehiculo | null {
    return this.filtros.vehiculo;
  }

  set filtroVehiculo(value: Vehiculo | null) {
    this.filtros.vehiculo = value;
  }

  get filtroClientes(): Array<Cliente> {
    return this.filtros.clientes;
  }

  set filtroClientes(value: Array<Cliente>) {
    this.filtros.clientes = value;
  }

  get filtroServicios(): Array<Servicio> {
    return this.filtros.servicios;
  }

  set filtroServicios(value: Array<Servicio>) {
    this.filtros.servicios = value;
  }

  get filtroDesecho(): Desecho | null {
    return this.filtros.desecho;
  }

  set filtroDesecho(value: Desecho | null) {
    this.filtros.desecho = value;
  }

  get textoFiltroClientes(): string {
    const length = this.filtroClientes.length;
    if (length === 1) {
      return this.filtroClientes[0].nombre;
    } else {
      return this.$tc("reportes.filtro.clientes.selected", length);
    }
  }

  get textoFiltroServicios(): string {
    const length = this.filtroServicios.length;
    if (length === 1) {
      return this.filtroServicios[0].nombre;
    } else {
      return this.$tc("reportes.filtro.servicios.selected", length);
    }
  }

  @Emit("click:crear-pdf")
  onClickCrearPDF(): void {
    return;
  }

  @Emit("click:crear-excel")
  onClickCrearExcel(): void {
    return;
  }

  @Emit("click:imprimir-todos")
  onClickImprimirTodos(): void {
    return;
  }

  @Watch("fechas")
  onFechasChange(): void {
    const [fechaInicio, fechaFin] = this.fechas.map((fecha) => fecha.toDate());
    if (fechaInicio && fechaFin && fechaInicio > fechaFin) {
        this.fechas.reverse();
    }
  }
}
