
//componsition
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'InformationText',
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    textCase: {
      type: String,
      default: "capitalize"
    }
  }
});
