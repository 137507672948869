
//decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
//tipos
import { Desecho } from "@/typings/store/plugins/easyFirestore/desechos";
import { Permisos } from "@/typings/store/modules/permisos";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: {
    SvgIcon,
  },
})
export default class DetalleDesecho extends Vue {
  @Prop() readonly desecho!: Desecho;
  @Prop() readonly permisos!: Permisos;

  get mostrarBotonEditar(): boolean {
    return this.permisos.desechos !== "ver";
  }

  get mostrarBotonEliminar(): boolean {
    return this.permisos.desechos === "eliminar";
  }

  get noValueMessage(): string {
    return this.$t("desechos.detalle.no-value-message") as string;
  }

  get nombre(): string {
    return this.desecho.nombre || this.noValueMessage;
  }

  get descripcion(): string {
    return this.desecho.descripcion || this.noValueMessage;
  }

  @Emit("click:editar")
  onClickEditar(): void {
    return;
  }

  @Emit("click:eliminar")
  onClickEliminar(): void {
    return;
  }
}
