

//composition
import {watch, onMounted, ref, defineComponent} from "vue";
//vuetify
import vuetify from "@/plugins/vuetify";

export default defineComponent({
  name: "SvgIcon",
  props: {
    color: {
      type: String,
    },
    src: {
      type: String
    },
    name: {
      type: String
    },
    opacity: {
      type: [Number, String]
    },
    width: {
      type: String,
      default: "24px"
    },
    height: {
      type: String,
      default: "24px"
    },
    noAnimations: {
      type: Boolean
    }
  },
  setup(props) {
    const prefixs = [
      "agr",
      "ags",
      "ag",
      "bxs",
      "bx",
      "fad",
      "fal",
      "far",
      "fas",
      "fi-br",
      "ionic-md",
      "mdi"
    ];
    const svgContent = ref<HTMLDivElement | null>(null);

    onMounted(() => {
      mountSVG();
    });

    watch(() => props.color, () => {
      mountSVG();
    });

    watch(() => props.width, () => {
      mountSVG();
    });

    watch(() => props.height, () => {
      mountSVG();
    });

    watch(() => props.src, () => {
      mountSVG();
    });

    watch(() => props.name, () => {
      mountSVG();
    });

    watch(() => props.opacity, () => {
      mountSVG();
    });

    watch(() => props.noAnimations, () => {
      mountSVG();
    });

    const mountSVG = async (): Promise<void> => {
      let src = props.src;
      if (props.name) {
        const split = props.name.split("/");
        const name = split[split.length - 1].split(".")[0];
        const prefix = getPrefix(name);
        const icons = require.context(`@/assets/svg/icons`, true, /\.svg$/);
        src = icons(`./${prefix}/${name}.svg`);
      }
      if (src && svgContent.value) {
        const response = await fetch(src);
        const svg = await response.text();
        const vuetifyTheme = vuetify.framework.theme?.themes?.light;
        const primaryColor = props.color ? (vuetifyTheme[props.color] as string) ?? props.color : null;
        if (svgContent.value && svg) {
          svgContent.value.innerHTML = svg;
          const svgElement = svgContent.value.querySelector("svg") as SVGElement;
          if (props.noAnimations) svgElement.style.animation = "none";
          svgElement.style.width = props.width;
          svgElement.style.height = props.height;
          for (const children of svgElement.querySelectorAll("*")) {
            const child = children as SVGElement;
            if (props.noAnimations) child.style.animation = "none";
            if (
              (child.style.fill || child.getAttribute("fill")) &&
              primaryColor
            ) {
              child.style.fill = primaryColor;
            }
            if (props.opacity) child.style.opacity = props.opacity.toString();
            if ((child.style.stroke || child.getAttribute("stroke")) && primaryColor) {
              child.style.stroke = primaryColor;
            }
          }
        }
      } else {
        throw new Error("No SVG source provided");
      }
    }

    const getPrefix = (name: string): string => {
      for (const prefix of prefixs) {
        if (name.startsWith(prefix)) return prefix;
      }
      return "personalizados";
    }

    return {
      svgContent
    }
  }
});
