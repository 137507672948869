

//composition
import {onMounted, defineComponent} from "vue";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "SuccessDialog",
  components: {
    SvgIcon
  },
  props: {
    icon: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    duration: {
      type: Number,
      default: 3000
    }
  },
  emits: [
    "close"
  ],
  setup(props, ctx) {
    onMounted(() => {
      window.setTimeout(() => {
        ctx.emit("close");
      }, props.duration);
    });
  }
})
