
import { Component, Vue } from "vue-property-decorator";
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: {
    SvgIcon,
  },
})
export default class TerminosCondiciones extends Vue {}
