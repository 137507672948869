
//composition
import {computed, defineComponent} from "vue";

export default defineComponent({
  name: "FormTextField",
  props: {
    value: {
      type: [String, Number],
      required: true
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false
    },
    errorMessages: {
      type: Array as () => Array<string>,
      required: false,
      default: () => []
    },
    required: {
      type: Boolean
    },
    readonly: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    dialog: {
      type: Boolean
    }
  },
  emits: [
    "blur",
    "focus",
    "change",
    "input"
  ],
  setup(props, ctx) {
    const vModel = computed({
      get() {
        return props.value;
      },
      set(value) {
        ctx.emit("input", value);
      }
    });

    const onBlur = (ev: FocusEvent) => {
      ctx.emit("blur", ev);
    };

    const onFocus = (ev: FocusEvent) => {
      ctx.emit("focus", ev);
    };


    const onChange = (value: string) => {
      ctx.emit("change", value);
    };


    const onInput = (value: string) => {
      ctx.emit("input", value);
    };

    return {
      vModel,
      onBlur,
      onFocus,
      onChange,
      onInput
    };
  }
});
