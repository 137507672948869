
//decoradores
import { Component, Prop, Vue } from "vue-property-decorator";
//tipos
import { Pesaje } from "@/typings/store/plugins/easyFirestore/pesajes";
//componentes
import DonutChart from "@/components/monitoreo/charts/DonutChart.vue";

@Component({
  components: {
    DonutChart,
  },
})
export default class TipoClienteVehiculos extends Vue {
  @Prop() readonly pesajes!: Array<Pesaje>;

  get labels(): Array<string> {
    return [
      this.$t("monitoreo.tab.vehiculos.grafico-2.tipo.0") as string,
      this.$t("monitoreo.tab.vehiculos.grafico-2.tipo.1") as string,
      this.$t("monitoreo.tab.vehiculos.grafico-2.tipo.2") as string,
    ];
  }

  get series(): Array<number> {
    const helper: { [key: string]: number } = {
      empresa: 0,
      particular: 0,
      municipal: 0,
    };
    for (const pesaje of this.pesajes) {
      const tipo = pesaje.cliente?.tipo;
      if (tipo) {
        helper[tipo] = (helper[tipo] || 0) + 1;
      }
    }
    return Object.values(helper);
  }
}
