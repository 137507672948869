
//decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
//tipos
import { Pesaje } from "@/typings/store/plugins/easyFirestore/pesajes";
import { Clientes } from "@/typings/store/plugins/easyFirestore/clientes";
import { Servicios } from "@/typings/store/plugins/easyFirestore/servicios";
import { Item, Header } from "@/typings/components/reportes/tabla";
//componentes
import ItemTabla from "@/components/reportes/tabla/Item.vue";
import ItemCirculo from "@/components/reportes/tabla/ItemCirculo.vue";
import OpcionesTabla from "@/components/reportes/tabla/Opciones.vue";
import DataTableFooter from "@/components/custom/DataTableFooter.vue";
import { log } from "@/helpers/env";

@Component({
  components: {
    ItemTabla,
    ItemCirculo,
    OpcionesTabla,
    DataTableFooter,
  },
})
export default class TablaReportes extends Vue {
  @Prop() readonly pesajes!: Array<Pesaje>;
  @Prop() readonly pesaje!: Pesaje | null;
  @Prop() readonly servicios!: Servicios;
  @Prop() readonly clientes!: Clientes;

  get headers(): Array<Header> {
    return [
      {
        text: "",
        value: "estado",
        sortable: false,
      },
      {
        text: this.$t("reportes.tabla.headers.0") as string,
        value: "fecha",
      },
      {
        text: this.$t("reportes.tabla.headers.1") as string,
        value: "entrada",
      },
      {
        text: this.$t("reportes.tabla.headers.2") as string,
        value: "salida",
      },
      {
        text: this.$t("reportes.tabla.headers.3") as string,
        value: "patente",
      },
      {
        text: this.$t("reportes.tabla.headers.5") as string,
        value: "cliente",
      },
      {
        text: this.$t("reportes.tabla.headers.4") as string,
        value: "pesoNeto",
      },
      {
        text: "",
        value: "opciones",
        sortable: false,
      },
    ];
  }

  get items(): Array<Item> {
    if (log) console.time("components.reportes.Tabla.items");
    const resultado = this.pesajes.map((pesaje) => {
      const patente = pesaje.vehiculo?.patente ?? "-";
      const estado = pesaje.estado;
      const fecha = pesaje.entrada.fecha
        ? pesaje.entrada.fecha.toDate().format("DD/MM/YYYY")
        : "-";
      const entrada = pesaje.entrada.fecha
        ? pesaje.entrada.fecha.toDate().format("HH:mm")
        : "-";
      const salida = pesaje.salida.fecha
        ? pesaje.salida.fecha.toDate().format("HH:mm")
        : "-";
      const mayoresCero = pesaje.entrada.peso > 0 && pesaje.salida.peso > 0;
      const peso = pesaje.entrada.peso - pesaje.salida.peso;
      const pesoNeto = mayoresCero ? Math.abs(peso).toCurrency() : "-";
      const cliente = pesaje.cliente?.nombre ?? "-";
      return {
        patente,
        estado,
        fecha,
        entrada,
        salida,
        pesoNeto,
        cliente,
        pesaje,
      };
    });
    if (log) console.timeEnd("components.reportes.Tabla.items");
    return resultado;
  }

  @Emit("click:informacion")
  onClickInformacion({ pesaje }: Item): Pesaje {
    return pesaje;
  }

  getClasses({ pesaje }: Item): {
    capitalize: string;
    uppercase: string;
    normal: string;
  } {
    const seleccionado = this.pesaje?.id === pesaje.id;
    return {
      capitalize: `pa-0 metropolis text-center text-body-1 text-capitalize ${
        seleccionado ? "primary--text font-weight-bold" : ""
      }`,
      uppercase: `pa-0 metropolis text-center text-body-1 text-uppercase ${
        seleccionado ? "primary--text font-weight-bold" : ""
      }`,
      normal: `pa-0 metropolis text-center text-body-1 ${
        seleccionado ? "primary--text font-weight-bold" : ""
      }`,
    };
  }
}
