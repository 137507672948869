
// composition-api
import { defineComponent, ref, reactive, computed, watch, set } from "vue";
// pdfmake
import pdfMake from "pdfmake/build/pdfmake";

//helpers
import routeGuard from "@/helpers/routeGuard";
// tipos
import { Chofer } from "@/typings/store/plugins/easyFirestore/choferes";
import { Cliente } from "@/typings/store/plugins/easyFirestore/clientes";
import { Desecho } from "@/typings/store/plugins/easyFirestore/desechos";
import { Servicio } from "@/typings/store/plugins/easyFirestore/servicios";
import { RutaPesaje } from "@/typings/store/plugins/easyFirestore/rutasPesaje";
import { Archivo } from "@/typings/store/plugins/easyFirestore/archivos";
import {
  Pesaje,
  PesajeData,
} from "@/typings/store/plugins/easyFirestore/pesajes";
import { Vehiculo } from "@/typings/store/plugins/easyFirestore/vehiculos";
// validaciones
import useVuelidate from "@vuelidate/core";
import { required, minValue } from "@vuelidate/validators";
// composables
import { useI18n } from "@/composables/i18n";
import { useDate } from "@/composables/date";
import { useKgM3 } from "@/composables/kgM3";
import { useMixins } from "@/composables/mixins";
import { useUsuario } from "@/composables/usuario";
import { useBascula } from "@/composables/bascula";
import { useCamaras } from "@/composables/camaras";
import { usePesajes } from "@/composables/pesajes";
import { useArchivos } from "@/composables/archivos";
import { useClientes } from "@/composables/clientes";
import { useChoferes } from "@/composables/choferes";
import { useDesechos } from "@/composables/desechos";
import { useVehiculos } from "@/composables/vehiculos";
import { useServicios } from "@/composables/servicios";
import { useRutasPesaje } from "@/composables/rutasPesaje";
import { useIdentificadores } from "@/composables/identificadores";
import { useVistaBascula } from "@/composables/vistaBascula";
// componentes
import BotonCapturar from "@/components/BotonCapturar.vue";
import TablaBascula from "@/components/bascula/TablaBascula.vue";
import DialogoManual from "@/components/bascula/DialogoManual.vue";
import FormularioBascula from "@/components/bascula/Formulario.vue";
import EntradaSalidaBascula from "@/components/bascula/EntradaSalida.vue";
import DialogoGaleriaImagenes from "@/components/bascula/DialogoGaleriaImagenes.vue";
import ConfirmationDialog from "@/components/custom/ConfirmationDialog.vue";
import DialogoSinFotos from "@/components/bascula/DialogoSinFotos.vue";

interface DialogoGaleria {
  model: boolean;
  pesaje: Pesaje | null;
}

interface DialogoConfirmacion {
  model: boolean;
  texto: string;
  si: (() => void) | null;
  no: (() => void) | null;
}

export default defineComponent({
  name: "VistaBascula",
  components: {
    DialogoSinFotos,
    ConfirmationDialog,
    TablaBascula,
    DialogoManual,
    BotonCapturar,
    FormularioBascula,
    EntradaSalidaBascula,
    DialogoGaleriaImagenes,
  },
  beforeRouteEnter(_to, _from, next) {
    next(routeGuard);
  },
  created(): void {
    if (!routeGuard()) {
      return;
    }
    this.$eventBus.$on("click:editar-pesaje", this.abrirDialogoManual);
    this.camaras.setSincronizar(true);
    this.bascula.setSincronizar(true);
  },
  destroyed(): void {
    this.$eventBus.$off("click:editar-pesaje", this.abrirDialogoManual);
    this.camaras.setSincronizar(false);
    this.bascula.setSincronizar(false);
  },
  setup() {
    const { t } = useI18n();

    const date = useDate();
    const kgM3 = useKgM3();
    const mixins = useMixins();
    const usuario = useUsuario();
    const bascula = useBascula();
    const pesajes = usePesajes();
    const camaras = useCamaras({ id: "cam01" });
    const archivos = useArchivos();
    const clientes = useClientes({ ordenarArray: true });
    const choferes = useChoferes({ ordenarArray: true });
    const desechos = useDesechos({ ordenarArray: true });
    const servicios = useServicios({ ordenarArray: true });
    const vehiculos = useVehiculos({ ordenarArray: true });
    const rutasPesaje = useRutasPesaje({ ordenarArray: true });
    const identificadores = useIdentificadores({ ordenarArray: true });

    const fijado = ref(false);
    const guardando = ref(false);
    const dialogoFormularioManual = ref(false);
    const capturaLista = ref(false);
    const pesaje = ref<Pesaje | null>(null);
    const pesajeManual = ref<Pesaje | null>(null);
    const pesajeData = ref<PesajeData>({});
    const fotos = ref<Array<Archivo>>([]);
    const dialogoSinFotos = ref(false);

    const { datosFiltrados } = useVistaBascula();

    const dialogoGaleria = reactive<DialogoGaleria>({
      model: false,
      pesaje: null,
    });

    const dialogoConfirmacion = reactive<DialogoConfirmacion>({
      model: false,
      texto: "",
      si: null,
      no: null,
    });

    const disabledFijar = computed((): boolean => {
      return bascula.getPeso.value <= 0;
    });

    const capacidadPesaje = computed((): number => {
      return pesajeData.value.capacidad || pesaje.value?.capacidad || 0;
    });

    const rutaPesaje = computed((): RutaPesaje | null => {
      return pesajeData.value.ruta || pesaje.value?.ruta || null;
    });

    const clientePesaje = computed((): Cliente | null => {
      return pesajeData.value.cliente || pesaje.value?.cliente || null;
    });

    const desechoPesaje = computed((): Desecho | null => {
      return pesajeData.value.desecho || pesaje.value?.desecho || null;
    });

    const vehiculoPesaje = computed((): Vehiculo | null => {
      return pesajeData.value.vehiculo || pesaje.value?.vehiculo || null;
    });

    const servicioPesaje = computed((): Servicio | null => {
      return pesajeData.value.servicio || pesaje.value?.servicio || null;
    });

    const choferEntradaPesaje = computed((): Chofer | null => {
      return (
        pesajeData.value.choferEntrada || pesaje.value?.entrada?.chofer || null
      );
    });

    const state = {
      capacidadPesaje,
      rutaPesaje,
      clientePesaje,
      desechoPesaje,
      vehiculoPesaje,
      servicioPesaje,
      choferEntradaPesaje,
    };

    const rules = {
      capacidadPesaje: {
        required,
        minValue: minValue(1),
      },
      rutaPesaje: { required },
      clientePesaje: { required },
      desechoPesaje: { required },
      vehiculoPesaje: { required },
      servicioPesaje: { required },
      choferEntradaPesaje: { required },
    };

    const v$ = useVuelidate(rules, state);

    const arrayChoferes = computed((): Array<Chofer> => {
      return choferes.getArrayHabilitados.value;
    });

    const arrayClientes = computed((): Array<Cliente> => {
      return clientes.getArrayHabilitados.value;
    });

    const arrayServicios = computed((): Array<Servicio> => {
      return servicios.getArray.value;
    });

    const arrayDesechos = computed((): Array<Desecho> => {
      return desechos.getArray.value;
    });

    const arrayVehiculos = computed((): Array<Vehiculo> => {
      return vehiculos.getArrayHabilitados.value;
    });

    const arrayRutasPesaje = computed((): Array<RutaPesaje> => {
      return rutasPesaje.getArrayHabilitados.value;
    });

    const cantidadFotos = computed((): number => {
      let cantidad = 0;
      if (pesaje.value?.fotos) {
        cantidad += pesaje.value.fotos.length;
      }
      if (fotos.value) {
        cantidad += fotos.value.length;
      }
      return cantidad;
    });

    const disabledBtnFoto = computed((): boolean => {
      return !fijado.value;
    });

    const img = computed((): string => {
      return camaras.getImagen.value;
    });

    const capacidadErrors = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.capacidadPesaje?.$dirty) return errores;
      if (v$.value.capacidadPesaje.required.$invalid) {
        const error = t(
          "bascula.formulario.capacidad.error.required"
        ) as string;
        errores.push(error);
      }
      if (v$.value.capacidadPesaje.minValue.$invalid) {
        const error = t(
          "bascula.formulario.capacidad.error.minValue"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const vehiculoErrors = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.vehiculoPesaje?.$dirty) return errores;
      if (v$.value.vehiculoPesaje.required.$invalid) {
        const error = t("bascula.formulario.vehiculo.error") as string;
        errores.push(error);
      }
      return errores;
    });

    const clienteErrors = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.clientePesaje?.$dirty) return errores;
      if (v$.value.clientePesaje.required.$invalid) {
        const error = t("bascula.formulario.cliente.error") as string;
        errores.push(error);
      }
      return errores;
    });

    const desechoErrors = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.desechoPesaje?.$dirty) return errores;
      if (v$.value.desechoPesaje.required.$invalid) {
        const error = t("bascula.formulario.desecho.error") as string;
        errores.push(error);
      }
      return errores;
    });

    const servicioErrors = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.servicioPesaje?.$dirty) return errores;
      if (v$.value.servicioPesaje.required.$invalid) {
        const error = t("bascula.formulario.servicio.error") as string;
        errores.push(error);
      }
      return errores;
    });

    const choferEntradaErrors = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.choferEntradaPesaje.$dirty) return errores;
      if (v$.value.choferEntradaPesaje.required.$invalid) {
        const error = t(
          "bascula.entrada-salida.tab.entrada.chofer.error"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const rutaErrors = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.rutaPesaje.$dirty) return errores;
      if (v$.value.rutaPesaje.required.$invalid) {
        const error = t("bascula.formulario.ruta.error") as string;
        errores.push(error);
      }
      return errores;
    });

    watch(vehiculoPesaje, (newVal) => {
      if (fijado.value || !newVal) return;
      const _pesaje = ultimoPesajeVehiculo(newVal);
      if (_pesaje && _pesaje?.id !== _pesaje.id) {
        cargarPesaje(_pesaje);
      }
    });

    watch(bascula.getFecha, (newVal) => {
      const tag = bascula.getTag.value;
      const peso = bascula.getPeso.value;
      if (fijado.value) return;
      if (!pesaje.value || pesaje.value.estado === "entrada") {
        pesajeData.value = Object.assign({}, pesajeData.value, {
          fechaEntrada: newVal.toISOString(),
        });
        pesajeData.value = Object.assign({}, pesajeData.value, {
          pesoEntrada: peso,
        });
      } else {
        pesajeData.value = Object.assign({}, pesajeData.value, {
          fechaSalida: newVal.toISOString(),
        });
        pesajeData.value = Object.assign({}, pesajeData.value, {
          pesoSalida: peso,
        });
      }
      const identificador = identificadores.getByEPC(tag);
      const id = identificador?.vehiculo?.id ?? "";
      const vehiculo = vehiculos.getById(id);
      if (vehiculo) {
        set(pesajeData, "vehiculo", vehiculo);
      }
    });

    const fijarData = (): void => {
      if (!fijado.value) {
        fijado.value = true;
      }
    };

    const cerrarDialogoConfirmacion = (): void => {
      dialogoConfirmacion.model = false;
      dialogoConfirmacion.texto = "";
      dialogoConfirmacion.si = null;
      dialogoConfirmacion.no = null;
    };

    const confirmarLimpiar = (): void => {
      dialogoConfirmacion.model = true;
      dialogoConfirmacion.texto = t(
        "bascula.formulario.boton-limpiar.mensaje-confirmacion"
      ) as string;
      dialogoConfirmacion.si = () => {
        limpiarFormulario();
        cerrarDialogoConfirmacion();
      };
      dialogoConfirmacion.no = () => {
        cerrarDialogoConfirmacion();
      };
    };

    const guardarImagen = async (val: string): Promise<void> => {
      if (!val || val.length <= 30) return;
      const archivo = await archivos.dataUrlToBlobArchivo({
        dataUrl: val,
        ext: "jpeg",
        coleccion: "pesajes",
        comprimir: { quality: 0.1 },
      });
      if (!archivo) {
        console.error("Error al guardar la imagen");
        return;
      }
      const id = await archivos.setBlob(archivo);
      const foto = archivos.getById(id);
      if (foto) {
        const temp = fotos.value.slice();
        temp.push(foto);
        fotos.value = temp;
      }
    };

    const clickRow = (_pesaje: Pesaje): void => {
      if (fijado.value) return;
      cargarPesaje(_pesaje);
    };

    const cargarPesaje = (_pesaje: Pesaje | null): void => {
      if (!_pesaje) return;
      pesaje.value = _pesaje;
      pesajeData.value = {};
      v$.value.$reset();
    };

    const abrirDialogoGaleria = (_pesaje: Pesaje): void => {
      dialogoGaleria.model = true;
      dialogoGaleria.pesaje = _pesaje;
    };

    const cerrarDialogoGaleria = (): void => {
      dialogoGaleria.model = false;
      dialogoGaleria.pesaje = null;
    };

    const abrirDialogoManual = (_pesaje?: Pesaje): void => {
      dialogoFormularioManual.value = true;
      pesajeManual.value = null;
      if (_pesaje) {
        pesajeManual.value = _pesaje;
      }
    };

    const cambiarCamara = (): void => {
      camaras.nextCamara();
    };

    const ultimoPesajeVehiculo = (vehiculo: Vehiculo | null): Pesaje | null => {
      if (!vehiculo) return null;
      const _pesaje = datosFiltrados.value.find((item) => {
        return (
          item.vehiculo?.patente == vehiculo.patente &&
          item.estado != "finalizado"
        );
      });
      return _pesaje ?? null;
    };

    const desfijar = (): void => {
      if (fijado.value) {
        fijado.value = false;
        capturaLista.value = false;
        v$.value.$reset();
      }
    };

    const limpiarFormulario = (): void => {
      if (fijado.value) desfijar();
      pesaje.value = null;
      pesajeData.value = {};
      fotos.value = [];
      v$.value.$reset();
    };

    const limpiarFormularioManual = (): void => {
      if (pesajeManual.value?.id === pesaje.value?.id) {
        if (fijado) desfijar();
        pesaje.value = null;
        pesajeData.value = {};
        fotos.value = [];
        v$.value.$reset();
      }
      pesajeManual.value = null;
    };

    const actualizarPesaje = (
      _pesaje: Pesaje,
      _pesajeData: PesajeData,
      _fotos: Archivo[],
      _manual?: boolean
    ) => {
      const doc = pesajes.pesajeDataToPatch(_pesaje.id, _pesajeData);
      if (_fotos.length > 0) {
        doc.fotos = _fotos;
        doc.fotos = doc.fotos.concat(_pesaje.fotos);
      }
      if (_manual) {
        if (doc.salida?.peso || _pesaje.salida?.peso) {
          doc.estado = "finalizado";
        } else {
          doc.estado = "salida";
        }
      } else {
        doc.estado = "finalizado";
      }
      doc.sincronizacion = {
        enviado: new Date().toISOString(),
        recibido: null,
      };
      pesajes.set(doc);
    };

    const nuevoPesaje = (
      _pesajeData: PesajeData,
      _fotos: Archivo[],
      _manual?: boolean
    ) => {
      const doc = pesajes.pesajeDataToSet(_pesajeData, _fotos);
      if (_manual && doc.salida?.peso) {
        doc.estado = "finalizado";
      }
      pesajes.set(doc);
    };

    const formularioValido = (): boolean => {
      v$.value.$touch();
      return !v$.value.$invalid;
    };

    const fotosValidas = (): boolean => {
      const cantidadImagenes =
        fotos.value.length + (pesaje.value?.fotos.length || 0);
      const tipoCliente = pesajeData.value.cliente?.tipo ?? "particular";
      return (
        (cantidadImagenes > 0 && tipoCliente === "municipal") ||
        tipoCliente !== "municipal"
      );
    };

    const guardar = (): void => {
      if (!formularioValido()) return;
      if (!fotosValidas()) {
        dialogoSinFotos.value = true;
        return;
      }
      guardando.value = true;
      if (pesaje.value?.id) {
        actualizarPesaje(pesaje.value, pesajeData.value, fotos.value);
        limpiarFormulario();
        if (fijado.value) desfijar();
        guardando.value = false;
      } else {
        nuevoPesaje(pesajeData.value, fotos.value);
        limpiarFormulario();
        if (fijado.value) desfijar();
        guardando.value = false;
      }
    };

    const guardarManual = (pesajeDataManual: PesajeData): void => {
      guardando.value = true;
      if (pesajeManual.value?.id) {
        actualizarPesaje(pesajeManual.value, pesajeDataManual, [], true);
        limpiarFormularioManual();
        guardando.value = false;
      } else {
        nuevoPesaje(pesajeDataManual, [], true);
        limpiarFormularioManual();
        guardando.value = false;
      }
      cerrarDialogoFormularioManual();
    };

    const cerrarDialogoFormularioManual = (): void => {
      pesajeManual.value = null;
      dialogoFormularioManual.value = false;
    };

    const pesoPesaje = (_pesaje: Pesaje) => {
      if (_pesaje.entrada.peso > 0 && _pesaje.salida.peso > 0) {
        return _pesaje.entrada.peso - _pesaje.salida.peso;
      }
      return 0;
    };

    const imprimirBoleto = async (id: string): Promise<void> => {
      const _pesaje = pesajes.getById(id);
      if (_pesaje) {
        const fechaEntrada: Date = date.newDate(_pesaje.entrada.fecha);
        fechaEntrada.setHours(0, 0, 0, 0);
        const kgM3Temp = await kgM3.getKgM3By({
          cliente: _pesaje.cliente,
          fecha: fechaEntrada,
        });
        const peso = pesoPesaje(_pesaje);
        const pesoNeto = _pesaje.servicio?.tipo === "Egreso" ? peso * -1 : peso;
        const m3 = kgM3
          .getM3(pesoNeto, _pesaje.capacidad, _pesaje.cobro, kgM3Temp)
          ?.toFixed(2);
        const empresa = usuario.getEmpresa.value;
        const idEmpresa = empresa.id ?? "";
        const dd = mixins.$printExitTicket({
          empresa: empresa,
          qr: `${_pesaje.id}@${idEmpresa}`,
          m3: m3 ?? "0",
          pesaje: _pesaje,
        });
        pdfMake.createPdf(dd).print();
      }
    };

    const resincronizar = (pesaje: Pesaje) => {
      if (!pesaje.id) return;
      pesajes.resynchronize(pesaje.id);
    };

    return {
      archivos,
      camaras,
      bascula,
      img,
      pesaje,
      fijado,
      guardando,
      pesajeData,
      rutaErrors,
      pesajeManual,
      disabledFijar,
      cantidadFotos,
      desechoErrors,
      clienteErrors,
      arrayChoferes,
      arrayClientes,
      arrayDesechos,
      arrayServicios,
      arrayVehiculos,
      servicioErrors,
      vehiculoErrors,
      dialogoGaleria,
      datosFiltrados,
      disabledBtnFoto,
      capacidadErrors,
      dialogoSinFotos,
      arrayRutasPesaje,
      choferEntradaErrors,
      dialogoConfirmacion,
      dialogoFormularioManual,
      guardar,
      clickRow,
      fijarData,
      cambiarCamara,
      guardarImagen,
      guardarManual,
      resincronizar,
      imprimirBoleto,
      confirmarLimpiar,
      abrirDialogoManual,
      abrirDialogoGaleria,
      cerrarDialogoGaleria,
      cerrarDialogoFormularioManual,
    };
  },
});
