
//decoradores
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
//tipos
import { Pesaje } from "@/typings/store/plugins/easyFirestore/pesajes";
import {
  Archivo,
  Archivos,
} from "@/typings/store/plugins/easyFirestore/archivos";

@Component
export default class GaleriaImagenes extends Vue {
  @Prop() readonly pesaje!: Pesaje;
  @Prop() readonly archivos!: Archivos;

  fotoSeleccionada: string | null = null;

  get arrayArchivos(): Array<Archivo> {
    return Object.values(this.archivos);
  }

  get fotos(): Array<string> {
    return this.pesaje.fotos.map((foto) => {
      return foto.url ?? "";
    });
  }

  @Watch("pesaje")
  onPesajeChange(): void {
    this.fotoSeleccionada = null;
  }

  @Emit("click:cerrar")
  onClickCerrar(): void {
    return;
  }

  seleccionarFoto(foto: string): void {
    this.fotoSeleccionada = foto;
  }
}
