
//decoradores
import { Component, Emit, Vue } from "vue-property-decorator";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: { SvgIcon },
})
export default class AppBarPagina extends Vue {
  get botones(): Array<{ id: number; to: string; name: string }> {
    return [
      {
        id: 1,
        to: "inicio",
        name: this.$t("pagina.app-bar.botones-rutas.0") as string,
      },
      {
        id: 2,
        to: "que-es",
        name: this.$t("pagina.app-bar.botones-rutas.1") as string,
      },
      {
        id: 3,
        to: "caracteristicas",
        name: this.$t("pagina.app-bar.botones-rutas.2") as string,
      },
      {
        id: 4,
        to: "testimonios",
        name: this.$t("pagina.app-bar.botones-rutas.3") as string,
      },
      {
        id: 5,
        to: "contacto",
        name: this.$t("pagina.app-bar.botones-rutas.4") as string,
      },
    ];
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.mobile;
  }

  @Emit("click:logout")
  onClickLogout(): void {
    return;
  }

  buttonScrollTo(to: string): void {
    this.$vuetify.goTo(`#${to}`);
  }

  goLogin(): void {
    this.$router.push({ name: "login" });
  }
}
