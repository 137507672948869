
//decoradores
import { Vue, Component } from "vue-property-decorator";
//vuex
import { mapActions, mapGetters } from "vuex";
// Helpers
import routeGuard from "@/helpers/routeGuard";
//tipos
import { FiltrosDesecho } from "@/typings/components/desechos/filtros";
import {
  Desecho,
  Desechos,
} from "@/typings/store/plugins/easyFirestore/desechos";
//componentes
import SuccessDialog from "@/components/custom/SuccessDialog.vue";
import TablaDesechos from "@/components/desechos/Tabla.vue";
import DetalleDesecho from "@/components/desechos/Detalle.vue";
import FiltrosDesechos from "@/components/desechos/Filtros.vue";
import FormularioDesecho from "@/components/desechos/FormularioDesecho.vue";
import ConfirmationDialog from "@/components/custom/ConfirmationDialog.vue";

@Component({
  computed: mapGetters({
    getDesechos: "desechos/get",
    getRutasUsuario: "usuario/getRutas",
    getPermisosUsuario: "usuario/getPermisos",
  }),
  methods: mapActions({
    setDesecho: "desechos/set",
    deleteDesecho: "desechos/delete",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    SuccessDialog,
    TablaDesechos,
    DetalleDesecho,
    FiltrosDesechos,
    FormularioDesecho,
    ConfirmationDialog,
  },
})
export default class HomeDesechos extends Vue {
  created(): void {
    if (!routeGuard()) {
      return;
    }
  }

  desecho: Desecho | null = null;
  cargando = false;
  dialogoFormulario = false;
  dialogoInfo = false;
  dialogoConfirmacion: {
    model: boolean;
    texto: string;
    si: (() => void) | null;
    no: (() => void) | null;
  } = {
    model: false,
    texto: "",
    si: null,
    no: null,
  };
  dialogoExito = {
    model: false,
    mensaje: "",
    icono: "",
  };
  filtros: FiltrosDesecho = {
    search: "",
  };

  get desechos(): Desechos {
    return this.getDesechos;
  }

  get items(): Array<Desecho> {
    return Object.values(this.desechos);
  }

  abrirNuevo(): void {
    this.desecho = null;
    this.dialogoFormulario = true;
    this.dialogoInfo = false;
  }

  clickAbrirInformacion(desecho: Desecho): void {
    if (this.dialogoFormulario && this.desecho) {
      this.abrirDialogoSinGuardar(desecho);
    } else {
      this.abrirInformacion(desecho);
    }
  }

  abrirDialogoSinGuardar(desecho: Desecho): void {
    this.dialogoConfirmacion = {
      model: true,
      texto: this.$t(
        "desechos.mensaje-confirmacion.message.save-changes"
      ) as string,
      si: () => {
        this.abrirInformacion(desecho);
        this.cerrarDialogoConfirmacion();
      },
      no: () => {
        this.cerrarDialogoConfirmacion();
      },
    };
  }

  abrirInformacion(desecho: Desecho): void {
    const data = this.desechos[desecho.id ?? ""];
    if (this.desecho?.id === desecho.id) {
      this.desecho = null;
      this.dialogoInfo = false;
    } else if (data) {
      this.desecho = data;
      this.dialogoInfo = true;
      this.dialogoFormulario = false;
    }
  }

  abrirEditar(): void {
    this.dialogoFormulario = true;
    this.dialogoInfo = false;
  }

  abrirDialogoEliminar(): void {
    this.dialogoConfirmacion = {
      model: true,
      texto: this.$t("desechos.mensaje-confirmacion.message.delete") as string,
      si: () => {
        this.clickEliminar();
      },
      no: () => {
        this.cerrarDialogoConfirmacion();
      },
    };
  }

  clickEliminar(): void {
    const desecho = this.desecho;
    if (desecho) {
      this.deleteDesecho(desecho.id ?? "");
      this.desecho = null;
      this.dialogoInfo = false;
      this.cerrarDialogoConfirmacion();
    }
  }

  async clickGuardar(desecho: Desecho): Promise<void> {
    this.cargando = true;
    await this.setDesecho(desecho);
    this.cargando = false;
    this.dialogoFormulario = false;
    if (this.desecho) {
      this.abrirDialogoExito(
        this.$t("desechos.dialogo-exito.message.save") as string,
        "ag-save"
      );
    } else {
      this.abrirDialogoExito(
        this.$t("desechos.dialogo-exito.message.create") as string,
        "desechos"
      );
    }
    this.desecho = null;
  }

  abrirDialogoExito(mensaje: string, icono: string): void {
    this.dialogoExito = {
      model: true,
      mensaje: mensaje,
      icono: icono,
    };
  }

  cerrarDialogoExito(): void {
    this.dialogoExito = {
      model: false,
      mensaje: "",
      icono: "",
    };
  }

  cerrarDialogoConfirmacion(): void {
    this.dialogoConfirmacion = {
      model: false,
      texto: "",
      si: null,
      no: null,
    };
  }
}
