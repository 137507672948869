
//composition
import { ref, defineComponent, computed } from "vue";
//vuex
import store from "@/store";
import { mapGetters } from "vuex";
// Helpers
import routeGuard from "@/helpers/routeGuard";
//i18n
import i18n from "@/plugins/i18n";
//tipos
import { Plan, PlanPatch } from "@/typings/store/plugins/easyFirestore/planes";
import { FiltrosPlan } from "@/typings/components/planes/filtros";
//componentes
import FiltrosPlanes from "@/components/planes/Filtros.vue";
import TablaPlanes from "@/components/planes/Tabla.vue";
import FormularioPlan from "@/components/planes/Formulario.vue";
import ConfirmationDialog from "@/components/custom/ConfirmationDialog.vue";
import SuccessDialog from "@/components/custom/SuccessDialog.vue";
import InformacionPlan from "@/components/planes/Informacion.vue";

export default defineComponent({
  name: "VistaPlanes",
  components: {
    InformacionPlan,
    TablaPlanes,
    SuccessDialog,
    FiltrosPlanes,
    FormularioPlan,
    ConfirmationDialog,
  },
  computed: mapGetters({
    getRutasUsuario: "usuario/getRutas",
    getPermisosUsuario: "usuario/getPermisos",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  created() {
    if (!routeGuard()) {
      return;
    }
  },
  setup() {
    const getters = mapGetters({
      getArrayPlanes: "planes/getArray",
    });

    const arrayPlanes = computed<Array<Plan>>(getters.getArrayPlanes);

    const planSeleccionado = ref<Plan | null>(null);
    const mostrarFormulario = ref(false);
    const mostrarInformacion = ref(false);
    const dialogoConfirmacion = ref<{
      model: boolean;
      texto: string;
      si: (() => void) | null;
      no: (() => void) | null;
    }>({
      model: false,
      texto: "",
      si: null,
      no: null,
    });
    const dialogoExito = ref({
      model: false,
      mensaje: "",
      icono: "",
    });
    const filtros = ref<FiltrosPlan>({
      buscar: "",
      estado: null,
      tipo: null,
      tipoPago: null,
    });

    const planes = computed(() => {
      return arrayPlanes.value.filter((plan) => {
        const filtroEstado =
          filtros.value.estado !== null
            ? filtros.value.estado === plan.estado
            : true;
        const filtroTipo =
          filtros.value.tipo !== null ? filtros.value.tipo === plan.tipo : true;
        const filtroTipoPago =
          filtros.value.tipoPago !== null
            ? filtros.value.tipoPago === plan.tipoPago
            : true;
        return filtroEstado && filtroTipo && filtroTipoPago;
      });
    });

    const onClickAgregar = () => {
      mostrarFormulario.value = true;
      mostrarInformacion.value = false;
      planSeleccionado.value = null;
    };

    const onClickEditar = (plan: Plan) => {
      mostrarFormulario.value = true;
      mostrarInformacion.value = false;
      planSeleccionado.value = plan;
    };

    const onClickInformacion = (plan: Plan) => {
      if (planSeleccionado.value !== null && mostrarFormulario.value) {
        dialogoConfirmacion.value = {
          model: true,
          texto: i18n.t("planes.confirmacion.editar") as string,
          si: () => {
            cerrarDialogoConfirmacion();
            abrirInformacion(plan);
          },
          no: () => {
            cerrarDialogoConfirmacion();
          },
        };
      } else {
        abrirInformacion(plan);
      }
    };

    const abrirInformacion = (plan: Plan) => {
      if (planSeleccionado.value?.id === plan.id) {
        planSeleccionado.value = null;
        mostrarInformacion.value = false;
      } else {
        planSeleccionado.value = plan;
        mostrarInformacion.value = true;
      }
      mostrarFormulario.value = false;
    };

    const onClickGuardar = (plan: Plan) => {
      store.dispatch("planes/set", plan);
      if (planSeleccionado.value !== null) {
        dialogoExito.value = {
          model: true,
          mensaje: i18n.t("planes.exito.editar") as string,
          icono: "ag-save",
        };
      } else {
        dialogoExito.value = {
          model: true,
          mensaje: i18n.t("planes.exito.crear") as string,
          icono: "ag-save",
        };
      }
      planSeleccionado.value = null;
      mostrarFormulario.value = false;
    };

    const cerrarDialogoExito = () => {
      dialogoExito.value = {
        model: false,
        mensaje: "",
        icono: "",
      };
    };

    const cerrarDialogoConfirmacion = () => {
      dialogoConfirmacion.value = {
        model: false,
        texto: "",
        si: null,
        no: null,
      };
    };

    const onClickEliminar = (plan: Plan) => {
      dialogoConfirmacion.value = {
        model: true,
        texto: i18n.t("planes.confirmacion.eliminar") as string,
        si: () => {
          cerrarDialogoConfirmacion();
          planSeleccionado.value = null;
          mostrarInformacion.value = false;
          store.dispatch("planes/delete", plan.id);
        },
        no: () => {
          cerrarDialogoConfirmacion();
        },
      };
    };

    const onClickSwitch = (plan: Plan) => {
      if (!plan.id) return;
      const estado = plan.estado;
      const doc: PlanPatch = { id: plan.id, estado: estado };
      doc.estado = estado === "deshabilitado" ? "habilitado" : "deshabilitado";
      store.dispatch("planes/set", doc);
    };

    return {
      filtros,
      planes,
      planSeleccionado,
      mostrarFormulario,
      mostrarInformacion,
      dialogoConfirmacion,
      dialogoExito,
      onClickAgregar,
      onClickEditar,
      onClickGuardar,
      onClickInformacion,
      onClickEliminar,
      onClickSwitch,
      cerrarDialogoExito,
      cerrarDialogoConfirmacion,
    };
  },
});
