
// Composition
import {computed, defineComponent} from "vue";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import i18n from "@/plugins/i18n";

export default defineComponent({
  name: "HeaderListaBarredores",
  components: {
    SvgIcon,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    mostrarBotonAgregar: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    "input",
    "click:nueva-ruta",
    "click:agregar-barredor",
  ],
  setup(props, ctx) {
    // VModel

    const model = computed({
      get() {
        return props.value;
      },
      set(value: boolean) {
        ctx.emit("input", value);
      },
    });

    // Methods

    const t = (key: string) => i18n.t(`barredores.tab.barredores.list.${key}`) as string;

    // Emits

    const onClickNuevaRuta = () => {
      ctx.emit("click:nueva-ruta");
    };

    const onClickAgregarBarredor = () => {
      ctx.emit("click:agregar-barredor");
    };

    return {
      // VModel
      model,
      // Methods
      t,
      // Emits
      onClickNuevaRuta,
      onClickAgregarBarredor,
    };
  },
});
