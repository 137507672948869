
//decoradores
import { Component, Vue } from "vue-property-decorator";

@Component
export default class CaracteristicasBarredoresPagina extends Vue {
  get caracteristicas(): Array<{
    id: number;
    icono: string;
    titulo: string;
    parrafo: string;
  }> {
    return [
      {
        id: 1,
        icono: require("../../assets/png/one-page/marker.png"),
        titulo: this.$t(
          "pagina.caracteristicas-barredores.caracteristicas.0.titulo"
        ) as string,
        parrafo: this.$t(
          "pagina.caracteristicas-barredores.caracteristicas.0.parrafo"
        ) as string,
      },
      {
        id: 2,
        icono: require("../../assets/png/one-page/map.png"),
        titulo: this.$t(
          "pagina.caracteristicas-barredores.caracteristicas.1.titulo"
        ) as string,
        parrafo: this.$t(
          "pagina.caracteristicas-barredores.caracteristicas.1.parrafo"
        ) as string,
      },
      {
        id: 3,
        icono: require("../../assets/png/one-page/diagram.png"),
        titulo: this.$t(
          "pagina.caracteristicas-barredores.caracteristicas.2.titulo"
        ) as string,
        parrafo: this.$t(
          "pagina.caracteristicas-barredores.caracteristicas.2.parrafo"
        ) as string,
      },
    ];
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.mobile;
  }
}
