
//composition
import {defineComponent} from "vue"
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "IconButton",
  components: {
    SvgIcon
  },
  props: {
    color: {
      type: String,
      default: "black"
    },
    icon: {
      type: String,
      required: true
    }
  },
  emits: [
    "click"
  ],
  setup(props, ctx) {
    const onClick = (ev: MouseEvent) => {
      ctx.emit("click", ev);
    };

    return {
      onClick
    };
  }
})
