
// decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

// helpers
import { log } from "@/helpers/env";
// tipos
import { Permisos } from "@/typings/store/modules/permisos";
import { Item } from "@/typings/components/transporte/identificadores";
import { EstadoIdentificador, Identificador } from "@/typings/store/plugins/easyFirestore/identificadores";

// componentes
import IconButton from "@/components/custom/IconButton.vue";

@Component({
  components: {
    IconButton,
  },
})
export default class OpcionesTabla extends Vue {
  @Prop() readonly item!: Item;
  @Prop() readonly width?: string | number;
  @Prop() readonly permisos!: Permisos;
  @Prop() readonly seleccionado!: Identificador | null;

  cargando = false;

  get isSelected(): boolean {
    return (
      this.seleccionado !== null &&
      this.seleccionado.id === this.item.identificador.id
    );
  }

  get mostrarSwitch(): boolean {
    return this.permisos.clientes !== "ver";
  }

  get estado(): EstadoIdentificador {
    const estado = this.item.identificador.estado;
    if (!estado) {
      return "habilitado";
    }
    return estado;
  }

  get switchValue(): boolean {
    this.cargando = false;
    return this.estado !== "deshabilitado";
  }

  set switchValue(value: boolean) {
    if (log) {
      console.log("set switchValue", value);
    }
    this.onClickSwitch();
    this.cargando = true;
  }

  @Emit("click:informacion")
  onClickInfo(): Item {
    return this.item;
  }

  @Emit("click:switch")
  onClickSwitch(): Item {
    return this.item;
  }
}
