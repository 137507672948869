
// Vue
import {computed, defineComponent, ref, watch} from "vue";

// i18n
import i18n from "@/plugins/i18n";
import {Choice, Values} from "vue-i18n";

// Helpers
import BarredoresDesconectados from "@/helpers/BarredoresDesconectados";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "AlertaBarredores",
  components: {
    SvgIcon,
  },
  props: {
    desconectados: {
      required: true,
      type: Object as () => BarredoresDesconectados
    },
  },
  emits: [
    "click:alerta",
  ],
  setup(props, ctx) {
    // Data

    const alerta = ref(false);

    // Computed

    const desconectadosWatch = computed(() => props.desconectados);

    const texto = computed(() => {
      if (props.desconectados.length === 0) return "";
      const length = props.desconectados.length;
      const nombrePrimero = props.desconectados.all[0]?.nombre ?? "";
      return tc("texto", length, {
        nombre: nombrePrimero,
        cantidad: length - 1,
      });
    });

    const accion = computed(() => {
      const length = props.desconectados.length;
      if (length === 0) return "";
      if (length === 1 && !props.desconectados.all[0]?.telefono) return "";
      return tc("accion", length);
    });

    // Methods

    const tc = (key: string, choice?: Choice, values?: Values) => i18n.tc(`alertas-barredores.alerta.${key}`, choice, values);

    const onClickAlerta = () => {
      alerta.value = false;
      if (props.desconectados.length === 1) {
        const telefono = props.desconectados.all[0]?.telefono;
        if (!telefono) return;
        window.open(`tel:${telefono}`);
      } else {
        ctx.emit("click:alerta");
      }
    };

    // Watch

    watch(desconectadosWatch, (value) => {
      if (value.length > 0) alerta.value = true;
    }, {
      deep: true,
      immediate: true,
    });

    return {
      // Data
      alerta,
      // Computed
      texto,
      accion,
      // Methods
      tc,
      onClickAlerta,
    };
  },
});
