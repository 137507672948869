
//decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: {
    SvgIcon,
  },
})
export default class BotonCapturar extends Vue {
  @Prop() readonly img!: string;
  @Prop() readonly disabledBtnFoto?: boolean;
  @Prop() readonly cantidadFotos!: number;

  foto = "";
  timeoutId: number | null = null;

  @Emit("click:crear-foto")
  onClickCrearFoto(): string {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
    this.foto = this.img;
    this.timeoutId = window.setTimeout(this.limpiarFoto, 2500);
    return this.foto;
  }

  @Emit("click:cambiar-camara")
  onClickCambiarCamara(): void {
    return;
  }

  limpiarFoto(): void {
    this.foto = "";
  }
}
