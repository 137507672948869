
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import SvgIcon from "./custom/SvgIcon.vue";

@Component({
  components: { SvgIcon },
})
export default class SubirImagen extends Vue {
  @VModel() file!: File | null;
  @Prop() readonly preview?: string;
  @Prop({ default: "150px" }) readonly height!: string | number;
  @Prop({ default: "110px" }) readonly iconHeight!: string | number;
  @Prop({ type: Boolean }) readonly onlyPreview!: boolean;
  @Prop({ type: Boolean }) readonly nueva!: boolean;

  get textoBoton(): string {
    const index = this.nueva ? 0 : 1;
    return this.$t(`subir-imagen.boton.texto.${index}`) as string;
  }

  get input(): HTMLInputElement {
    return this.$refs.input as HTMLInputElement;
  }

  clickSeleccionarImagen(): void {
    this.input.click();
  }

  subirImagen(payload: Event): void {
    const input = payload.target as HTMLInputElement;
    this.file = input.files?.[0] ?? null;
  }
}
