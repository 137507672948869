
//composition
import { defineComponent } from "vue";

export default defineComponent({
  name: "ConfirmationDialog",
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  emits: ["click:yes", "click:no"],
  setup(props, ctx) {
    const onClickSi = (): void => {
      ctx.emit("click:yes");
    };

    const onClickNo = (): void => {
      ctx.emit("click:no");
    };

    return {
      onClickSi,
      onClickNo,
    };
  },
});
