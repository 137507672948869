
//decoradores
import {Component, Vue, Prop, Emit, VModel} from "vue-property-decorator";
//tipos
import {Ruta} from "@/typings/store/modules/rutas";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: {
    SvgIcon,
  },
})
export default class ListaRutasNavegador extends Vue {
  @Prop() readonly rutas!: Array<Ruta>;

  @VModel() miniVariant!: boolean;

  get version(): string {
    return process.env.VUE_APP_VERSION ?? "0.0.0";
  }

  selected = this.$route.name;

  @Emit("logout")
  onLogout(): void {
    return;
  }

  active(ruta: Ruta): boolean {
    return ruta.to?.name === this.$route.name;
  }
}
