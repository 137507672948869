
//decoradores
import { Component, Vue } from "vue-property-decorator";

@Component
export default class CaracteristicasPesajePagina extends Vue {
  get caracteristicas(): Array<{
    id: number;
    icono: string;
    titulo: string;
    parrafo: string;
  }> {
    return [
      {
        id: 1,
        icono: require("../../assets/png/one-page/weight.png"),
        titulo: this.$t(
          "pagina.caracteristicas-pesaje.caracteristicas.0.titulo"
        ) as string,
        parrafo: this.$t(
          "pagina.caracteristicas-pesaje.caracteristicas.0.parrafo"
        ) as string,
      },
      {
        id: 2,
        icono: require("../../assets/png/one-page/web.png"),
        titulo: this.$t(
          "pagina.caracteristicas-pesaje.caracteristicas.1.titulo"
        ) as string,
        parrafo: this.$t(
          "pagina.caracteristicas-pesaje.caracteristicas.1.parrafo"
        ) as string,
      },
      {
        id: 3,
        icono: require("../../assets/png/one-page/image.png"),
        titulo: this.$t(
          "pagina.caracteristicas-pesaje.caracteristicas.2.titulo"
        ) as string,
        parrafo: this.$t(
          "pagina.caracteristicas-pesaje.caracteristicas.2.parrafo"
        ) as string,
      },
      {
        id: 4,
        icono: require("../../assets/png/one-page/truck.png"),
        titulo: this.$t(
          "pagina.caracteristicas-pesaje.caracteristicas.3.titulo"
        ) as string,
        parrafo: this.$t(
          "pagina.caracteristicas-pesaje.caracteristicas.3.parrafo"
        ) as string,
      },
      {
        id: 5,
        icono: require("../../assets/png/one-page/shield.png"),
        titulo: this.$t(
          "pagina.caracteristicas-pesaje.caracteristicas.4.titulo"
        ) as string,
        parrafo: this.$t(
          "pagina.caracteristicas-pesaje.caracteristicas.4.parrafo"
        ) as string,
      },
      {
        id: 6,
        icono: require("../../assets/png/one-page/document.png"),
        titulo: this.$t(
          "pagina.caracteristicas-pesaje.caracteristicas.5.titulo"
        ) as string,
        parrafo: this.$t(
          "pagina.caracteristicas-pesaje.caracteristicas.5.parrafo"
        ) as string,
      },
      {
        id: 7,
        icono: require("../../assets/png/one-page/dashboard.png"),
        titulo: this.$t(
          "pagina.caracteristicas-pesaje.caracteristicas.6.titulo"
        ) as string,
        parrafo: this.$t(
          "pagina.caracteristicas-pesaje.caracteristicas.6.parrafo"
        ) as string,
      },
      {
        id: 8,
        icono: require("../../assets/png/one-page/user.png"),
        titulo: this.$t(
          "pagina.caracteristicas-pesaje.caracteristicas.7.titulo"
        ) as string,
        parrafo: this.$t(
          "pagina.caracteristicas-pesaje.caracteristicas.7.parrafo"
        ) as string,
      },
    ];
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.mobile;
  }
}
