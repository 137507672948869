
// Vue
import { computed, defineComponent , PropType} from "vue";

// i18n
import { Values } from "vue-i18n";
import i18n from "@/plugins/i18n";

// Tipos
import { Barredor } from "@/typings/store/plugins/easyFirestore/barredores";
import { Posicion } from "@/typings/store/plugins/easyFirestore/pingsRastreador";

export default defineComponent({
  name: "InfoBarredor",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    barredor: {
      required: true,
      type: Object as () => Barredor,
    },
    desconectado: {
      type: Boolean,
      required: true,
    },
    posicion: {
      required: true,
      type: Object as PropType<Posicion | null>,
    },
  },
  emits: ["input", "click:marker"],
  setup(props, ctx) {
    // VModel

    const model = computed({
      get() {
        return props.value;
      },
      set(value: boolean) {
        ctx.emit("input", value);
      },
    });

    // Computed

    const nombre = computed(() =>
      t("nombre", { nombre: props.barredor.nombre })
    );

    const celular = computed(() =>
      t("celular", { celular: props.barredor.telefono || "-" })
    );

    const estado = computed(() =>
      t("estado", {
        estado: props.desconectado ? "Desconectado" : "Activo",
      })
    );

    const estadoStyle = computed(() => ({
      color: props.desconectado ? "#D86C6C" : "#354F57",
    }));

    const markerOptions = computed(() => {
      const size = 36;
      return {
        icon: {
          url: generarMarker(),
          scaledSize: {
            width: size,
            height: size,
          },
          size: {
            width: size,
            height: size,
          },
          anchor: {
            x: size / 2,
            y: size / 2,
          },
        },
      };
    });

    // Methods

    const t = (key: string, values?: Values) =>
      i18n.t(`alertas-barredores.map.info-window.${key}`, values) as string;

    const generarMarker = () => {
      let template = `<svg viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">`;
      if (props.desconectado) {
        template += `<rect width="36" height="36" rx="12">`;
        template += `<animate attributeName="fill" values="#D86C6C;#A94747;#D86C6C" dur="1.5s" repeatCount="indefinite" />`;
        template += `</rect>`;
      } else {
        template += `<rect width="36" height="36" rx="12" fill="#354F57"/>`;
      }
      template += `<text font-family="sans-serif" x="18px" y="24px" fill="#fff" font-size="16px" text-anchor="middle">${props.barredor.numero}</text>`;
      template += `</svg>`;
      return "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(template);
    };

    // Emits

    const onClickMarker = () => {
      ctx.emit("click:marker", props.barredor);
    };

    return {
      // VModel
      model,
      // Computed
      estado,
      nombre,
      celular,
      estadoStyle,
      markerOptions,
      // Methods
      t,
      // Emits
      onClickMarker,
    };
  },
});
