
//decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
//tipos
import { Servicio } from "@/typings/store/plugins/easyFirestore/servicios";
import { ItemsTablaServicios } from "@/typings/components/servicios/tabla";
//componentes
import ItemTabla from "@/components/servicios/tabla/Item.vue";
import OpcionesTabla from "@/components/servicios/tabla/Opciones.vue";
import DataTableFooter from "@/components/custom/DataTableFooter.vue";

@Component({
  components: {
    ItemTabla,
    OpcionesTabla,
    DataTableFooter,
  },
})
export default class TablaServicioes extends Vue {
  @Prop() readonly servicio?: Servicio;
  @Prop() readonly servicios!: Array<Servicio>;
  @Prop() readonly search!: string;

  get headers(): Array<{ text: string; value: string; sortable?: boolean }> {
    return [
      {
        text: this.$t("servicios.tabla.headers[0]") as string,
        value: "nombre",
      },
      {
        text: this.$t("servicios.tabla.headers[2]") as string,
        value: "tipo",
      },
      {
        text: "",
        value: "informacion",
        sortable: false,
      },
    ];
  }

  get noValueMessage(): string {
    return this.$t("servicios.tabla.no-value-message") as string;
  }

  get items(): ItemsTablaServicios {
    return this.servicios.map((servicio) => {
      const id = servicio.id;
      const nombre = servicio.nombre;
      const tipo =
        servicio.tipo ??
        (this.$t("servicios.tabla.no-value-message") as string);
      const descripcion =
        servicio.descripcion ??
        (this.$t("servicios.tabla.no-value-message") as string);
      return {
        id,
        nombre,
        tipo,
        descripcion,
        data: servicio,
      };
    });
  }

  @Emit("click:informacion")
  onClickInformacion(servicio: Servicio): Servicio {
    return servicio;
  }

  getClasses({ data }: { data: Servicio }): {
    capitalize: string;
    uppercase: string;
    normal: string;
  } {
    const seleccionado = this.servicio?.id === data.id;
    return {
      capitalize: `pa-0 metropolis text-center text-body-1 text-capitalize ${
        seleccionado ? "primary--text font-weight-bold" : ""
      }`,
      uppercase: `pa-0 metropolis text-center text-body-1 text-uppercase ${
        seleccionado ? "primary--text font-weight-bold" : ""
      }`,
      normal: `pa-0 metropolis text-center text-body-1 ${
        seleccionado ? "primary--text font-weight-bold" : ""
      }`,
    };
  }
}
