
//decoradores
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
//tipos
import {Pesaje} from "@/typings/store/plugins/easyFirestore/pesajes";
import {Chofer} from "@/typings/store/plugins/easyFirestore/choferes";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import MenuInfoChofer from "@/components/transporte/choferes/MenuInfo.vue";

@Component({
  components: {
    SvgIcon,
    MenuInfoChofer,
  },
})
export default class EntradaSalida extends Vue {
  @Prop() readonly pesaje!: Pesaje;
  @Prop() readonly mostrarGaleria!: boolean;

  tab = 0;

  get noValueMessage(): string {
    return this.$t(
      "reportes.detalle-pesaje.entrada-salida.tab.no-value-message",
    ) as string;
  }

  get fechaEntrada(): string {
    const fechaEntrada = this.pesaje.entrada?.fecha;
    return fechaEntrada
      ? fechaEntrada.toDate().format("DD-MM-YYYY, HH:mm")
      : this.noValueMessage;
  }

  get pesoEntrada(): string {
    const peso = this.pesaje.entrada?.peso?.toCurrency();
    return peso ? peso : this.noValueMessage;
  }

  get observacionEntrada(): string {
    return (
      this.pesaje.entrada?.observacion?.capitalizeFirstLetter() ||
      this.noValueMessage
    );
  }

  get choferEntrada(): Chofer | null {
    return this.pesaje.entrada?.chofer || null;
  }

  get fechaSalida(): string {
    const fechaSalida = this.pesaje.salida?.fecha;
    return fechaSalida
      ? fechaSalida.toDate().format("DD-MM-YYYY, HH:mm")
      : this.noValueMessage;
  }

  get pesoSalida(): string {
    const peso = this.pesaje.salida?.peso?.toCurrency();
    return peso ? peso : this.noValueMessage;
  }

  get observacionSalida(): string {
    return (
      this.pesaje.salida?.observacion?.capitalizeFirstLetter() ||
      this.noValueMessage
    );
  }

  get choferSalida(): Chofer | null {
    return this.pesaje.salida?.chofer || null;
  }

  @Emit("click:ver-imagenes")
  onClickVerImagenes(): void {
    return;
  }

  @Emit("click:imprimir")
  onClickImprimir(): void {
    return;
  }
}
