
import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class ScatterChart extends Vue {
  @Prop() readonly xaxis!: ApexXAxis;
  @Prop() readonly colors!: Array<string>;
  @Prop() readonly labels!: Array<string | number>;
  @Prop() readonly series!: Array<{ name: string; data: Array<Array<number>> }>;

  get options() {
    return {
      chart: {
        id: "scatter-chart",
        type: "scatter",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false
        }
      },
      yaxis: {
        labels: {
          style: {
            fontFamily: "Metropolis",
            colors: this.$vuetify.theme.themes.light.text as string,
          },
        },
      },
      legend: {
        fontFamily: "Metropolis",
        labels: {
          colors: this.$vuetify.theme.themes.light.text as string,
        },
      },
      labels: this.labels,
      colors: this.colors,
      xaxis: this.xaxis,
    };
  }
}
