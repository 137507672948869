
// vue
import { defineComponent, computed, ref, reactive } from "vue";
// composables
import { useI18n } from "@/composables/i18n";
//tipos
import {
  PingRastreador,
  Posicion,
} from "@/typings/store/plugins/easyFirestore/pingsRastreador";
import { Empresa } from "@/typings/store/plugins/easyFirestore/empresas";
import { Barredor } from "@/typings/store/plugins/easyFirestore/barredores";
// componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
// validaciones
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

declare interface GoogleMapClickEvent {
  latLng: {
    lat: () => number;
    lng: () => number;
  };
}

export default defineComponent({
  components: { SvgIcon },
  props: {
    empresa: {
      default: null,
      type: Object as () => Empresa | null,
    },
    barredores: {
      default: () => [],
      type: Array as () => Barredor[],
    },
  },
  created(): void {
    if (this.empresa?.posicion) {
      this.center = this.empresa.posicion;
    }
  },
  emits: ["click:cerrar", "click:guardar"],
  setup(props, ctx) {
    const { t } = useI18n();

    const center = ref({
      lat: -35.4320809,
      lng: -71.6546965,
    });
    const menu = ref(false);
    const path = ref<Array<Posicion>>([]);
    const barredor = ref<Barredor | null>(null);
    const fecha = ref("");

    const titulo = computed((): string => {
      return t("barredores.tab.barredores.nuevaRuta.titulo") as string;
    });

    const ultima = computed((): Posicion | null => {
      return path.value.length > 0 ? path.value[path.value.length - 1] : null;
    });

    const textoFecha = computed((): string => {
      return (
        (fecha.value ? fecha.value.toDate().format("DD/MM/YYYY") : null) ||
        (t("barredores.tab.barredores.nuevaRuta.fecha.placeholder") as string)
      );
    });

    const state = reactive({
      barredor,
      fecha,
    });

    const rules = {
      barredor: { required },
      fecha: { required },
    };

    const v$ = useVuelidate(rules, state);

    const erroresBarredor = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.barredor.$dirty) return errores;
      if (v$.value.barredor.required.$invalid) {
        const error = t(
          "barredores.tab.barredores.nuevaRuta.barredor.error-message"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresFecha = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.fecha.$dirty) return errores;
      if (v$.value.fecha.required.$invalid) {
        const error = t(
          "barredores.tab.barredores.nuevaRuta.fecha.error-message"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const onClickCerrar = (): void => {
      ctx.emit("click:cerrar");
    };

    const onClickGuardar = (data: Array<PingRastreador>): void => {
      ctx.emit("click:guardar", data);
    };

    const mapClickHandler = ({ latLng }: GoogleMapClickEvent): void => {
      const temp = path.value.slice();
      temp.push({
        lat: latLng.lat(),
        lng: latLng.lng(),
      });
      path.value = temp;
    };

    const polylineClickHandler = ({ latLng }: GoogleMapClickEvent): void => {
      const temp = path.value.slice();
      temp.push({
        lat: latLng.lat(),
        lng: latLng.lng(),
      });
      path.value = temp;
    };

    const eliminarUltimo = (): void => {
      const temp = path.value.slice();
      temp.pop();
      path.value = temp;
    };

    const guardar = (): void => {
      v$.value.$touch();
      if (v$.value.$invalid || !barredor.value) return;
      const pings: Array<PingRastreador> = [];
      const fechaTemp = fecha.value.toDate();
      fechaTemp.setHours(7, 0, 0, 0);
      for (const posicion of path.value) {
        const ping: PingRastreador = {
          imei: barredor.value.rastreador?.imei ?? "",
          fecha: fechaTemp.toISOString(),
          posicion: posicion,
          empresa: props.empresa?.id ?? "",
          orientacion: 1,
          valido: true,
          velocidad: 4,
          barredores: [barredor.value],
        };
        pings.push(ping);
        fechaTemp.setMinutes(fechaTemp.getMinutes() + 1);
      }
      onClickGuardar(pings);
    };

    return {
      t,
      v$,
      center,
      menu,
      path,
      barredor,
      fecha,
      titulo,
      ultima,
      textoFecha,
      erroresBarredor,
      erroresFecha,
      onClickCerrar,
      onClickGuardar,
      mapClickHandler,
      polylineClickHandler,
      eliminarUltimo,
      guardar,
    };
  },
});
