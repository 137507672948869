
// composition-api
import { defineComponent, PropType, computed } from "vue";
//tipos
import { Pesaje } from "@/typings/store/plugins/easyFirestore/pesajes";
//componentes
import TabVehiculo from "@/components/monitoreo/vehiculos/Tab.vue";
import TabDesechos from "@/components/monitoreo/desechos/TabDesechos.vue";

export default defineComponent({
  components: {
    TabVehiculo,
    TabDesechos,
  },
  props: {
    value: {
      type: Number,
      required: true,
    },
    arrayPesajes: {
      type: Array as PropType<Pesaje[]>,
      required: true,
    },
    periodo: {
      type: Number as PropType<number>,
      required: true,
    },
    fechas: {
      type: Array as PropType<Date[]>,
      required: true,
    },
    unidadDesechos: {
      type: String as PropType<"t"| "kg" | "m3">,
      required: true,
    },
  },
  emits: ["input"],
  setup(props, ctx) {
    const tab = computed({
      get() {
        return props.value;
      },
      set(val) {
        ctx.emit("input", val);
      },
    });

    return { tab };
  },
});
