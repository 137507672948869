
// decoradores
import { Component, Vue, Watch } from "vue-property-decorator";
// vuex
import { mapActions, mapGetters } from "vuex";
// tipos
import { Ruta } from "@/typings/store/modules/rutas";
import { Usuario } from "./typings/store/plugins/easyFirestore/usuarios";
import { Empresa } from "./typings/store/plugins/easyFirestore/empresas";
// componentes
import AppBar from "@/components/AppBar.vue";
import NavDrawer from "@/components/navDrawer/NavDrawer.vue";
import CargandoWeighTruck from "@/components/CargandoWeighTruck.vue";
import SnackbarActualizar from "@/components/SnackbarActualizar.vue";
import SnackbarCerrarTab from "@/components/SnackbarCerrarTab.vue";
import SnackbarPagoPendiente from "@/components/SnackbarPagoPendiente.vue";
import AppBarMobile from "@/components/appCore/AppBarMobile.vue";

@Component({
  computed: mapGetters({
    getUser: "user/get",
    getDrawer: "drawer/get",
    getCargando: "cargando/get",
    getErrorCargando: "cargando/getError",
    getUsuario: "usuario/get",
    getRutasUsuario: "usuario/getRutas",
    getEmpresaUsuario: "usuario/getEmpresa",
    getUpdatedSW: "sw/getUpdated",
  }),
  methods: mapActions({
    setDrawer: "drawer/set",
    setCargando: "cargando/set",
    signOutUsuario: "usuario/signOut",
  }),
  components: {
    AppBarMobile,
    AppBar,
    NavDrawer,
    CargandoWeighTruck,
    SnackbarActualizar,
    SnackbarCerrarTab,
    SnackbarPagoPendiente,
  },
})
export default class App extends Vue {
  created(): void {
    if (!this.$route.name) {
      this.$router.push({ name: "login" });
    }
  }

  nombre = "";
  miniVariant = false;
  banderaCargandoWeighTruck = true;

  get appClass(): { [key: string]: boolean } {
    return {
      "background-white": this.$route.name === "inicio",
      "background-no-white": this.$route.name !== "inicio",
    };
  }

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get updatedSw(): boolean {
    return this.getUpdatedSW;
  }

  get errorCargando(): boolean {
    return this.getErrorCargando;
  }

  get drawer(): boolean {
    return this.getDrawer;
  }

  set drawer(data: boolean) {
    this.setDrawer(data);
  }

  get cargando(): boolean {
    return this.getCargando;
  }

  get usuario(): Usuario | null {
    return this.getUsuario;
  }

  get empresa(): Empresa {
    return this.getEmpresaUsuario;
  }

  get rutas(): Array<Ruta> {
    return this.getRutasUsuario.filter(_ruta => {
      const mobile = this.$vuetify.breakpoint.mdAndDown;
      return mobile ? _ruta.mobile : _ruta.desktop;
    });
  }

  get verAppBar(): boolean {
    return this.usuario != null && !this.banderaCargandoWeighTruck;
  }

  get breakpoint(): string {
    return this.$vuetify.breakpoint?.name;
  }

  @Watch("cargando")
  onCargandoChange(val: boolean): void {
    if (val) {
      this.banderaCargandoWeighTruck = true;
    }
  }

  onMiniVariantChange(val: boolean): void {
    this.miniVariant = val;
  }

  logout(): void {
    this.signOutUsuario();
  }
}
