
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";

@Component
export default class TabsBarredores extends Vue {
  @VModel() tab!: number;
  @Prop() readonly usuario!: Usuario;

  get isAdmin(): boolean {
    return this.usuario.tipo === "administrador";
  }
}
