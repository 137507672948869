
//decoradores
import {Component, Prop, Vue} from "vue-property-decorator";
//tipos
import {Pesaje} from "@/typings/store/plugins/easyFirestore/pesajes";
//componentes
import ScatterChart from "../charts/ScatterChart.vue";

type Series = Array<{ name: string; data: Array<Array<number>> }>;

@Component({
  components: {
    ScatterChart,
  },
})
export default class TiempoDescargaVehiculos extends Vue {
  @Prop() readonly pesajes!: Array<Pesaje>;
  @Prop() readonly periodo!: number;
  @Prop() readonly fechas!: Array<Date>;

  get colors(): string[] {
    return [
      this.$vuetify.theme.themes.light.yellow as string,
      this.$vuetify.theme.themes.light.primary as string,
      this.$vuetify.theme.themes.light.error as string,
    ];
  }

  get diferenciaDias(): number {
    const [fechaInicio, fechaFin] = this.fechas;
    if (fechaFin && fechaInicio) {
      const diferencia = fechaFin.getTime() - fechaInicio.getTime();
      return Math.round(diferencia / (1000 * 60 * 60 * 24));
    }
    return 0;
  }

  get fechasValidas(): Array<Date> {
    const fechas = [];
    const [fechaInicio, fechaFin] = this.fechas;
    const fecha = new Date(fechaInicio);
    while (fecha <= fechaFin) {
      fechas.push(new Date(fecha));
      if (this.periodo === 1 || (this.periodo === 6 && this.diferenciaDias <= 8)) {
        fecha.setDate(fecha.getDate() + 1);
      } else if (this.periodo === 2 || (this.periodo === 6 && this.diferenciaDias <= 31)) {
        fecha.setDate(fecha.getDate() + 3);
      } else if (this.periodo === 3 || (this.periodo === 6 && this.diferenciaDias <= 91)) {
        fecha.setDate(fecha.getDate() + 7);
      } else {
        fecha.setMonth(fecha.getMonth() + 1);
      }
    }
    return fechas;
  }

  get labels(): Array<string | number> {
    return this.fechasValidas.map((fecha) => {
      if (this.periodo >= 1 && this.periodo <= 3) {
        return fecha.getTime();
      } else if (this.periodo <= 5) {
        return fecha.format("MMMM");
      } else {
        return this.diferenciaDias <= 91 ? fecha.getTime() : fecha.format("MMMM");
      }
    });
  }

  get xaxis(): ApexXAxis {
    let type: "datetime" | "category";
    if (this.periodo >= 1 && this.periodo <= 3) {
      type = "datetime";
    } else if (this.periodo <= 5) {
      type = "category";
    } else {
      type = this.diferenciaDias <= 91 ? "datetime" : "category";
    }
    return {
      type: type,
      tickAmount: this.labels.length,
      labels: {
        style: {
          fontFamily: "Metropolis",
          colors: this.$vuetify.theme.themes.light.text as string,
        },
        formatter(value: string) {
          return new Date(parseInt(value.toString())).format(type === "datetime" ? "DD/MM/YY" : "MMMM");
        },
      },
    };
  }

  get series(): Series {
    const result: Series = [{
      name: this.$t("monitoreo.tab.vehiculos.grafico-4.times.0") as string,
      data: [],
    }, {
      name: this.$t("monitoreo.tab.vehiculos.grafico-4.times.1") as string,
      data: [],
    }, {
      name: this.$t("monitoreo.tab.vehiculos.grafico-4.times.2") as string,
      data: [],
    }];
    for (const pesaje of this.pesajes) {
      const fechaPesaje = pesaje.entrada.fecha?.toDate();
      const fechaEntrada = pesaje.entrada.fecha?.toDate();
      const fechaSalida = pesaje.salida?.fecha?.toDate();
      if (fechaPesaje && fechaEntrada && fechaSalida) {
        fechaPesaje.setHours(0, 0, 0, 0);
        const duracionMinutos = (fechaSalida.getTime() - fechaEntrada.getTime()) / (1000 * 60);
        const fecha = this.obtenerFecha(fechaPesaje);
        if (fecha) {
          const item = this.obtenerItem(result, duracionMinutos);
          const existe = item.data.find((d) => d[0] === fecha.getTime());
          if (existe) {
            existe[1]++;
          } else {
            item.data.push([fecha.getTime(), 1]);
          }
        }
      }
    }
    return result;
  }

  obtenerItem(result: Series, duracion: number) {
    if (duracion < 25) return result[0];
    if (duracion < 30) return result[1];
    return result[2];
  }

  obtenerFecha(fechaPesaje: Date): Date {
    const fecha = this.fechasValidas.find((f) => {
      if (this.periodo >= 1 && this.periodo <= 3) {
        return f.getTime() >= fechaPesaje.getTime();
      } else if (this.periodo <= 5) {
        return f.getMonth() === fechaPesaje.getMonth();
      } else {
        return this.diferenciaDias <= 91 ? f.getTime() >= fechaPesaje.getTime() : f.getMonth() === fechaPesaje.getMonth();
      }
    });
    return fecha ?? this.fechasValidas[this.fechasValidas.length - 1];
  }
}
