
// decoradores
import { Vue, Component, Emit } from "vue-property-decorator";
// componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: {
    SvgIcon,
  },
})
export default class Formulario extends Vue {
  async mounted(): Promise<void> {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    this.onClose();
  }

  @Emit("close")
  onClose(): void {
    return;
  }
}
