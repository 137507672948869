
//composition
import { defineComponent, ref, computed } from "vue";
//i18n
import i18n from "@/plugins/i18n";
//helpers
import { imageToWebp } from "@/helpers/image";
//tipos
import { DataFormularioClientes } from "@/typings/components/clientes/formulario";
import {
  Cliente,
  EstadoCliente,
  TipoCliente,
} from "@/typings/store/plugins/easyFirestore/clientes";
//validadores
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
//componentes
import SubirImagen from "@/components/SubirImagen.vue";
import FormTextField from "@/components/custom/FormTextField.vue";
import FormSelect from "@/components/custom/FormSelect.vue";
import ActionButton from "@/components/custom/ActionButton.vue";

export default defineComponent({
  name: "FormularioCliente",
  components: {
    ActionButton,
    FormSelect,
    FormTextField,
    SubirImagen,
  },
  props: {
    cliente: {
      type: Object as () => Cliente | null,
    },
    cargando: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["click:guardar"],
  setup(props, ctx) {
    const tipos = [
      {
        text: i18n.t("clientes.formulario.tipo.values[0]") as string,
        value: "empresa",
      },
      {
        text: i18n.t("clientes.formulario.tipo.values[1]") as string,
        value: "particular",
      },
      {
        text: i18n.t("clientes.formulario.tipo.values[2]") as string,
        value: "municipal",
      },
    ];

    const id = ref<string | null>(null);
    const nombre = ref("");
    const rut = ref("");
    const tipo = ref<TipoCliente | null>(null);
    const giro = ref("");
    const ciudad = ref("");
    const direccion = ref("");
    const contacto = ref("+56");
    const correo = ref("");
    const estado = ref<EstadoCliente>("habilitado");
    const file = ref<File | null>(null);

    if (props.cliente) {
      id.value = props.cliente.id ?? null;
      nombre.value = props.cliente.nombre;
      rut.value = props.cliente.rut ?? "";
      tipo.value = props.cliente.tipo ?? null;
      giro.value = props.cliente.giro ?? "";
      ciudad.value = props.cliente.ciudad ?? "";
      direccion.value = props.cliente.direccion ?? "";
      contacto.value = props.cliente.contacto ?? "+56";
      correo.value = props.cliente.correo ?? "";
      estado.value = props.cliente.estado ?? "habilitado";
    }

    const rules = computed(() => ({
      nombre: { required },
      rut: { required },
      tipo: { required },
      giro: { required },
      ciudad: { required },
      direccion: { required },
      contacto: { required },
      correo: { required, email },
    }));

    const v$ = useVuelidate(rules, {
      nombre,
      rut,
      tipo,
      giro,
      ciudad,
      direccion,
      contacto,
      correo,
    });

    const titulo = computed(() => {
      const index = props.cliente ? 1 : 0;
      return i18n.t(`clientes.formulario.title.${index}`) as string;
    });

    const buttonText = computed(() => {
      const index = props.cliente ? 0 : 1;
      return i18n.t(`clientes.formulario.boton.text.${index}`) as string;
    });

    const foto = computed(() => {
      return file.value
        ? URL.createObjectURL(file.value)
        : props.cliente?.foto?.url ?? "";
    });

    const erroresNombre = computed(() => {
      const errores: Array<string> = [];
      if (!v$.value.nombre.$dirty) return errores;
      if (v$.value.nombre.required.$invalid) {
        const error = i18n.t(
          "clientes.formulario.nombre.error-messages.0"
        ) as string;
        errores.push(error);
      }
      if (nombre.value.length > 30) {
        const error = i18n.t(
          "clientes.formulario.nombre.error-messages.1"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresRut = computed(() => {
      const errores: Array<string> = [];
      if (!v$.value.rut.$dirty) return errores;
      if (v$.value.rut.required.$invalid) {
        const error = i18n.t(
          "clientes.formulario.rut.error-messages.0"
        ) as string;
        errores.push(error);
      }
      if (!rut.value.validateRUT()) {
        const error = i18n.t(
          "clientes.formulario.rut.error-messages.1"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresTipo = computed(() => {
      const errores: Array<string> = [];
      if (!v$.value.tipo.$dirty) return errores;
      if (v$.value.tipo.required.$invalid) {
        const error = i18n.t("clientes.formulario.tipo.error-message") as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresGiro = computed(() => {
      const errores: Array<string> = [];
      if (!v$.value.giro.$dirty) return errores;
      if (v$.value.giro.required.$invalid) {
        const error = i18n.t("clientes.formulario.giro.error-message") as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresCiudad = computed(() => {
      const errores: Array<string> = [];
      if (!v$.value.ciudad.$dirty) return errores;
      if (v$.value.ciudad.required.$invalid) {
        const error = i18n.t(
          "clientes.formulario.ciudad.error-message"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresDireccion = computed(() => {
      const errores: Array<string> = [];
      if (!v$.value.direccion.$dirty) return errores;
      if (v$.value.direccion.required.$invalid) {
        const error = i18n.t("clientes.formulario.direccion.error-message") as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresContacto = computed(() => {
      const errores: Array<string> = [];
      if (!v$.value.contacto.$dirty) return errores;
      if (v$.value.contacto.required.$invalid) {
        const error = i18n.t(
          "clientes.formulario.telefono.error-message"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresCorreo = computed(() => {
      const errores: Array<string> = [];
      if (!v$.value.correo.$dirty) return errores;
      if (v$.value.correo.required.$invalid) {
        const error = i18n.t(
          "clientes.formulario.correo.error-messages.0"
        ) as string;
        errores.push(error);
      }
      if (v$.value.correo.email.$invalid) {
        const error = i18n.t(
          "clientes.formulario.correo.error-messages.1"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const crearDisabled = computed(() => {
      if (props.cliente) return false;
      return (
        !nombre.value ||
        !rut.value ||
        !giro.value ||
        !ciudad.value ||
        !direccion.value ||
        !contacto.value ||
        !correo.value
      );
    });

    const onKeydownHandlerGiro = (ev: KeyboardEvent) => {
      if (ev.key.length > 1) return;
      const regex = /[0-9]/;
      if (regex.test(ev.key)) ev.preventDefault();
    };

    const onKeydownHandlerCiudad = (ev: KeyboardEvent) => {
      if (ev.key.length > 1) return;
      const regex = /[a-zA-ZñÑ\s]/;
      if (!regex.test(ev.key)) ev.preventDefault();
    };

    const guardar = async () => {
      v$.value.$touch();
      if (
        !rut.value.validateRUT() ||
        !tipo.value ||
        nombre.value.length > 30 ||
        v$.value.$invalid
      )
        return;
      const cliente: Cliente = {
        nombre: nombre.value,
        rut: rut.value,
        giro: giro.value,
        tipo: tipo.value,
        ciudad: ciudad.value,
        direccion: direccion.value,
        contacto: contacto.value,
        correo: correo.value,
        estado: estado.value,
        foto: null,
      };
      if (id.value) cliente.id = id.value;
      const data: DataFormularioClientes = { cliente };
      if (file.value) {
        const imagenRaw = file.value;
        const fecha = new Date();
        const extencion = imagenRaw.type.split("/")[1];
        let nombre = `${fecha.valueOf()}.${extencion}`;
        let imagen = await imageToWebp(imagenRaw);
        if (imagen) {
          nombre = `${fecha.valueOf()}.webp`;
        } else {
          imagen = imagenRaw;
        }
        const fechaCarpeta = new Date(fecha);
        fechaCarpeta.setHours(0, 0, 0, 0);
        const nombreCarpeta = `${fechaCarpeta.valueOf()}`;
        const ref = `clientes/${nombreCarpeta}/${nombre}`;
        data.archivo = {
          ref: ref,
          file: imagen,
          nombre: nombre,
        };
      }
      ctx.emit("click:guardar", data);
    };

    return {
      v$,
      titulo,
      buttonText,
      tipos,
      nombre,
      rut,
      giro,
      ciudad,
      direccion,
      contacto,
      correo,
      estado,
      tipo,
      id,
      file,
      foto,
      erroresNombre,
      erroresRut,
      erroresGiro,
      erroresTipo,
      erroresCiudad,
      erroresDireccion,
      erroresContacto,
      erroresCorreo,
      crearDisabled,
      onKeydownHandlerGiro,
      onKeydownHandlerCiudad,
      guardar,
    };
  },
});
