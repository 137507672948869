
// decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// helpers
import { log } from "@/helpers/env";
// tipos
import { Item } from "@/typings/components/usuarios/tabla";
import { Permisos } from "@/typings/store/modules/permisos";
import {
  EstadoUsuario,
  Usuario,
} from "@/typings/store/plugins/easyFirestore/usuarios";
// componentes
import IconButton from "@/components/custom/IconButton.vue";

@Component({
  components: {
    IconButton,
  },
})
export default class OpcionesTabla extends Vue {
  @Prop() readonly item!: Item;
  @Prop() readonly width?: string | number;
  @Prop() readonly permisos!: Permisos;
  @Prop() readonly seleccionado!: Usuario | null;

  cargando = false;

  get isSelected(): boolean {
    return this.seleccionado !== null && this.seleccionado.id === this.item.id;
  }

  get mostrarSwitch(): boolean {
    return this.permisos.usuarios !== "ver";
  }

  get estado(): EstadoUsuario {
    const estado = this.item.data.estado;
    if (!estado) {
      return "activo";
    }
    return estado;
  }

  get switchValue(): boolean {
    this.cargando = false;
    return this.estado !== "deshabilitado";
  }

  set switchValue(value: boolean) {
    if (log) {
      console.log("set switchValue", value);
    }
    this.onClickSwitch();
    this.cargando = true;
  }

  @Emit("click-info")
  onClickInfo(): Item {
    return this.item;
  }

  @Emit("click-switch")
  onClickSwitch(): Item {
    return this.item;
  }
}
