
//composition
import { computed, defineComponent } from "vue";
//i18n
import i18n from "@/plugins/i18n";
//tipos
import { Plan } from "@/typings/store/plugins/easyFirestore/planes";
//componentes
import InformationText from "@/components/custom/InformationText.vue";
import ActionButton from "@/components/custom/ActionButton.vue";

export default defineComponent({
  name: "InformacionPlan",
  components: {
    ActionButton,
    InformationText,
  },
  emits: ["click:editar", "click:eliminar"],
  props: {
    plan: {
      type: Object as () => Plan,
      required: true,
    },
  },
  setup(props, ctx) {
    const noRegistrado = computed(() => {
      return i18n.t("planes.informacion.no-registrado") as string;
    });

    const tipos = computed(() => {
      return {
        ["plan a"]: i18n.t("planes.informacion.tipos.0") as string,
        ["plan b"]: i18n.t("planes.informacion.tipos.1") as string,
        ["plan c"]: i18n.t("planes.informacion.tipos.2") as string,
        ["plan d"]: i18n.t("planes.informacion.tipos.3") as string,
      };
    });

    const tiposPago = computed(() => {
      return {
        ["mensual"]: i18n.t("planes.informacion.tipos-pago.0") as string,
        ["anual"]: i18n.t("planes.informacion.tipos-pago.1") as string,
      };
    });

    const nombre = computed(() => {
      return props.plan.nombre || noRegistrado.value;
    });

    const tipo = computed(() => {
      return props.plan.tipo
        ? tipos.value[props.plan.tipo]
        : noRegistrado.value;
    });

    const precio = computed(() => {
      return props.plan.precio?.toCurrency("$") || noRegistrado.value;
    });

    const tipoPago = computed(() => {
      return props.plan.tipoPago
        ? tiposPago.value[props.plan.tipoPago]
        : noRegistrado.value;
    });

    const descuento = computed(() => {
      return props.plan.descuento || noRegistrado.value;
    });

    const descripcion = computed(() => {
      return props.plan.descripcion || noRegistrado.value;
    });

    const onClickEditar = () => {
      ctx.emit("click:editar", props.plan);
    };

    const onClickEliminar = () => {
      ctx.emit("click:eliminar", props.plan);
    };

    return {
      nombre,
      tipo,
      precio,
      tipoPago,
      descuento,
      descripcion,
      onClickEditar,
      onClickEliminar,
    };
  },
});
