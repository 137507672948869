
//decoradores
import { Component, Emit, Prop, VModel, Vue } from "vue-property-decorator";
//tipos
import { Permisos } from "@/typings/store/modules/permisos";
import { FiltrosDesecho } from "@/typings/components/desechos/filtros";
//componentes

import AddButton from "@/components/custom/AddButton.vue";
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: {
    AddButton,
    SvgIcon,
  },
})
export default class FiltrosDesechos extends Vue {
  @VModel() filtros!: FiltrosDesecho;
  @Prop() readonly buttonDisabled!: boolean;
  @Prop() readonly permisos!: Permisos;

  get mostrarBotonAgregar(): boolean {
    return this.permisos.desechos !== "ver";
  }

  get search(): string {
    return this.filtros.search;
  }

  set search(value: string) {
    this.filtros.search = value;
  }

  @Emit("click:agregar")
  onClickAgregar(): void {
    return;
  }
}
