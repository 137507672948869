
//composition
import {defineComponent} from "vue";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "AddButton",
  components: {
    SvgIcon
  },
  emits: [
    "click"
  ],
  props: {
    icon: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const onClick = () => {
      ctx.emit("click");
    };

    return {
      onClick
    };
  }
});
