
import { computed, defineComponent } from "vue";
// composables
import { useI18n } from "@/composables/i18n";
import { useSuscripciones } from "@/composables/suscripciones";
// helpers
import { log } from "@/helpers/env";

export default defineComponent({
  setup() {
    const i18n = useI18n();
    const suscripcionesBD = useSuscripciones();

    const ultimaSuscripcion = computed(() => {
      const arraySubs = suscripcionesBD.getArray.value.filter(
        (sub) => sub.valido
      );
      arraySubs.sort((a, b) => {
        const dateA = new Date(a.fechaInicio);
        const dateB = new Date(b.fechaInicio);
        return dateB.getTime() - dateA.getTime();
      });
      if (arraySubs.length <= 0) return null;
      const ultimaSub = arraySubs[0] ?? null;
      if (log) {
        console.log("ultimaSub", ultimaSub);
      }
      return ultimaSub;
    });

    const model = computed({
      get() {
        return ultimaSuscripcion.value?.snackbar?.model ?? false;
      },
      set(value) {
        if (log) {
          console.log("set model", value);
        }
      },
    });

    const color = computed(() => {
      return ultimaSuscripcion.value?.snackbar?.color ?? "warning";
    });

    const texto = computed(() => {
      return (
        ultimaSuscripcion.value?.snackbar?.mensaje ??
        (i18n.t("app.snackbar-pending-payment.text") as string)
      );
    });

    return {
      model,
      color,
      texto,
    };
  },
});
