
// vue
import { defineComponent, computed, ref, reactive, watch } from "vue";
// composables
import { useI18n } from "@/composables/i18n";
// validaciones
import useVuelidate from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";

export default defineComponent({
  props: {
    errorSignIn: {
      type: String,
      default: null,
    },
  },
  emits: ["recuperar-password", "click-btn"],
  setup(props, ctx) {
    const { t } = useI18n();

    watch(
      () => props.errorSignIn,
      (value) => {
        if (value) {
          cargando.value = false;
        }
      }
    );

    const password = ref("");
    const correo = ref("");

    const verPassword = ref(false);

    const cargando = ref(false);

    const state = reactive({
      correo,
      password,
    });

    const rules = {
      correo: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(6),
      },
    };

    const v$ = useVuelidate(rules, state);

    const errorCorreo = computed((): string[] => {
      if (!v$.value.correo.$dirty) return [];
      const error: Array<string> = [];
      v$.value.correo.required ||
        error.push(t("login.form.email.error.required") as string);
      v$.value.correo.email ||
        error.push(t("login.form.email.error.invalid") as string);
      return error;
    });

    const errorPassword = computed((): string[] => {
      if (!v$.value.password.$dirty) return [];
      const error: Array<string> = [];
      v$.value.password.required ||
        error.push(t("login.form.password.error.required") as string);
      v$.value.password.minLength ||
        error.push(t("login.form.password.error.min-length") as string);
      return error;
    });

    const onRecuperarPassword = (): void => {
      ctx.emit("recuperar-password", correo.value);
    };

    const onClickBtn = (data: { email: string; password: string }): void => {
      ctx.emit("click-btn", data);
    };

    const login = (): void => {
      v$.value.$touch();
      if (v$.value.$invalid) {
        return;
      }
      cargando.value = true;
      onClickBtn({ email: correo.value, password: password.value });
    };

    return {
      t,
      v$,
      password,
      correo,
      verPassword,
      cargando,
      errorCorreo,
      errorPassword,
      onRecuperarPassword,
      onClickBtn,
      login,
    };
  },
});
