
import { defineComponent, computed } from "vue";

export default defineComponent({
  props: {
    value: Boolean,
  },
  emits: ["input"],
  setup(props, ctx) {
    const vModel = computed({
      get() {
        return props.value;
      },
      set(val) {
        ctx.emit("input", val);
      },
    });

    const onClickUpdate = async (): Promise<void> => {
      const registrations = await navigator.serviceWorker.getRegistrations();
      for (const registration of registrations) {
        await registration.unregister();
      }
      window.location.reload();
    };
    return {
      vModel,
      onClickUpdate,
    };
  },
});
