
// vue
import { defineComponent, computed, ref, reactive, watch } from "vue";
// composables
import { useI18n } from "@/composables/i18n";
// validaciones
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

export default defineComponent({
  props: {
    errorSignIn: {
      type: String,
      default: null,
    },
  },
  emits: ["click-recuperar"],
  setup(props, ctx) {
    const { t } = useI18n();

    watch(
      () => props.errorSignIn,
      (value) => {
        if (value) {
          cargando.value = false;
        }
      }
    );

    const correo = ref("");

    const cargando = ref(false);

    const state = reactive({
      correo,
    });

    const rules = {
      correo: {
        required,
        email,
      },
    };

    const v$ = useVuelidate(rules, state);

    const errorCorreo = computed((): string[] => {
      const error: Array<string> = [];
      if (!v$.value.correo.$dirty) return error;
      v$.value.correo.required ||
        error.push(
          t("login.form-recovery-password.email.error.required") as string
        );
      v$.value.correo.email ||
        error.push(
          t("login.form-recovery-password.email.error.invalid") as string
        );
      return error;
    });

    const onClickRecuperar = (data: { email: string }): void => {
      ctx.emit("click-recuperar", data);
    };

    const recuperar = (): void => {
      v$.value.$touch();
      if (v$.value.$invalid) {
        return;
      }
      cargando.value = true;
      onClickRecuperar({ email: correo.value });
    };

    return {
      t,
      v$,
      correo,
      errorCorreo,
      cargando,
      onClickRecuperar,
      recuperar,
    };
  },
});
