
//decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
//tipos
import { Chofer } from "@/typings/store/plugins/easyFirestore/choferes";
import { Permisos } from "@/typings/store/modules/permisos";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import SubirImagen from "@/components/SubirImagen.vue";

@Component({
  components: {
    SvgIcon,
    SubirImagen,
  },
})
export default class DetalleChofer extends Vue {
  @Prop() readonly chofer!: Chofer;
  @Prop() readonly permisos!: Permisos;

  get mostrarBotonEditar(): boolean {
    return this.permisos.transporte !== "ver";
  }

  get mostrarBotonEliminar(): boolean {
    return this.permisos.transporte === "eliminar";
  }

  get noValueMessage(): string {
    return this.$t("transporte.tab.choferes.no-value-message") as string;
  }

  get nombre(): string {
    return this.chofer.nombre;
  }

  get rut(): string {
    return this.chofer.rut ?? this.noValueMessage;
  }

  get cargo(): string {
    return this.chofer.cargo ?? this.noValueMessage;
  }

  get nacimiento(): string {
    return (
      this.chofer.fechaNacimiento?.toDate()?.format("DD-MM-YYYY") ??
      this.noValueMessage
    );
  }

  get contratacion(): string {
    return (
      this.chofer.fechaContratacion?.toDate()?.format("DD-MM-YYYY") ??
      this.noValueMessage
    );
  }

  get contacto(): string {
    return this.chofer.contacto ?? this.noValueMessage;
  }

  get estado(): string {
    return this.chofer.estado ?? this.noValueMessage;
  }

  @Emit("click:editar")
  onClickEditar(): void {
    return;
  }

  @Emit("click:eliminar")
  onClickEliminar(): void {
    return;
  }
}
