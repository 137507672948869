
// Vue
import {defineComponent, computed} from "vue";

// Composables
import {useI18n} from "@/composables/i18n";
import {useRutas} from "@/composables/rutas";
import {useRouter} from "@/composables/router";
import {useUsuario} from "@/composables/usuario";
import {usePesajes} from "@/composables/pesajes";
import {useEmpresas} from "@/composables/empresas";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import ListaNotificaciones from "@/components/ListaNotificaciones.vue";

export default defineComponent({
  components: {
    SvgIcon,
    ListaNotificaciones,
  },
  props: {
    miniVariant: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    // Composables

    const i18n = useI18n();
    const rutas = useRutas();
    const router = useRouter();
    const usuario = useUsuario();
    const pesajes = usePesajes();
    const empresas = useEmpresas();

    // Data

    const rutasNotificaciones = ["bascula", "reportes", "pesajes"];

    // Computed

    const mostrarNotificaciones = computed((): boolean => {
      return rutasNotificaciones.includes(router.routeName.value);
    });

    const nombreEmpresa = computed((): string => {
      const usuarioTemp = usuario.get.value;
      if (!usuarioTemp) return "";
      return empresas.getById(usuarioTemp.empresa)?.nombre ?? "";
    });

    const nombreUsuario = computed((): string => {
      const usuarioTemp = usuario.get.value;
      if (!usuarioTemp) return "";
      return `${usuarioTemp.nombres} ${usuarioTemp.apellidos}` ?? "";
    });

    const nombreRuta = computed((): string => {
      const name = router.routeName.value;
      const ruta = rutas.getArray.value.find((_ruta) => _ruta.id === name);
      return ruta?.text ?? "";
    });

    const nombreCargo = computed((): string => {
      const usuarioTemp = usuario.get.value;
      if (!usuarioTemp) return "";
      return usuarioTemp.cargo ?? (i18n.t("app-bar.no-position-message") as string);
    });

    const appBarClass = computed(() => ({
      'app-bar__container': true,
      'app-bar__mini': props.miniVariant,
      'app-bar__normal': !props.miniVariant
    }));

    return {
      // Composables
      pesajes,
      // Computed
      nombreRuta,
      nombreCargo,
      appBarClass,
      nombreEmpresa,
      nombreUsuario,
      mostrarNotificaciones,
    };
  },
});
