
//decoradores
import {Component, Emit, Prop, VModel, Vue} from "vue-property-decorator";

//tipos
import {Permisos} from "@/typings/store/modules/permisos";
import {Barredor} from "@/typings/store/plugins/easyFirestore/barredores";
import {Rastreador} from "@/typings/store/plugins/easyFirestore/rastreadores";
import {RutaBarredor, TiempoPings} from "@/typings/store/plugins/easyFirestore/rutasBarredor";

//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import ChipTiempo from "@/components/barredores/listaBarredores/ChipTiempo.vue";
import HeaderListaBarredores from "@/components/barredores/listaBarredores/HeaderListaBarredores.vue";

interface ItemLista {
  id: string;
  color: string;
  numero: number;
  nombre: string;
  conectado: boolean;
  barredor: Barredor;
  mostrarRuta: boolean;
  ruta: RutaBarredor | null;
}

@Component({
  components: {
    SvgIcon,
    ChipTiempo,
    HeaderListaBarredores,
  },
})
export default class ListaBarredores extends Vue {
  @VModel() seleccionados!: Barredor[];

  @Prop() readonly fecha!: string;
  @Prop() readonly permisos!: Permisos;
  @Prop() readonly tiempoPings!: TiempoPings;
  @Prop() readonly seleccionMultiple!: boolean;
  @Prop() readonly barredores!: Array<Barredor>;
  @Prop() readonly rastreadores!: Array<Rastreador>;

  get historico(): boolean {
    const hoy = new Date();
    hoy.setHours(0, 0, 0, 0);
    const fecha = this.fecha.toDate();
    return fecha.getTime() < hoy.getTime();
  }

  get mostrarBotonAgregar(): boolean {
    return this.permisos.barredores !== "ver";
  }

  get mostrarBotonEditar(): boolean {
    return this.permisos.barredores !== "ver";
  }

  get mostrarBotonEliminar(): boolean {
    return this.permisos.barredores === "eliminar";
  }

  get items(): Array<ItemLista> {
    const ordenados = this.barredores.slice();
    ordenados.sort((a, b) => a.numero - b.numero);
    return ordenados.map((barredor) => {
      const rastreador = this.rastreadores.find((r) => r.id === barredor.rastreador?.id,);
      const hoy = new Date();
      const diferenciaMs = hoy.getTime() - (rastreador?.conectado?.toDate()?.getTime() ?? 0);
      const diferenciaMin = Math.round(diferenciaMs / 60000);
      return {
        barredor,
        ruta: barredor.ruta,
        id: barredor.id ?? "",
        color: barredor.color,
        numero: barredor.numero,
        nombre: barredor.nombre,
        conectado: diferenciaMin < 3,
        mostrarRuta: barredor.mostrarRuta,
      };
    });
  }

  get seleccionMultipleModel(): boolean {
    return this.seleccionMultiple;
  }

  set seleccionMultipleModel(value: boolean) {
    this.onInputSeleccionMultiple(value);
  }

  @Emit("input:seleccion-multiple")
  onInputSeleccionMultiple(val: boolean): boolean {
    return val;
  }

  @Emit("click:agregar")
  onClickAgregar(): void {
    return;
  }

  @Emit("click:nueva-ruta")
  onClickNuevaRuta(): void {
    return;
  }

  @Emit("click:editar")
  onClickEditar(barredor: Barredor): Barredor {
    return barredor;
  }

  @Emit("click:eliminar")
  onClickEliminar(barredor: Barredor): Barredor {
    return barredor;
  }

  @Emit("click:mostrar-ruta")
  onClickMostrarRuta(barredor: Barredor): Barredor {
    return barredor;
  }

  @Emit("click:seleccionar")
  onClickSeleccionar(barredor: Barredor): Barredor {
    return barredor;
  }

  seleccionar(item: ItemLista): void {
    const barredor = item.barredor;
    this.onClickSeleccionar(barredor);
  }

  getTextEstado(item: ItemLista): string {
    const index = item.conectado ? 0 : 1;
    return this.$t(`barredores.tab.barredores.list.boton-estado.tooltip.${index}`,) as string;
  }

  getTextRuta(item: ItemLista): string {
    const index = item.mostrarRuta ? 0 : 1;
    return this.$t(`barredores.tab.barredores.list.boton-ruta.tooltip.${index}`,) as string;
  }

  estaSeleccionado(item: ItemLista): boolean {
    return this.seleccionados.some(seleccionado => seleccionado.id === item.barredor.id);
  }

  getIcon(item: ItemLista): string {
    const seleccionado = this.estaSeleccionado(item);
    if (this.seleccionMultipleModel) return seleccionado ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline";
    return seleccionado ? "mdi-checkbox-marked-circle" : "mdi-checkbox-blank-circle-outline";
  }
}
