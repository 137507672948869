
// Vue
import {defineComponent, ref} from "vue";

// Tipos
import {Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";
import {Empresa} from "@/typings/store/plugins/easyFirestore/empresas";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import ConfirmLogout from "@/components/appCore/ConfirmLogout.vue";
import UserInformation from "@/components/appCore/UserInformation.vue";

export default defineComponent({
  name: "AppBarMobile",
  components: {
    SvgIcon,
    ConfirmLogout,
    UserInformation,
  },
  props: {
    usuario: {
      required: true,
      type: Object as () => Usuario,
    },
    empresa: {
      required: true,
      type: Object as () => Empresa,
    },
  },
  emits: [
    "click:logout",
  ],
  setup(_props, ctx) {
    // Data

    const dialogoConfirmacion = ref(false);

    // Emits

    const onClickLogout = () => {
      ctx.emit("click:logout");
    };

    return {
      // Data
      dialogoConfirmacion,
      // Emits
      onClickLogout,
    };
  },
});
