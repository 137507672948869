
// composition-api
import { defineComponent, computed, PropType, ref, watchEffect } from "vue";
//tipos
import { Pesaje } from "@/typings/store/plugins/easyFirestore/pesajes";
import { Cliente } from "@/typings/store/plugins/easyFirestore/clientes";
import { Desecho } from "@/typings/store/plugins/easyFirestore/desechos";
import { Usuarios } from "@/typings/store/plugins/easyFirestore/usuarios";
import { Servicio } from "@/typings/store/plugins/easyFirestore/servicios";
import { Vehiculo } from "@/typings/store/plugins/easyFirestore/vehiculos";
import { RutaPesaje } from "@/typings/store/plugins/easyFirestore/rutasPesaje";
// composables
import { useKgM3 } from "@/composables/kgM3";
//componentes
import EntradaSalida from "@/components/reportes/EntradaSalida.vue";
import MenuInfoCliente from "@/components/clientes/MenuInfo.vue";
import MenuInfoDesecho from "@/components/desechos/MenuInfo.vue";
import MenuInfoVehiculo from "@/components/transporte/vehiculos/MenuInfo.vue";
import MenuInfoServicio from "@/components/servicios/MenuInfo.vue";
import MenuInfoRutaPesaje from "@/components/transporte/rutas/MenuInfo.vue";

export default defineComponent({
  components: {
    EntradaSalida,
    MenuInfoCliente,
    MenuInfoDesecho,
    MenuInfoServicio,
    MenuInfoVehiculo,
    MenuInfoRutaPesaje,
  },
  props: {
    pesaje: {
      type: Object as PropType<Pesaje | null>,
      required: true,
    },
    mostrarGaleria: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    usuarios: {
      type: Object as PropType<Usuarios>,
      required: true,
    },
  },
  emits: ["click:ver-imagenes", "click:imprimir"],
  setup(props, ctx) {
    const _kgM3 = useKgM3();

    const volumen = ref(0);

    const ruta = computed((): RutaPesaje | null => {
      return props.pesaje?.ruta ?? null;
    });

    const vehiculo = computed((): Vehiculo | null => {
      return props.pesaje?.vehiculo ?? null;
    });

    const cliente = computed((): Cliente | null => {
      return props.pesaje?.cliente ?? null;
    });

    const desecho = computed((): Desecho | null => {
      return props.pesaje?.desecho ?? null;
    });

    const servicio = computed((): Servicio | null => {
      return props.pesaje?.servicio ?? null;
    });

    const fechaCreacion = computed((): string => {
      return (
        props.pesaje?.created_at?.toDate()?.format("DD-MM-YYYY, HH:mm") ?? ""
      );
    });

    const creadoPor = computed((): string => {
      const id = props.pesaje?.created_by;
      if (!id) return "";
      const usuario = props.usuarios[id];
      if (!usuario) return "";
      return usuario.nombres + " " + usuario.apellidos;
    });

    const fechaEdicion = computed((): string => {
      return (
        props.pesaje?.updated_at?.toDate()?.format("DD-MM-YYYY, HH:mm") ?? ""
      );
    });

    const editadoPor = computed((): string => {
      const id = props.pesaje?.updated_by;
      if (!id) return "";
      const usuario = props.usuarios[id];
      if (!usuario) return "";
      return usuario.nombres + " " + usuario.apellidos;
    });

    const onClickVerImagenes = (): void => {
      ctx.emit("click:ver-imagenes");
    };

    const onClickImprimir = (): void => {
      ctx.emit("click:imprimir");
    };

    const cargarVolumen = async (): Promise<void> => {
      if (!props.pesaje) return;
      const _cliente = props.pesaje.cliente;
      const fecha = props.pesaje.entrada?.fecha?.toDate() || new Date();
      const kgM3 = _cliente
        ? await _kgM3.getKgM3By({ cliente: _cliente, fecha })
        : 0;
      const tipoServicio = props.pesaje.servicio?.tipo;
      const peso = props.pesaje.entrada.peso - props.pesaje.salida.peso;
      const pesoNeto = tipoServicio === "Ingreso" ? peso : peso * -1;
      volumen.value = _kgM3.getM3(
        pesoNeto,
        props.pesaje.capacidad,
        props.pesaje.cobro,
        kgM3
      );
    };

    watchEffect(async () => {
      if (props.pesaje) {
        await cargarVolumen();
      }
    });

    return {
      volumen,
      ruta,
      vehiculo,
      cliente,
      desecho,
      servicio,
      fechaCreacion,
      creadoPor,
      fechaEdicion,
      editadoPor,
      onClickVerImagenes,
      onClickImprimir,
      cargarVolumen,
    };
  },
});
