
//composition
import {ref, watch, defineComponent, computed} from "vue";
//helpers
import "@/helpers/extensions";
//tipos
import {GmapMap} from "vue2-google-maps";
import {Barredor} from "@/typings/store/plugins/easyFirestore/barredores";
import {
  PingRastreador,
  Posicion,
} from "@/typings/store/plugins/easyFirestore/pingsRastreador";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "MapaMonitoreo",
  components: {
    SvgIcon,
  },
  props: {
    value: {
      type: Object as () => PingRastreador | null,
    },
    dataMonitoreo: {
      type: Object as () => {
        tipo: "sin-movimiento" | "ruta";
        barredor: Barredor | null;
      },
      required: true,
    },
    pingsRastreador: {
      type: Array as () => Array<PingRastreador>,
      required: true,
    },
    fecha: {
      type: String,
      required: true,
    },
    center: {
      type: Object as () => Posicion,
      required: true,
    },
  },
  emits: ["input"],
  setup(props, ctx) {
    const zoom = ref(14);
    const step = ref(0);
    const reproduciendo = ref(false);
    const intervalId = ref<number | null>(null);
    const map = ref<GmapMap | null>(null);

    const nombreBarredor = computed(() => props.dataMonitoreo.barredor?.nombre || "" );
    const barredor = computed<Barredor | null>(() =>
      JSON.parse(JSON.stringify(props.dataMonitoreo.barredor)),
    );
    const polylineColor = computed(
      () => props.dataMonitoreo.barredor?.color || "#FF0000",
    );
    const polyline = computed(() =>
      pingsFiltrados.value.map((ping) => ping.posicion).slice(0, step.value),
    );
    const tipo = computed(() => props.dataMonitoreo.tipo);

    const pingActual = computed({
      get() {
        return props.value;
      },
      set(val) {
        ctx.emit("input", val);
      },
    });

    const centerLocal = computed({
      get() {
        return props.center;
      },
      set(val) {
        ctx.emit("input", val);
      },
    });

    const pingsFiltrados = computed(() => {
      const pings = props.pingsRastreador.filter((ping) => {
        const filtroBarredor = ping.barredores.some(
          (b) => b.id === barredor.value?.id,
        );
        const horarioBarredor = barredor.value?.horario;
        const inicio = props.fecha.toDate();
        inicio.setHours(0, 0, 0, 0);
        const fin = props.fecha.toDate();
        fin.setHours(23, 59, 59, 999);
        if (horarioBarredor) {
          const [horarioInicio, horarioFin] = horarioBarredor.split("-");
          const [horaInicio, minutoInicio] = horarioInicio
          .split(":")
          .map(Number);
          const [horaFin, minutoFin] = horarioFin.split(":").map(Number);
          inicio.setHours(horaInicio, minutoInicio, 0, 0);
          fin.setHours(horaFin, minutoFin, 59, 999);
        }
        const fecha = ping.fecha.toDate();
        const filtroFecha = inicio <= fecha && fecha <= fin;
        return filtroBarredor && filtroFecha;
      });
      pings.sort(
        (a, b) => a.fecha.toDate().getTime() - b.fecha.toDate().getTime(),
      );
      return pings;
    });

    const sinMovimiento = computed(() => {
      const result: Array<Posicion> = [];
      if (tipo.value === "sin-movimiento") {
        let index = 0;
        for (const ping of pingsFiltrados.value) {
          const filtroVelocidad = ping.velocidad < 1;
          const filtroSiguiente = !!pingsFiltrados.value[index + 1];
          if (filtroVelocidad && filtroSiguiente) {
            result.push(ping.posicion);
          }
          index++;
        }
      }
      return result;
    });

    const ultima = polyline.value[polyline.value.length - 1];
    if (ultima) {
      centerLocal.value = ultima;
    }

    watch(
      () => barredor.value,
      (newValue: Barredor | null, oldValue: Barredor | null) => {
        if (newValue?.id !== oldValue?.id) {
          pingActual.value = null;
          step.value = 0;
          if (intervalId.value) {
            reproduciendo.value = false;
            clearInterval(intervalId.value);
            intervalId.value = null;
          }
        }
      },
    );

    watch(
      () => tipo.value,
      (value: "sin-movimiento" | "ruta") => {
        if (value === "ruta") {
          step.value = 0;
        } else {
          step.value = pingsFiltrados.value.length - 1;
          if (intervalId.value) {
            reproduciendo.value = false;
            clearInterval(intervalId.value);
            intervalId.value = null;
          }
        }
      },
      {immediate: true},
    );

    watch(
      () => step.value,
      (val: number) => {
        pingActual.value = pingsFiltrados.value[val];
      },
    );

    const boundsChangedHandler = () => {
      zoom.value = map.value?.$mapObject?.getZoom() || zoom.value;
    };

    const irInicio = () => {
      step.value = 0;
      if (intervalId.value) {
        reproduciendo.value = false;
        clearInterval(intervalId.value);
        intervalId.value = null;
      }
    };

    const irAnterior = () => {
      if (step.value > 0) {
        step.value--;
      }
      if (intervalId.value) {
        reproduciendo.value = false;
        clearInterval(intervalId.value);
        intervalId.value = null;
      }
    };

    const playPause = () => {
      if (intervalId.value) {
        reproduciendo.value = false;
        clearInterval(intervalId.value);
        intervalId.value = null;
      } else {
        reproduciendo.value = true;
        intervalId.value = window.setInterval(() => {
          if (step.value < pingsFiltrados.value.length - 1) {
            step.value++;
          } else {
            playPause();
          }
        }, 1000);
      }
    };

    const irSiguiente = () => {
      if (step.value < pingsFiltrados.value.length - 1) {
        step.value++;
      }
      if (intervalId.value) {
        reproduciendo.value = false;
        clearInterval(intervalId.value);
        intervalId.value = null;
      }
    };

    const irFinal = () => {
      step.value = pingsFiltrados.value.length - 1;
      if (intervalId.value) {
        reproduciendo.value = false;
        clearInterval(intervalId.value);
        intervalId.value = null;
      }
    };

    return {
      zoom,
      centerLocal,
      map,
      tipo,
      nombreBarredor,
      polyline,
      polylineColor,
      sinMovimiento,
      reproduciendo,
      boundsChangedHandler,
      irInicio,
      irAnterior,
      playPause,
      irSiguiente,
      irFinal,
    };
  },
});
