
//decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
//tipos
import { Vehiculo } from "@/typings/store/plugins/easyFirestore/vehiculos";
import { Permisos } from "@/typings/store/modules/permisos";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import SubirImagen from "@/components/SubirImagen.vue";

@Component({
  components: {
    SvgIcon,
    SubirImagen,
  },
})
export default class DetalleVehiculo extends Vue {
  @Prop() readonly vehiculo!: Vehiculo;
  @Prop() readonly permisos!: Permisos;

  get mostrarBotonEditar(): boolean {
    return this.permisos.transporte !== "ver";
  }

  get mostrarBotonEliminar(): boolean {
    return this.permisos.transporte === "eliminar";
  }

  get noValueMessage(): string {
    return this.$t("transporte.tab.flota.no-value-message") as string;
  }

  get patente(): string {
    return this.vehiculo.patente || this.noValueMessage;
  }

  get tipo(): string {
    return this.vehiculo.tipo || this.noValueMessage;
  }

  get marca(): string {
    return this.vehiculo.marca || this.noValueMessage;
  }

  get modelo(): string {
    return this.vehiculo.modelo || this.noValueMessage;
  }

  get año(): string | number {
    return this.vehiculo.año || this.noValueMessage;
  }

  get combustible(): string {
    return this.vehiculo.combustible || this.noValueMessage;
  }

  get numeroChasis(): string {
    return this.vehiculo.numeroChasis || this.noValueMessage;
  }

  get numeroMotor(): string {
    return this.vehiculo.numeroMotor || this.noValueMessage;
  }

  @Emit("click:editar")
  onClickEditar(): void {
    return;
  }

  @Emit("click:eliminar")
  onClickEliminar(): void {
    return;
  }
}
