
// composition-api
import {computed, defineComponent, reactive, ref} from "vue";

// helpers
import routeGuard from "@/helpers/routeGuard";

//tipos
import {Filtros} from "@/typings/components/monitoreo/filtros";
import {Pesaje} from "@/typings/store/plugins/easyFirestore/pesajes";

// composables
import {usePesajes} from "@/composables/pesajes";
import {useUsuario} from "@/composables/usuario";
import {useClientes} from "@/composables/clientes";
import {useServicios} from "@/composables/servicios";

// componentes
import TabsMonitoreo from "@/components/monitoreo/TabsMonitoreo.vue";
import FiltrosMonitoreo from "@/components/monitoreo/Filtros.vue";

export default defineComponent({
  name: "VistaMonitoreo",
  components: {
    TabsMonitoreo,
    FiltrosMonitoreo,
  },
  beforeRouteEnter(_to, _from, next) {
    next(routeGuard);
  },
  created(): void {
    if (!routeGuard()) {
      return;
    }
  },
  setup() {
    const _pesajes = usePesajes();
    const _usuario = useUsuario();
    const _clientes = useClientes({ordenarArray: true});
    const _servicios = useServicios({ordenarArray: true});

    const tab = ref(0);

    const filtros = reactive<Filtros>({
      fechas: [],
      clientes: [],
      servicios: [],
      periodo: 1,
      unidadDesechos: "kg",
    });

    const arrayClientes = computed(() => {
      return _clientes.getArrayHabilitados.value;
    });

    const arrayServicios = computed(() => {
      return _servicios.getArray.value;
    });

    const pesajesFiltrados = computed((): Array<Pesaje> => {
      return _pesajes.getArray.value.filter((pesaje) => {
        const filtroClientes = filtros.clientes;
        const filtroServicios = filtros.servicios;
        const [fechaInicio, fechaFin] = filtros.fechas;
        const fechaPesaje = pesaje.entrada.fecha.toDate();
        const filtroCliente = filtroClientes.length > 0 ? filtroClientes.some((cliente) => cliente.id === pesaje.cliente?.id,) : true;
        const filtroServicio = filtroServicios.length > 0 ? filtroServicios.some((servicio) => servicio.id === pesaje.servicio?.id,) : true;
        const filtroFecha = fechaInicio && fechaFin ? fechaPesaje.getTime() >= fechaInicio.getTime() && fechaPesaje.getTime() <= fechaFin.getTime() : true;
        const filtroEstado = pesaje.estado !== "anulado";
        return filtroCliente && filtroServicio && filtroFecha && filtroEstado;
      });
    });

    const fetchPesajes = (fechas: Array<Date>): void => {
      _pesajes.fetchAndAdd({
        pathVariables: {
          idEmpresa: _usuario.getEmpresa.value.id ?? "",
        },
        clauses: {
          where: [
            ["entrada.fecha", ">=", fechas[0].toISOString()],
            ["entrada.fecha", "<=", fechas[1].toISOString()],
          ],
        },
      });
    };

    return {
      filtros,
      arrayClientes,
      arrayServicios,
      tab,
      pesajesFiltrados,
      fetchPesajes,
    };
  },
});
