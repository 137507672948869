
// decoradores
import { Component, Vue, Prop, Model, Emit } from "vue-property-decorator";
// tipos
import {
  TipoUsuario,
  Usuario,
  UsuarioPatch,
} from "@/typings/store/plugins/easyFirestore/usuarios";
import {
  Permisos,
  PermisosTipoUsuario,
  SubPermisos,
} from "@/typings/store/modules/permisos";
//componentes
import FormSelect from "@/components/custom/FormSelect.vue";

@Component({
  components: {
    FormSelect,
  },
})
export default class PermisosUsuario extends Vue {
  @Model("input") readonly value!: UsuarioPatch | null;

  @Prop() readonly tipoUsuarioPermisos!: PermisosTipoUsuario;
  @Prop() readonly seleccionado!: Usuario | null;

  created(): void {
    if (!this.seleccionado) {
      return;
    }
    if (!this.seleccionado.tipo) {
      this.tipo = "cliente";
    }
  }

  get itemsTiposUsuario(): Array<{ text: string; value: TipoUsuario }> {
    return [
      {
        text: this.$t("users.permissions.item.tipos-usuario.0") as string,
        value: "administrador",
      },
      {
        text: this.$t("users.permissions.item.tipos-usuario.1") as string,
        value: "supervisor",
      },
      {
        text: this.$t("users.permissions.item.tipos-usuario.2") as string,
        value: "bascula",
      },
      {
        text: this.$t("users.permissions.item.tipos-usuario.3") as string,
        value: "cliente",
      },
    ];
  }

  get itemsPermisos(): Array<{ texto: string; valor: SubPermisos | null }> {
    return [
      {
        texto: this.$t(
          "users.permissions.item.permissions-items.null"
        ) as string,
        valor: null,
      },
      {
        texto: this.$t(
          "users.permissions.item.permissions-items.ver"
        ) as string,
        valor: "ver",
      },
      {
        texto: this.$t(
          "users.permissions.item.permissions-items.editar"
        ) as string,
        valor: "editar",
      },
      {
        texto: this.$t(
          "users.permissions.item.permissions-items.eliminar"
        ) as string,
        valor: "eliminar",
      },
    ];
  }

  get permisosDefault(): Permisos {
    return this.tipoUsuarioPermisos[this.tipo] ?? {};
  }

  get verPermisos(): { [key: string]: boolean } {
    const result: { [key: string]: boolean } = {};
    for (const key in this.permisosDefault) {
      result[key] = true;
    }
    return result;
  }

  // get y set home
  get permisosHome(): SubPermisos | null | undefined {
    if (this.value?.permisos?.home === undefined) {
      if (this.seleccionado?.permisos?.home === undefined) {
        return this.permisosDefault.home ?? null;
      } else {
        return this.seleccionado?.permisos?.home ?? null;
      }
    } else {
      return this.value?.permisos?.home ?? null;
    }
  }

  set permisosHome(value: SubPermisos | null | undefined) {
    const valores = Object.assign({}, this.value);
    const usuario: UsuarioPatch = valores ?? {
      id: this.seleccionado?.id ?? "",
    };
    if (!usuario.permisos) {
      usuario.permisos = {};
    }
    if (usuario.permisos.home !== value) {
      usuario.permisos.home = value ?? null;
      this.onInput(usuario);
    }
  }

  // get y set bascula
  get permisosBascula(): SubPermisos | null | undefined {
    if (this.value?.permisos?.bascula === undefined) {
      if (this.seleccionado?.permisos?.bascula === undefined) {
        return this.permisosDefault.bascula ?? null;
      } else {
        return this.seleccionado?.permisos?.bascula ?? null;
      }
    } else {
      return this.value?.permisos?.bascula ?? null;
    }
  }

  set permisosBascula(value: SubPermisos | null | undefined) {
    const valores = Object.assign({}, this.value);
    const usuario: UsuarioPatch = valores ?? {
      id: this.seleccionado?.id ?? "",
    };
    if (!usuario.permisos) {
      usuario.permisos = {};
    }
    if (usuario.permisos.bascula !== value) {
      usuario.permisos.bascula = value ?? null;
      this.onInput(usuario);
    }
  }

  // get y set monitoreo
  get permisosMonitoreo(): SubPermisos | null | undefined {
    if (this.value?.permisos?.monitoreo === undefined) {
      if (this.seleccionado?.permisos?.monitoreo === undefined) {
        return this.permisosDefault.monitoreo ?? null;
      } else {
        return this.seleccionado?.permisos?.monitoreo ?? null;
      }
    } else {
      return this.value?.permisos?.monitoreo ?? null;
    }
  }

  set permisosMonitoreo(value: SubPermisos | null | undefined) {
    const valores = Object.assign({}, this.value);
    const usuario: UsuarioPatch = valores ?? {
      id: this.seleccionado?.id ?? "",
    };
    if (!usuario.permisos) {
      usuario.permisos = {};
    }
    if (usuario.permisos.monitoreo !== value) {
      usuario.permisos.monitoreo = value ?? null;
      this.onInput(usuario);
    }
  }

  // get y set reportes
  get permisosReportes(): SubPermisos | null | undefined {
    if (this.value?.permisos?.reportes === undefined) {
      if (this.seleccionado?.permisos?.reportes === undefined) {
        return this.permisosDefault.reportes ?? null;
      } else {
        return this.seleccionado?.permisos?.reportes ?? null;
      }
    } else {
      return this.value?.permisos?.reportes ?? null;
    }
  }

  set permisosReportes(value: SubPermisos | null | undefined) {
    const valores = Object.assign({}, this.value);
    const usuario: UsuarioPatch = valores ?? {
      id: this.seleccionado?.id ?? "",
    };
    if (!usuario.permisos) {
      usuario.permisos = {};
    }
    if (usuario.permisos.reportes !== value) {
      usuario.permisos.reportes = value ?? null;
      this.onInput(usuario);
    }
  }

  // get y set pesajes
  get permisosPesajes(): SubPermisos | null | undefined {
    if (this.value?.permisos?.pesajes === undefined) {
      if (this.seleccionado?.permisos?.pesajes === undefined) {
        return this.permisosDefault.pesajes ?? null;
      } else {
        return this.seleccionado?.permisos?.pesajes ?? null;
      }
    } else {
      return this.value?.permisos?.pesajes ?? null;
    }
  }

  set permisosPesajes(value: SubPermisos | null | undefined) {
    const valores = Object.assign({}, this.value);
    const usuario: UsuarioPatch = valores ?? {
      id: this.seleccionado?.id ?? "",
    };
    if (!usuario.permisos) {
      usuario.permisos = {};
    }
    if (usuario.permisos.pesajes !== value) {
      usuario.permisos.pesajes = value ?? null;
      this.onInput(usuario);
    }
  }

  // get y set usuarios
  get permisosUsuarios(): SubPermisos | null | undefined {
    if (this.value?.permisos?.usuarios === undefined) {
      if (this.seleccionado?.permisos?.usuarios === undefined) {
        return this.permisosDefault.usuarios ?? null;
      } else {
        return this.seleccionado?.permisos?.usuarios ?? null;
      }
    } else {
      return this.value?.permisos?.usuarios ?? null;
    }
  }

  set permisosUsuarios(value: SubPermisos | null | undefined) {
    const valores = Object.assign({}, this.value);
    const usuario: UsuarioPatch = valores ?? {
      id: this.seleccionado?.id ?? "",
    };
    if (!usuario.permisos) {
      usuario.permisos = {};
    }
    if (usuario.permisos.usuarios !== value) {
      usuario.permisos.usuarios = value ?? null;
      this.onInput(usuario);
    }
  }

  // get y set clientes
  get permisosClientes(): SubPermisos | null | undefined {
    if (this.value?.permisos?.clientes === undefined) {
      if (this.seleccionado?.permisos?.clientes === undefined) {
        return this.permisosDefault.clientes ?? null;
      } else {
        return this.seleccionado?.permisos?.clientes ?? null;
      }
    } else {
      return this.value?.permisos?.clientes ?? null;
    }
  }

  set permisosClientes(value: SubPermisos | null | undefined) {
    const valores = Object.assign({}, this.value);
    const usuario: UsuarioPatch = valores ?? {
      id: this.seleccionado?.id ?? "",
    };
    if (!usuario.permisos) {
      usuario.permisos = {};
    }
    if (usuario.permisos.clientes !== value) {
      usuario.permisos.clientes = value ?? null;
      this.onInput(usuario);
    }
  }

  // get y set transporte
  get permisosTransporte(): SubPermisos | null | undefined {
    if (this.value?.permisos?.transporte === undefined) {
      if (this.seleccionado?.permisos?.transporte === undefined) {
        return this.permisosDefault.transporte ?? null;
      } else {
        return this.seleccionado?.permisos?.transporte ?? null;
      }
    } else {
      return this.value?.permisos?.transporte ?? null;
    }
  }

  set permisosTransporte(value: SubPermisos | null | undefined) {
    const valores = Object.assign({}, this.value);
    const usuario: UsuarioPatch = valores ?? {
      id: this.seleccionado?.id ?? "",
    };
    if (!usuario.permisos) {
      usuario.permisos = {};
    }
    if (usuario.permisos.transporte !== value) {
      usuario.permisos.transporte = value ?? null;
      this.onInput(usuario);
    }
  }

  // get y set servicios
  get permisosServicios(): SubPermisos | null | undefined {
    if (this.value?.permisos?.servicios === undefined) {
      if (this.seleccionado?.permisos?.servicios === undefined) {
        return this.permisosDefault.servicios ?? null;
      } else {
        return this.seleccionado?.permisos?.servicios ?? null;
      }
    } else {
      return this.value?.permisos?.servicios ?? null;
    }
  }

  set permisosServicios(value: SubPermisos | null | undefined) {
    const valores = Object.assign({}, this.value);
    const usuario: UsuarioPatch = valores ?? {
      id: this.seleccionado?.id ?? "",
    };
    if (!usuario.permisos) {
      usuario.permisos = {};
    }
    if (usuario.permisos.servicios !== value) {
      usuario.permisos.servicios = value ?? null;
      this.onInput(usuario);
    }
  }

  // get y set desechos
  get permisosDesechos(): SubPermisos | null | undefined {
    if (this.value?.permisos?.desechos === undefined) {
      if (this.seleccionado?.permisos?.desechos === undefined) {
        return this.permisosDefault.desechos ?? null;
      } else {
        return this.seleccionado?.permisos?.desechos ?? null;
      }
    } else {
      return this.value?.permisos?.desechos ?? null;
    }
  }

  set permisosDesechos(value: SubPermisos | null | undefined) {
    const valores = Object.assign({}, this.value);
    const usuario: UsuarioPatch = valores ?? {
      id: this.seleccionado?.id ?? "",
    };
    if (!usuario.permisos) {
      usuario.permisos = {};
    }
    if (usuario.permisos.desechos !== value) {
      usuario.permisos.desechos = value ?? null;
      this.onInput(usuario);
    }
  }

  // get y set barredores
  get permisosBarredores(): SubPermisos | null | undefined {
    if (this.value?.permisos?.barredores === undefined) {
      if (this.seleccionado?.permisos?.barredores === undefined) {
        return this.permisosDefault.barredores ?? null;
      } else {
        return this.seleccionado?.permisos?.barredores ?? null;
      }
    } else {
      return this.value?.permisos?.barredores ?? null;
    }
  }

  set permisosBarredores(value: SubPermisos | null | undefined) {
    const valores = Object.assign({}, this.value);
    const usuario: UsuarioPatch = valores ?? {
      id: this.seleccionado?.id ?? "",
    };
    if (!usuario.permisos) {
      usuario.permisos = {};
    }
    if (usuario.permisos.barredores !== value) {
      usuario.permisos.barredores = value ?? null;
      this.onInput(usuario);
    }
  }

  // get y set tipo
  get tipo(): TipoUsuario {
    return this.value?.tipo ?? this.seleccionado?.tipo ?? "cliente";
  }

  set tipo(value: TipoUsuario) {
    const usuarioPatch = Object.assign({}, this.value);
    const usuario: UsuarioPatch = usuarioPatch ?? {
      id: this.seleccionado?.id ?? "",
    };
    if (usuario.tipo !== value) {
      usuario.tipo = value ?? "cliente";
      if (usuario.permisos) {
        for (const key in usuario.permisos) {
          usuario.permisos[key] = null;
        }
      }
      usuario.permisos =
        Object.assign({}, this.tipoUsuarioPermisos[value]) ?? {};
      this.onInput(usuario);
    }
  }

  @Emit("input")
  onInput(value: UsuarioPatch): UsuarioPatch {
    return value;
  }
}
