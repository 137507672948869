
//decoradores
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
//tipos
import { EstadoChofer } from "@/typings/store/plugins/easyFirestore/choferes";
import { FiltrosTransporte } from "@/typings/components/transporte/filtros";
import { EstadoIdentificador } from "@/typings/store/plugins/easyFirestore/identificadores";
import {
  EstadoRutaPesaje,
  TipoRutaPesaje,
} from "@/typings/store/plugins/easyFirestore/rutasPesaje";
import {
  Combustible,
  EstadoVehiculo,
  TipoVehiculo,
} from "@/typings/store/plugins/easyFirestore/vehiculos";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: {
    SvgIcon,
  },
})
export default class FiltrosTransportes extends Vue {
  @VModel() filtros!: FiltrosTransporte;
  @Prop() readonly tab!: number;

  get tiposVehiculos(): Array<{ text: string; value: TipoVehiculo }> {
    return [
      {
        text: this.$t("transporte.filtro.tipo-vehiculo.values[0]") as string,
        value: "auto",
      },
      {
        text: this.$t("transporte.filtro.tipo-vehiculo.values[1]") as string,
        value: "camioneta",
      },
      {
        text: this.$t("transporte.filtro.tipo-vehiculo.values[2]") as string,
        value: "camion",
      },
    ];
  }

  get tiposRutas(): Array<{ text: string; value: TipoRutaPesaje }> {
    return [
      {
        text: this.$t("transporte.filtro.tipo-ruta.values[0]") as string,
        value: "rural",
      },
      {
        text: this.$t("transporte.filtro.tipo-ruta.values[1]") as string,
        value: "urbano",
      },
    ];
  }

  get combustibles(): Array<{ text: string; value: Combustible }> {
    return [
      {
        text: this.$t("transporte.filtro.combustible.values[0]") as string,
        value: "bencina",
      },
      {
        text: this.$t("transporte.filtro.combustible.values[1]") as string,
        value: "diesel",
      },
    ];
  }

  get estados(): Array<{
    text: string;
    value: "habilitado" | "deshabilitado";
  }> {
    return [
      {
        text: this.$t("transporte.filtro.estado.values[0]") as string,
        value: "habilitado",
      },
      {
        text: this.$t("transporte.filtro.estado.values[1]") as string,
        value: "deshabilitado",
      },
    ];
  }

  get search(): string {
    return this.filtros.search;
  }

  set search(value: string) {
    this.filtros.search = value;
  }

  get filtroTipoVehiculo(): TipoVehiculo | null {
    return this.filtros.tipoVehiculo;
  }

  set filtroTipoVehiculo(value: TipoVehiculo | null) {
    this.filtros.tipoVehiculo = value;
  }

  get filtroTipoRutaPesaje(): TipoRutaPesaje | null {
    return this.filtros.tipoRuta;
  }

  set filtroTipoRutaPesaje(value: TipoRutaPesaje | null) {
    this.filtros.tipoRuta = value;
  }

  get filtroCombustible(): Combustible | null {
    return this.filtros.combustible;
  }

  set filtroCombustible(value: Combustible | null) {
    this.filtros.combustible = value;
  }

  get filtroEstado():
    | EstadoVehiculo
    | EstadoRutaPesaje
    | EstadoChofer
    | EstadoIdentificador
    | null {
    return this.filtros.estado;
  }

  set filtroEstado(
    value:
      | EstadoVehiculo
      | EstadoRutaPesaje
      | EstadoChofer
      | EstadoIdentificador
      | null
  ) {
    this.filtros.estado = value;
  }
}
