
// decoradores
import { Vue, Component, Model, Prop } from "vue-property-decorator";

@Component
export default class DialogoGenerandoReporte extends Vue {
  @Model("input", { type: Boolean }) readonly value!: boolean;

  @Prop() readonly progreso!: number;
  @Prop() readonly texto!: string;

  get dialogo(): boolean {
    return this.value;
  }

  set dialogo(value: boolean) {
    this.$emit("input", value);
  }
}
