
//composition
import {computed, defineComponent} from "vue";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "FormSelect",
  props: {
    value: {
      type: [String, Number, Object]
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    itemValue: {
      type: String,
      default: "value"
    },
    itemText: {
      type: String,
      default: "text"
    },
    textCase: {
      type: String,
      default: "initial"
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: Array as () => Array<string>,
      default: () => []
    }
  },
  components: {
    SvgIcon
  },
  emits: [
    "input",
    "change",
    "focus",
    "blur"
  ],
  setup(props, ctx) {
    const vModel = computed({
      get() {
        return props.value;
      },
      set(value) {
        ctx.emit("input", value);
      }
    });

    const getText = (item: { [key: string]: string } | string): string => {
      if (typeof item === "object") {
        if (item[props.itemText]) {
          return item[props.itemText];
        }
      }
      return JSON.stringify(item);
    };

    const onInput = (value: unknown): void => {
      ctx.emit("input", value);
    };

    const onChange = (value: unknown): void => {
      ctx.emit("change", value);
    };

    const onFocus = (ev: FocusEvent): void => {
      ctx.emit("focus", ev);
    };

    const onBlur = (ev: FocusEvent): void => {
      ctx.emit("blur", ev);
    };

    return {
      vModel,
      getText,
      onInput,
      onChange,
      onFocus,
      onBlur
    };
  }
});
