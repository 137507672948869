
// vue
import { defineComponent, computed, ref, reactive } from "vue";
// composables
import { useI18n } from "@/composables/i18n";
// helpers
import { imageToWebp } from "@/helpers/image";
//tipos
import {
  Chofer,
  EstadoChofer,
} from "@/typings/store/plugins/easyFirestore/choferes";
import { DataFormularioChofer } from "@/typings/components/choferes/formulario";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import SubirImagen from "@/components/SubirImagen.vue";
// validaciones
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default defineComponent({
  components: {
    SvgIcon,
    SubirImagen,
  },
  props: {
    chofer: {
      default: null,
      type: Object as () => Chofer | null,
    },
    cargando: {
      default: false,
      type: Boolean,
    },
  },
  created(): void {
    if (this.chofer) {
      this.id = this.chofer.id ?? "";
      this.nombre = this.chofer.nombre;
      this.rut = this.chofer.rut ?? "";
      this.cargo = this.chofer.cargo ?? "";
      this.fechaNacimiento =
        this.chofer.fechaNacimiento?.toDate()?.format("YYYY-MM-DD") ?? "";
      this.fechaContratacion =
        this.chofer.fechaContratacion?.toDate()?.format("YYYY-MM-DD") ?? "";
      this.contacto = this.chofer.contacto ?? "";
      this.estado = this.chofer.estado ?? "habilitado";
    }
  },
  emits: ["click:guardar"],
  setup(props, ctx) {
    const { t } = useI18n();

    const id = ref("");
    const nombre = ref("");
    const rut = ref("");
    const cargo = ref("");
    const fechaNacimiento = ref("");
    const menuFechaNacimiento = ref(false);
    const fechaContratacion = ref("");
    const menuFechaContratacion = ref(false);
    const contacto = ref("+56");
    const estado = ref<EstadoChofer>("habilitado");
    const file = ref<File | null>(null);

    const titulo = computed((): string => {
      const index = props.chofer ? 0 : 1;
      return t(`transporte.tab.choferes.formulario.title.${index}`) as string;
    });

    const textoBoton = computed((): string => {
      const index = props.chofer ? 0 : 1;
      return t(
        `transporte.tab.choferes.formulario.boton.text.${index}`
      ) as string;
    });

    const foto = computed((): string => {
      return file.value
        ? URL.createObjectURL(file.value)
        : props.chofer?.foto?.url ?? "";
    });

    const fechaContratacionMostrar = computed((): string => {
      return fechaContratacion.value
        ? fechaContratacion.value.toDate().format("DD/MM/YYYY")
        : "";
    });

    const fechaNacimientoMostrar = computed((): string => {
      return fechaNacimiento.value
        ? fechaNacimiento.value.toDate().format("DD/MM/YYYY")
        : "";
    });

    const state = reactive({
      nombre,
      rut,
      cargo,
      fechaNacimiento,
      fechaContratacion,
      contacto,
    });

    const rules = {
      nombre: { required },
      rut: { required },
      cargo: { required },
      fechaNacimiento: { required },
      fechaContratacion: { required },
      contacto: { required },
    };

    const v$ = useVuelidate(rules, state);

    const erroresNombre = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.nombre.$dirty) return errores;
      if (v$.value.nombre.required.$invalid)
        errores.push(
          t(
            "transporte.tab.choferes.formulario.nombre.error-messages[0]"
          ) as string
        );
      if (nombre.value.length > 30)
        errores.push(
          t(
            "transporte.tab.choferes.formulario.nombre.error-messages[1]"
          ) as string
        );
      return errores;
    });

    const erroresRut = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.rut.$dirty) return errores;
      if (v$.value.rut.required.$invalid)
        errores.push(
          t(
            "transporte.tab.choferes.formulario.rut.error-messages[0]"
          ) as string
        );
      if (!rut.value.validateRUT())
        errores.push(
          t(
            "transporte.tab.choferes.formulario.rut.error-messages[1]"
          ) as string
        );
      return errores;
    });

    const erroresCargo = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.cargo.$dirty) return errores;
      if (v$.value.cargo.required.$invalid)
        errores.push(
          t("transporte.tab.choferes.formulario.cargo.error-message") as string
        );
      return errores;
    });

    const erroresFechaNacimiento = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.fechaNacimiento.$dirty) return errores;
      if (v$.value.fechaNacimiento.required.$invalid)
        errores.push(
          t(
            "transporte.tab.choferes.formulario.fecha-nacimiento.error-message"
          ) as string
        );
      return errores;
    });

    const erroresFechaContratacion = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.fechaContratacion.$dirty) return errores;
      if (v$.value.fechaContratacion.required.$invalid)
        errores.push(
          t(
            "transporte.tab.choferes.formulario.fecha-contratacion.error-message"
          ) as string
        );
      return errores;
    });

    const erroresContacto = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.contacto.$dirty) return errores;
      if (v$.value.contacto.required.$invalid)
        errores.push(
          t(
            "transporte.tab.choferes.formulario.telefono.error-message"
          ) as string
        );
      return errores;
    });

    const guardar = async (): Promise<void> => {
      v$.value.$touch();
      if (
        v$.value.$invalid ||
        !rut.value.validateRUT() ||
        nombre.value.length > 30
      )
        return;
      const chofer: Chofer = {
        nombre: nombre.value,
        rut: rut.value,
        cargo: cargo.value,
        fechaNacimiento: fechaNacimiento.value.toDate().toISOString(),
        fechaContratacion: fechaContratacion.value.toDate().toISOString(),
        contacto: contacto.value,
        estado: estado.value,
        foto: null,
      };
      if (id.value) chofer.id = id.value;
      const data: DataFormularioChofer = { chofer };
      if (file.value) {
        const imagenRaw = file.value;
        const fecha = new Date();
        const extencion = imagenRaw.type.split("/")[1];
        let nombre = `${fecha.valueOf()}.${extencion}`;
        let imagen = await imageToWebp(imagenRaw);
        if (imagen) {
          nombre = `${fecha.valueOf()}.webp`;
        } else {
          imagen = imagenRaw;
        }
        const fechaCarpeta = new Date(fecha);
        fechaCarpeta.setHours(0, 0, 0, 0);
        const nombreCarpeta = `${fechaCarpeta.valueOf()}`;
        const ref = `choferes/${nombreCarpeta}/${nombre}`;
        data.archivo = {
          ref: ref,
          file: imagen,
          nombre: nombre,
        };
      }
      ctx.emit("click:guardar", data);
    };

    return {
      t,
      v$,
      id,
      nombre,
      rut,
      cargo,
      fechaNacimiento,
      menuFechaNacimiento,
      fechaContratacion,
      menuFechaContratacion,
      contacto,
      estado,
      file,
      titulo,
      textoBoton,
      foto,
      fechaContratacionMostrar,
      fechaNacimientoMostrar,
      erroresNombre,
      erroresRut,
      erroresCargo,
      erroresFechaNacimiento,
      erroresFechaContratacion,
      erroresContacto,
      guardar,
    };
  },
});
