
//decoradores
import { Component, Vue } from "vue-property-decorator";
//vuex
import { mapActions, mapGetters } from "vuex";
//tipos
import { Mail } from "@/typings/store/plugins/easyFirestore/mail";
//componentes
import QueEsPagina from "@/components/pagina/QueEs.vue";
import AppBarPagina from "@/components/pagina/AppBar.vue";
import BannerPagina from "@/components/pagina/Banner.vue";
import FooterPagina from "@/components/pagina/Footer.vue";
import ContactoPagina from "@/components/pagina/ContactoPagina.vue";
import TestimoniosPagina from "@/components/pagina/Testimonios.vue";
import CaracteristicasPesajePagina from "@/components/pagina/CaracteristicasPesaje.vue";
import CaracteristicasBarredoresPagina from "@/components/pagina/CaracteristicasBarredores.vue";

const routeGuard = (vm: Vue): boolean => {
  const mobile = window.innerWidth < 1264;
  const rutasMovil = vm.getRutasUsuario.filter((_ruta) => _ruta.mobile);
  const primeraRuta =
    mobile && rutasMovil.length > 0 ? rutasMovil[0] : vm.getRutasUsuario[0];
  if (primeraRuta?.to && vm.$route.name !== primeraRuta.to.name) {
    vm.$router.push(primeraRuta.to);
  }
  return false;
};

@Component({
  components: {
    QueEsPagina,
    AppBarPagina,
    FooterPagina,
    BannerPagina,
    ContactoPagina,
    TestimoniosPagina,
    CaracteristicasPesajePagina,
    CaracteristicasBarredoresPagina,
  },
  computed: mapGetters({
    getArrayTestimonios: "testimonios/getArray",
    getRutasUsuario: "usuario/getRutas",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  methods: mapActions({
    setMail: "mail/set",
  }),
})
export default class VistaInicio extends Vue {
  created(): void {
    if (!routeGuard(this)) {
      return;
    }
  }

  enviarContacto(mail: Mail): void {
    this.setMail(mail);
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.mobile;
  }
}
