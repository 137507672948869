
//deoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
//tipos
import { Chofer } from "@/typings/store/plugins/easyFirestore/choferes";
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";
import { Permisos } from "@/typings/store/modules/permisos";
import { DataFormularioChofer } from "@/typings/components/choferes/formulario";
//componentes
import DetalleChofer from "@/components/transporte/choferes/Detalle.vue";
import TablaChoferes from "@/components/transporte/choferes/Tabla.vue";
import FormularioChofer from "@/components/transporte/choferes/FormularioChofer.vue";

@Component({
  components: {
    DetalleChofer,
    TablaChoferes,
    FormularioChofer,
  },
})
export default class TabChoferes extends Vue {
  @Prop() readonly arrayChoferes!: Array<Chofer>;
  @Prop() readonly mostrarFormulario!: boolean;
  @Prop() readonly mostrarDetalle!: boolean;
  @Prop() readonly chofer!: Chofer | null;
  @Prop() readonly search!: string;
  @Prop() readonly cargando!: boolean;
  @Prop() readonly usuario!: Usuario;
  @Prop() readonly permisos!: Permisos;

  @Emit("click:guardar")
  onClickGuardar(data: DataFormularioChofer): DataFormularioChofer {
    return data;
  }

  @Emit("click:informacion")
  onClickInformacion(chofer: Chofer): Chofer {
    return chofer;
  }

  @Emit("click:switch-estado")
  onClickSwitchEstado(chofer: Chofer): Chofer {
    return chofer;
  }

  @Emit("click:editar")
  onClickEditar(): void {
    return;
  }

  @Emit("click:eliminar")
  onClickEliminar(): void {
    return;
  }
}
