
//composition
import { computed, defineComponent, PropType } from "vue";
//i18n
import i18n from "@/plugins/i18n";
//tipos
import { Empresa } from "@/typings/store/plugins/easyFirestore/empresas";
import { Rastreador } from "@/typings/store/plugins/easyFirestore/rastreadores";
//componentes
import InformationText from "@/components/custom/InformationText.vue";
import ActionButton from "@/components/custom/ActionButton.vue";

export default defineComponent({
  name: "InformacionRastreador",
  components: {
    ActionButton,
    InformationText,
  },
  emits: ["click:editar", "click:eliminar"],
  props: {
    rastreador: {
      type: Object as PropType<Rastreador | null>,
      required: true,
    },
    empresas: {
      type: Array as () => Array<Empresa>,
      required: true,
    },
  },
  setup(props, ctx) {
    const noRegistrado = computed(() => {
      return i18n.t("rastreadores.informacion.no-registrado") as string;
    });

    const tipos = computed(() => {
      return {
        ["persona"]: i18n.t("rastreadores.informacion.tipos.0"),
        ["vehiculo"]: i18n.t("rastreadores.informacion.tipos.1"),
      };
    });

    const imei = computed(() => {
      return props.rastreador?.imei || noRegistrado.value;
    });

    const marca = computed(() => {
      return props.rastreador?.marca || noRegistrado.value;
    });

    const modelo = computed(() => {
      return props.rastreador?.modelo || noRegistrado.value;
    });

    const tipo = computed(() => {
      return props.rastreador?.tipo
        ? tipos.value[props.rastreador.tipo]
        : noRegistrado.value;
    });

    const empresa = computed(() => {
      const empresa = props.empresas.find(
        (empresa) => empresa.id === props.rastreador?.empresa
      );
      return empresa?.nombre || noRegistrado.value;
    });

    const onClickEditar = () => {
      ctx.emit("click:editar", props.rastreador);
    };

    const onClickEliminar = () => {
      ctx.emit("click:eliminar", props.rastreador);
    };

    return {
      imei,
      marca,
      modelo,
      tipo,
      empresa,
      onClickEditar,
      onClickEliminar,
    };
  },
});
