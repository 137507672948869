
//decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
//tipos
import { Desecho } from "@/typings/store/plugins/easyFirestore/desechos";
import { ItemsTablaDesechos } from "@/typings/components/desechos/tabla";
//componentes
import ItemTabla from "@/components/desechos/tabla/Item.vue";
import OpcionesTabla from "@/components/desechos/tabla/Opciones.vue";
import DataTableFooter from "@/components/custom/DataTableFooter.vue";

@Component({
  components: {
    ItemTabla,
    OpcionesTabla,
    DataTableFooter,
  },
})
export default class TablaDesechos extends Vue {
  @Prop() readonly desecho?: Desecho;
  @Prop() readonly desechos!: Array<Desecho>;
  @Prop() readonly search!: string;

  get headers(): Array<{ text: string; value: string; sortable?: boolean }> {
    return [
      {
        text: this.$t("desechos.tabla.headers[0]") as string,
        value: "nombre",
      },
      {
        text: "",
        value: "informacion",
        sortable: false,
      },
    ];
  }

  get noValueMessage(): string {
    return this.$t("desechos.tabla.no-value-message") as string;
  }

  get items(): ItemsTablaDesechos {
    return this.desechos.map((desecho) => {
      const id = desecho.id ?? "";
      const nombre = desecho.nombre;
      const descripcion = desecho.descripcion || this.noValueMessage;
      return {
        id,
        nombre,
        descripcion,
        data: desecho,
      };
    });
  }

  @Emit("click:informacion")
  onClickInformacion(desecho: Desecho): Desecho {
    return desecho;
  }

  getClasses({ data }: { data: Desecho }): {
    capitalize: string;
    uppercase: string;
    normal: string;
  } {
    const seleccionado = this.desecho?.id === data.id;
    return {
      capitalize: `mx-n2 pa-0 metropolis text-center text-body-1 text-capitalize ${
        seleccionado ? "primary--text font-weight-bold" : ""
      }`,
      uppercase: `pa-0 metropolis text-center text-body-1 text-uppercase ${
        seleccionado ? "primary--text font-weight-bold" : ""
      }`,
      normal: `mx-n2 pa-0 metropolis text-center text-body-1 ${
        seleccionado ? "primary--text font-weight-bold" : ""
      }`,
    };
  }
}
