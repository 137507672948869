import { render, staticRenderFns } from "./TotalExtendido.vue?vue&type=template&id=aac6d940&scoped=true&"
import script from "./TotalExtendido.vue?vue&type=script&lang=ts&"
export * from "./TotalExtendido.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aac6d940",
  null
  
)

export default component.exports