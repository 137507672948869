
import {
  Component,
  Vue,
  Prop,
  Watch,
  Emit,
  VModel,
} from "vue-property-decorator";

import {Ruta} from "@/typings/store/modules/rutas";

import SvgIcon from "@/components/custom/SvgIcon.vue";
import ListaRutas from "@/components/navDrawer/ListaRutas.vue";
import MensajeConfirmacion from "@/components/custom/ConfirmationDialog.vue";

@Component({
  components: {
    MensajeConfirmacion,
    SvgIcon,
    ListaRutas,
  },
})
export default class NavDrawer extends Vue {
  @VModel() drawer!: boolean;
  @Prop() readonly rutas!: Ruta[];

  created(): void {
    this.miniVariant = this.$vuetify.breakpoint.width < 1920;
  }

  miniVariant = true;
  dialogoMensajeConfirmacion = false;

  @Emit("logout")
  onLogout(): void {
    return;
  }

  @Emit("mini-variant-change")
  onMiniVariantChange(value: boolean): boolean {
    return value;
  }

  @Watch("miniVariant", {immediate: true})
  onWatchMiniVariant(newValue: boolean): void {
    this.onMiniVariantChange(newValue);
  }

  logout(): void {
    this.dialogoMensajeConfirmacion = true;
  }
}
