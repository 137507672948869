
// vue
import { defineComponent, computed, ref, reactive } from "vue";
// composables
import { useI18n } from "@/composables/i18n";
//tipos
import { RutaBarredor } from "@/typings/store/plugins/easyFirestore/rutasBarredor";
import { Barredor } from "@/typings/store/plugins/easyFirestore/barredores";
import { Rastreador } from "@/typings/store/plugins/easyFirestore/rastreadores";
// validaciones
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default defineComponent({
  props: {
    barredor: {
      default: null,
      type: Object as () => Barredor | null,
    },
    barredores: {
      default: () => [],
      type: Array as () => Barredor[],
    },
    rutas: {
      default: () => [],
      type: Array as () => RutaBarredor[],
    },
    rastreadores: {
      default: () => [],
      type: Array as () => Rastreador[],
    },
  },
  created(): void {
    if (this.barredor) {
      this.id = this.barredor.id ?? "";
      this.nombre = this.barredor.nombre;
      this.color = this.barredor.color ?? "#FF0000";
      this.ruta = this.barredor.ruta ?? null;
      this.numero = this.barredor.numero ?? 0;
      this.rastreador = this.barredor.rastreador ?? null;
      const horarioSplit = this.barredor.horario.split("-");
      this.horaInicio = horarioSplit[0];
      this.horaFin = horarioSplit[1];
      this.telefono = this.barredor.telefono ?? "";
    }
  },
  emits: ["click:cerrar", "click:guardar"],
  setup(props, ctx) {
    const { t } = useI18n();

    const rastreador = ref<Rastreador | null>(null);
    const horaInicio = ref("00:00");
    const horaFin = ref("23:59");
    const numero = ref(0);
    const id = ref("");
    const nombre = ref("");
    const color = ref("#FF0000");
    const telefono = ref("");
    const ruta = ref<RutaBarredor | null>(null);

    const numeroRepetido = computed((): boolean => {
      return props.barredores.some(
        (barredor) =>
          barredor.numero === numero.value && barredor.id !== id.value
      );
    });

    const titulo = computed((): string => {
      const index = props.barredor ? 0 : 1;
      return t(`barredores.tab.barredores.formulario.title.${index}`) as string;
    });

    const rutaSelect = computed({
      get: () => {
        return {
          value: ruta.value?.id ?? null,
          text: ruta.value?.nombre ?? "Sin ruta",
        };
      },
      set: (data: { value: string | null; text: string }) => {
        if (data?.value) {
          ruta.value = props.rutas.find((r) => r.id === data.value) ?? null;
        } else {
          ruta.value = null;
        }
      },
    });

    const arrayRutas = computed(
      (): Array<{ value: string | null; text: string }> => {
        const rutas: Array<{ value: string | null; text: string }> =
          props.rutas.map((ruta) => ({
            value: ruta.id ?? "",
            text: ruta.nombre,
          }));
        rutas.unshift({
          value: null,
          text: t(
            "barredores.tab.barredores.formulario.ruta.no-value-message"
          ) as string,
        });
        return rutas;
      }
    );

    const state = reactive({
      nombre,
      numero,
      rastreador,
      horaInicio,
      horaFin,
    });

    const rules = {
      nombre: { required },
      numero: { required },
      rastreador: { required },
      horaInicio: { required },
      horaFin: { required },
    };

    const v$ = useVuelidate(rules, state);

    const nombreErrors = computed((): Array<string> => {
      const errors: Array<string> = [];
      if (!v$.value.nombre.$dirty) return errors;
      if (v$.value.nombre.required.$invalid)
        errors.push(
          t(
            "barredores.tab.barredores.formulario.nombre.error-message"
          ) as string
        );
      return errors;
    });

    const rastreadorErrors = computed((): Array<string> => {
      const errors: Array<string> = [];
      if (!v$.value.rastreador.$dirty) return errors;
      if (v$.value.rastreador.required.$invalid)
        errors.push(
          t(
            "barredores.tab.barredores.formulario.rastreador.error-message"
          ) as string
        );
      return errors;
    });

    const numeroErrors = computed((): Array<string> => {
      const errors: Array<string> = [];
      if (!v$.value.numero.$dirty) return errors;
      if (v$.value.numero.required.$invalid)
        errors.push(
          t(
            "barredores.tab.barredores.formulario.identificador.error-message.0"
          ) as string
        );
      if (numeroRepetido.value)
        errors.push(
          t(
            "barredores.tab.barredores.formulario.identificador.error-message.1"
          ) as string
        );
      return errors;
    });

    const horaInicioErrors = computed((): Array<string> => {
      const errors: Array<string> = [];
      if (!v$.value.horaInicio.$dirty) return errors;
      if (v$.value.horaInicio.required.$invalid)
        errors.push(
          t(
            "barredores.tab.barredores.formulario.hora-inicio.error-message"
          ) as string
        );
      return errors;
    });

    const horaFinErrors = computed((): Array<string> => {
      const errors: Array<string> = [];
      if (!v$.value.horaFin.$dirty) return errors;
      if (v$.value.horaFin.required.$invalid)
        errors.push(
          t(
            "barredores.tab.barredores.formulario.hora-fin.error-message"
          ) as string
        );
      return errors;
    });

    const onClickCerrar = (): void => {
      ctx.emit("click:cerrar");
    };

    const onClickGuardar = (doc: Barredor): void => {
      ctx.emit("click:guardar", doc);
    };

    const guardar = (): void => {
      v$.value.$touch();
      if (v$.value.$invalid || numeroRepetido.value) return;
      const barredor: Barredor = {
        nombre: nombre.value,
        id: id.value,
        color: color.value,
        ruta: ruta.value,
        numero: numero.value || 0,
        horario: `${horaInicio.value}-${horaFin.value}`,
        mostrarRuta: false,
        telefono: telefono.value,
        rastreador: rastreador.value,
      };
      onClickGuardar(barredor);
    };

    return {
      t,
      v$,
      rastreador,
      horaInicio,
      horaFin,
      numero,
      id,
      nombre,
      color,
      telefono,
      ruta,
      rutaSelect,
      arrayRutas,
      nombreErrors,
      rastreadorErrors,
      numeroErrors,
      horaInicioErrors,
      horaFinErrors,
      titulo,
      numeroRepetido,
      onClickCerrar,
      guardar,
    };
  },
});
