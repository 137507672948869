
//decoradores
import {
  Component,
  Emit,
  Prop,
  VModel,
  Vue,
  Watch,
} from "vue-property-decorator";
//tipos
import { Chofer } from "@/typings/store/plugins/easyFirestore/choferes";
import {
  EstadoPesaje,
  Pesaje,
  PesajeData,
} from "@/typings/store/plugins/easyFirestore/pesajes";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: {
    SvgIcon,
  },
})
export default class EntradaSalidaBascula extends Vue {
  @VModel() pesajeData!: PesajeData;
  @Prop() readonly pesaje!: Pesaje | null;
  @Prop() readonly arrayChoferes!: Array<Chofer>;
  @Prop() readonly fijado!: boolean;
  @Prop() readonly disabledFijar!: boolean;
  @Prop() readonly choferEntradaErrors!: Array<string>;

  created(): void {
    if (this.pesaje) {
      this.onEstadoChange(this.pesaje.estado);
    }
  }

  tab = 0;

  get textoBotonFijar(): string {
    const flag = this.fijado ? "fijado" : "fijar";
    return this.$t(
      `bascula.entrada-salida.tab.entrada.boton-fijar.${flag}`
    ) as string;
  }

  get fechaEntrada(): string {
    const fecha =
      this.pesajeData.fechaEntrada ?? (this.pesaje?.entrada?.fecha || "");
    return fecha;
  }

  get pesoEntrada(): string {
    const peso =
      this.pesajeData.pesoEntrada ?? (this.pesaje?.entrada?.peso || 0);
    return `${peso} kg`;
  }

  get choferEntrada(): Chofer | null {
    const chofer =
      this.pesajeData.choferEntrada || this.pesaje?.entrada?.chofer || null;
    return chofer;
  }
  set choferEntrada(value: Chofer | null) {
    let chofer: Chofer | null;
    if (typeof value === "string") {
      chofer = {
        nombre: (value as string).toLowerCase(),
        rut: "",
        cargo: "",
        fechaNacimiento: "",
        fechaContratacion: "",
        contacto: "",
        estado: "habilitado",
        foto: null,
      };
    } else {
      chofer = Object.assign({}, value);
    }
    this.$set(this.pesajeData, "choferEntrada", chofer);
  }

  get observacionEntrada(): string {
    const observacion =
      this.pesajeData.observacionEntrada ||
      this.pesaje?.entrada?.observacion ||
      "";
    return observacion;
  }
  set observacionEntrada(value: string) {
    this.$set(this.pesajeData, "observacionEntrada", value);
  }

  get fechaSalida(): string {
    const fecha =
      this.pesajeData.fechaSalida ?? (this.pesaje?.salida?.fecha || "");
    return fecha;
  }

  get pesoSalida(): string {
    const peso = this.pesajeData.pesoSalida ?? (this.pesaje?.salida?.peso || 0);
    return `${peso} kg`;
  }

  get choferSalida(): Chofer | null {
    const chofer =
      this.pesajeData.choferSalida || this.pesaje?.salida?.chofer || null;
    return chofer;
  }
  set choferSalida(value: Chofer | null) {
    let chofer: Chofer | null;
    if (typeof value == "string") {
      chofer = {
        nombre: (value as string).toLowerCase(),
        rut: "",
        cargo: "",
        fechaNacimiento: "",
        fechaContratacion: "",
        contacto: "",
        estado: "habilitado",
        foto: null,
      };
    } else {
      chofer = Object.assign({}, value);
    }
    this.$set(this.pesajeData, "choferSalida", chofer);
  }

  get observacionSalida(): string {
    const observacion =
      this.pesajeData.observacionSalida ||
      this.pesaje?.salida?.observacion ||
      "";
    return observacion;
  }
  set observacionSalida(value: string) {
    this.$set(this.pesajeData, "observacionSalida", value);
  }

  get estado(): EstadoPesaje {
    return this.pesajeData.estado || this.pesaje?.estado || "entrada";
  }

  @Watch("estado")
  onEstadoChange(value: EstadoPesaje): void {
    if (value === "salida") {
      this.tab = 1;
    } else {
      this.tab = 0;
    }
  }

  @Emit("click:fijar")
  onClickFijar(): void {
    return;
  }
}
