
//decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
//tipos
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";
import { Vehiculo } from "@/typings/store/plugins/easyFirestore/vehiculos";
import { Permisos } from "@/typings/store/modules/permisos";
import { DataFormularioVehiculo } from "@/typings/components/vehiculos/formulario";
//componentes
import TablaVehiculos from "@/components/transporte/vehiculos/Tabla.vue";
import DetalleVehiculo from "@/components/transporte/vehiculos/Detalle.vue";
import FormularioVehiculo from "@/components/transporte/vehiculos/FormularioVehiculo.vue";

@Component({
  components: {
    TablaVehiculos,
    DetalleVehiculo,
    FormularioVehiculo,
  },
})
export default class TabVehiculos extends Vue {
  @Prop() readonly arrayVehiculos!: Array<Vehiculo>;
  @Prop() readonly mostrarFormulario!: boolean;
  @Prop() readonly mostrarDetalle!: boolean;
  @Prop() readonly vehiculo!: Vehiculo | null;
  @Prop() readonly search!: string;
  @Prop() readonly cargando!: boolean;
  @Prop() readonly usuario!: Usuario;
  @Prop() readonly permisos!: Permisos;

  @Emit("click:guardar")
  onClickGuardar(data: DataFormularioVehiculo): DataFormularioVehiculo {
    return data;
  }

  @Emit("click:informacion")
  onClickInformacion(vehiculo: Vehiculo): Vehiculo {
    return vehiculo;
  }

  @Emit("click:switch-estado")
  onClickSwitchEstado(vehiculo: Vehiculo): Vehiculo {
    return vehiculo;
  }

  @Emit("click:editar")
  onClickEditar(): void {
    return;
  }

  @Emit("click:eliminar")
  onClickEliminar(): void {
    return;
  }
}
