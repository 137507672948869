
// decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// tipos
import { Item } from "@/typings/components/reportes/tabla";
import { Pesaje } from "@/typings/store/plugins/easyFirestore/pesajes";
// componentes
import IconButton from "@/components/custom/IconButton.vue";

@Component({
  components: {
    IconButton,
  },
})
export default class OpcionesTabla extends Vue {
  @Prop() readonly item!: Item;
  @Prop() readonly seleccionado!: Pesaje | null;

  get isSelected(): boolean {
    return (
      this.seleccionado !== null && this.seleccionado.id === this.item.pesaje.id
    );
  }

  @Emit("click:informacion")
  onClickInformacion(): Item {
    return this.item;
  }
}
