
// decoradores
import { Component, Vue, Emit, Prop } from "vue-property-decorator";
//tipos
import { Permisos } from "@/typings/store/modules/permisos";
// componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: {
    SvgIcon,
  },
})
export default class BotonesUsuario extends Vue {
  @Prop() readonly estado!: number;
  @Prop() readonly guardando?: boolean;
  @Prop() readonly hidePermisos?: boolean;
  @Prop() readonly hideGuardar?: boolean;
  @Prop() readonly hideEliminar?: boolean;
  @Prop() readonly hideEditar?: boolean;
  @Prop() readonly disabledPermisos?: boolean;
  @Prop() readonly disabledGuardar?: boolean;
  @Prop() readonly disabledEliminar?: boolean;
  @Prop() readonly disabledEditar?: boolean;
  @Prop() readonly permisos!: Permisos;

  get mostrarBotonPermisos(): boolean {
    return (
      !this.hidePermisos &&
      this.permisos.usuarios !== "ver" &&
      this.estado === 1
    );
  }

  get mostrarBotonGuardar(): boolean {
    return !this.hideGuardar && this.permisos.usuarios !== "ver";
  }

  get mostrarBotonEditar(): boolean {
    return !this.hideEditar && this.permisos.usuarios !== "ver";
  }

  get mostrarBotonEliminar(): boolean {
    return (
      !this.hideEliminar &&
      this.permisos.usuarios === "eliminar" &&
      this.estado === 1
    );
  }

  @Emit("click-permisos")
  onClickPermisos(): void {
    return;
  }

  @Emit("click-editar")
  onClickEditar(): void {
    return;
  }

  @Emit("click-guardar")
  onClickGuardar(): void {
    return;
  }

  @Emit("click-eliminar")
  onClickEliminar(): void {
    return;
  }
}
