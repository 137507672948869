
//decoradores
import { Component, Prop, Vue } from "vue-property-decorator";
//tipos
import { Testimonio } from "@/typings/store/plugins/easyFirestore/testimonios";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: { SvgIcon },
})
export default class TestimoniosPagina extends Vue {
  @Prop() readonly testimonios!: Array<Testimonio>;

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.mobile;
  }
}
