
//decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
//tipos
import { Permisos } from "@/typings/store/modules/permisos";
import { RutaPesaje } from "@/typings/store/plugins/easyFirestore/rutasPesaje";
//componentes
import TablaRutasPesaje from "@/components/transporte/rutas/Tabla.vue";
import DetalleRutasPesaje from "@/components/transporte/rutas/Detalle.vue";
import FormularioRutasPesaje from "@/components/transporte/rutas/FormularioRuta.vue";

@Component({
  components: {
    TablaRutasPesaje,
    DetalleRutasPesaje,
    FormularioRutasPesaje,
  },
})
export default class TabRutasPesaje extends Vue {
  @Prop() readonly arrayRutasPesaje!: Array<RutaPesaje>;
  @Prop() readonly mostrarFormulario!: boolean;
  @Prop() readonly mostrarDetalle!: boolean;
  @Prop() readonly rutaPesaje!: RutaPesaje | null;
  @Prop() readonly search!: string;
  @Prop() readonly cargando!: boolean;
  @Prop() readonly permisos!: Permisos;

  @Emit("click:guardar")
  onClickGuardar(ruta: RutaPesaje): RutaPesaje {
    return ruta;
  }

  @Emit("click:informacion")
  onClickInformacion(ruta: RutaPesaje): RutaPesaje {
    return ruta;
  }

  @Emit("click:switch-estado")
  onClickSwitchEstado(ruta: RutaPesaje): RutaPesaje {
    return ruta;
  }

  @Emit("click:editar")
  onClickEditar(): void {
    return;
  }

  @Emit("click:eliminar")
  onClickEliminar(): void {
    return;
  }
}
