
//decoradores
import { Component, Prop, Vue } from "vue-property-decorator";
//tipos
import { PesajeMonitoreoDesecho } from "@/typings/components/monitoreo/desecho";
//componentes
import DonutChart from "@/components/monitoreo/charts/DonutChart.vue";

@Component({
  components: {
    DonutChart,
  },
})
export default class TipoClienteDesechos extends Vue {
  @Prop() readonly items!: Array<PesajeMonitoreoDesecho>;
  @Prop() readonly unidad?: string;

  get labels(): Array<string> {
    return [
      this.$t("monitoreo.tab.desechos.grafico-4.tipo.0") as string,
      this.$t("monitoreo.tab.desechos.grafico-4.tipo.1") as string,
      this.$t("monitoreo.tab.desechos.grafico-4.tipo.2") as string,
    ];
  }

  get series(): Array<number> {
    const helper: { [key: string]: number } = {
      empresa: 0,
      particular: 0,
      municipal: 0,
    };
    for (const pesaje of this.items) {
      const tipo = pesaje.tipoCliente;
      if (tipo) {
        helper[tipo] = (helper[tipo] || 0) + pesaje.valor;
      }
    }
    return Object.values(helper);
  }

  formatter(value: number): string {
    if (this.unidad === "t") {
      return `${(value / 1000).toFixed(1)} T`;
    } else {
      return `${value} ${this.unidad}`;
    }
  }
}
