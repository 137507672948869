
import SvgIcon from "../custom/SvgIcon.vue";
import {
  Component,
  Emit,
  Prop,
  VModel,
  Vue,
  Watch,
} from "vue-property-decorator";
import { Cliente } from "@/typings/store/plugins/easyFirestore/clientes";
import { Servicio } from "@/typings/store/plugins/easyFirestore/servicios";
import { Filtros } from "@/typings/components/monitoreo/filtros";

@Component({
  components: {
    SvgIcon,
  },
})
export default class FiltrosMonitoreo extends Vue {
  @VModel() filtros!: Filtros;
  @Prop() readonly clientes!: Array<Cliente>;
  @Prop() readonly servicios!: Array<Servicio>;
  @Prop() readonly tab!: number;

  created(): void {
    this.fechasPeriodo = [
      new Date().format("YYYY-MM-DD"),
      new Date().format("YYYY-MM-DD"),
    ];
  }

  get periodos(): Array<{ text: string; value: number }> {
    return [
      {
        text: this.$t("monitoreo.filtro.periodo.value.0") as string,
        value: 1,
      },
      {
        text: this.$t("monitoreo.filtro.periodo.value.1") as string,
        value: 2,
      },
      {
        text: this.$t("monitoreo.filtro.periodo.value.2") as string,
        value: 3,
      },
      {
        text: this.$t("monitoreo.filtro.periodo.value.3") as string,
        value: 4,
      },
      {
        text: this.$t("monitoreo.filtro.periodo.value.4") as string,
        value: 5,
      },
      {
        text: this.$t("monitoreo.filtro.periodo.value.5") as string,
        value: 6,
      },
    ];
  }

  fechasPeriodo: Array<string> = [];
  menuPeriodo = false;
  widthPeriodo = 0;

  get activatorCard(): HTMLElement | null {
    return (
      (this.$refs["activator-card"] as (Vue & { $el: HTMLElement }) | null)
        ?.$el ?? null
    );
  }

  get textoPeriodo(): string {
    if (this.filtros.periodo === 6) {
      return this.fechasPeriodo
        .map((fecha) => fecha.toDate().format("DD/MM/YY"))
        .join(" ~ ");
    } else {
      return this.filtros.periodo
        ? this.periodos[this.filtros.periodo - 1].text
        : "Selecciona";
    }
  }

  get unidadDesechos(): "kg" | "m3" | "t" {
    return this.filtros.unidadDesechos;
  }

  get filtroFechas(): Array<Date> {
    let fechaInicio: Date | null = new Date();
    let fechaFin: Date | null = new Date();
    switch (this.filtros.periodo) {
      case 1:
        fechaInicio.setDate(fechaInicio.getDate() - 7);
        break;
      case 2:
        fechaInicio.setDate(fechaInicio.getDate() - 30);
        break;
      case 3:
        fechaInicio.setDate(fechaInicio.getDate() - 90);
        break;
      case 4:
        fechaInicio.setMonth(0);
        fechaInicio.setDate(1);
        break;
      case 5:
        fechaInicio.setMonth(0);
        fechaInicio.setDate(1);
        fechaInicio.setFullYear(fechaInicio.getFullYear() - 1);
        fechaFin.setMonth(11);
        fechaFin.setDate(31);
        fechaFin.setFullYear(fechaFin.getFullYear() - 1);
        break;
      case 6:
        return this.filtros.fechas;
      default:
        return [];
    }
    fechaInicio?.setHours(0, 0, 0, 0);
    fechaFin?.setHours(23, 59, 59, 999);
    return [fechaInicio, fechaFin];
  }

  get clientesOrdenados(): Array<Cliente> {
    const clientes = this.clientes.slice();
    clientes.sort((a, b) => a.nombre.localeCompare(b.nombre));
    return clientes;
  }

  get serviciosOrdenados(): Array<Servicio> {
    const servicios = this.servicios.slice();
    servicios.sort((a, b) => a.nombre.localeCompare(b.nombre));
    return servicios;
  }

  get periodoSeleccionado(): number {
    return this.filtros.periodo;
  }

  get diferenciaDiasHoy(): number {
    const fechaHoy = new Date();
    const fechaInicio = this.filtroFechas[0];
    if (!fechaInicio) return 0;
    const diferencia = fechaHoy.getTime() - fechaInicio.getTime();
    return Math.round(diferencia / (1000 * 60 * 60 * 24));
  }

  get textoFiltroClientes(): string {
    const length = this.filtros.clientes.length;
    if (length === 1) {
      return this.filtros.clientes[0].nombre;
    } else {
      return this.$tc("monitoreo.filtro.cliente.selected", length);
    }
  }

  get textoFiltroServicios(): string {
    const length = this.filtros.servicios.length;
    if (length === 1) {
      return this.filtros.servicios[0].nombre;
    } else {
      return this.$tc("monitoreo.filtro.servicio.selected", length);
    }
  }

  @Emit("fetch:pesajes")
  onFetchPesajes(fechas: Array<Date>): Array<Date> {
    return fechas;
  }

  @Watch("fechasPeriodo")
  onFechasPeriodoChange(): void {
    const [fechaInicioString, fechaFinString] = this.fechasPeriodo;
    if (
      fechaInicioString &&
      fechaFinString &&
      fechaInicioString.localeCompare(fechaFinString) > 0
    ) {
      this.fechasPeriodo = [fechaFinString, fechaInicioString];
    }
  }

  @Watch("filtroFechas", { immediate: true })
  onFiltroFechasChange(fechas: Array<Date>): void {
    this.filtros.fechas = fechas;
    if (this.diferenciaDiasHoy > 100) {
      const fechaMenos100 = new Date();
      fechaMenos100.setDate(fechaMenos100.getDate() - 100);
      const fechaInicio = fechas[0];
      if (fechaInicio) {
        this.onFetchPesajes([fechaInicio, fechaMenos100]);
      }
    }
  }

  limpiarPeriodo(): void {
    this.filtros.periodo = 1;
    this.fechasPeriodo = [];
  }

  confirmarPeriodo(): void {
    this.menuPeriodo = false;
    const [fechaInicioString, fechaFinString] = this.fechasPeriodo;
    const fechaInicio = fechaInicioString
      ? fechaInicioString.toDate()
      : new Date();
    fechaInicio.setHours(0, 0, 0, 0);
    const fechaFin = fechaFinString
      ? fechaFinString.toDate()
      : fechaInicioString
      ? fechaInicioString.toDate()
      : new Date();
    fechaFin.setHours(23, 59, 59, 999);
    this.fechasPeriodo = [
      fechaInicio.format("YYYY-MM-DD"),
      fechaFin.format("YYYY-MM-DD"),
    ];
    this.filtros.fechas = [fechaInicio, fechaFin];
  }

  openMenuPeriodo(): void {
    const bounds = this.activatorCard?.getBoundingClientRect();
    this.widthPeriodo = bounds?.width ?? 0;
  }

  seleccionarPeriodo(value: number): void {
    if (value !== 6) this.menuPeriodo = false;
  }
}
