
//composition
import { computed, defineComponent } from "vue";
//tipos
import { DataTableFooterProps } from "@/typings/vuetify/v-data-table";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "DataTableFooter",
  props: {
    value: {
      type: Object as () => DataTableFooterProps,
      required: true,
    },
  },
  components: {
    SvgIcon,
  },
  emits: ["input"],
  setup(props, ctx) {
    const model = computed({
      get() {
        return props.value;
      },
      set(value: DataTableFooterProps) {
        ctx.emit("input", value);
      },
    });

    const currentPage = computed(() => model.value.pagination.page);
    const pageCount = computed(() => model.value.pagination.pageCount);

    const pages = computed(() => {
      const page = currentPage.value;
      if (page === 1) {
        const pages = [page, page + 1, page + 2];
        return pages.filter((p) => p <= pageCount.value);
      } else if (page === pageCount.value) {
        const pages = [page - 2, page - 1, page];
        return pages.filter((p) => p > 0);
      } else {
        const pages = [page - 1, page, page + 1];
        return pages.filter((p) => p > 0 && p <= pageCount.value);
      }
    });

    const previousPage = () => {
      if (currentPage.value > 1) {
        model.value.options.page = currentPage.value - 1;
      }
    };

    const nextPage = () => {
      if (currentPage.value < pageCount.value) {
        model.value.options.page = currentPage.value + 1;
      }
    };

    return {
      model,
      currentPage,
      pageCount,
      pages,
      previousPage,
      nextPage,
    };
  },
});
