
// vue
import { defineComponent, computed, ref, reactive } from "vue";
// composables
import { useI18n } from "@/composables/i18n";
//tipos
import { Mail } from "@/typings/store/plugins/easyFirestore/mail";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
// validaciones
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

export default defineComponent({
  components: {
    SvgIcon,
  },
  emits: ["click:enviar"],
  setup(props, ctx) {
    const { t } = useI18n();

    const nombre = ref("");
    const telefono = ref("");
    const mail = ref("");
    const rutEmpresa = ref("");
    const tipoEmpresa = ref("");

    const tiposEmpresa = computed((): Array<string> => {
      return [
        t("pagina.contacto.formulario.empresa.opciones.0") as string,
        t("pagina.contacto.formulario.empresa.opciones.1") as string,
        t("pagina.contacto.formulario.empresa.opciones.2") as string,
        t("pagina.contacto.formulario.empresa.opciones.3") as string,
        t("pagina.contacto.formulario.empresa.opciones.4") as string,
        t("pagina.contacto.formulario.empresa.opciones.5") as string,
      ];
    });

    const state = reactive({
      nombre,
      telefono,
      mail,
      rutEmpresa,
      tipoEmpresa,
    });

    const rules = {
      nombre: {
        required,
      },
      telefono: {
        required,
      },
      mail: {
        required,
        email,
      },
      rutEmpresa: {
        required,
      },
      tipoEmpresa: {
        required,
      },
    };

    const v$ = useVuelidate(rules, state);

    const erroresNombre = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.nombre.$dirty) return errores;
      if (v$.value.nombre.required.$invalid) {
        const error = t(
          "pagina.contacto.formulario.nombre.errores.0"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresTelefono = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.telefono.$dirty) return errores;
      if (v$.value.telefono.required.$invalid) {
        const error = t(
          "pagina.contacto.formulario.telefono.errores.0"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresEmail = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.mail.$dirty) return errores;
      if (v$.value.mail.required.$invalid) {
        const error = t("pagina.contacto.formulario.email.errores.0") as string;
        errores.push(error);
      }
      if (v$.value.mail.email) {
        const error = t("pagina.contacto.formulario.email.errores.1") as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresRutEmpresa = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.rutEmpresa.$dirty) return errores;
      if (v$.value.rutEmpresa.required.$invalid) {
        const error = t(
          "pagina.contacto.formulario.rut-empresa.errores.0"
        ) as string;
        errores.push(error);
      }
      if (!rutEmpresa.value.validateRUT()) {
        const error = t(
          "pagina.contacto.formulario.rut-empresa.errores.1"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresTipoEmpresa = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.tipoEmpresa.$dirty) return errores;
      if (v$.value.tipoEmpresa.required.$invalid) {
        const error = t(
          "pagina.contacto.formulario.empresa.errores.0"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const onClickEnviar = (mail: Mail): void => {
      ctx.emit("click:enviar", mail);
    };

    const enviar = (): void => {
      v$.value.$touch();
      if (v$.value.$invalid || !rutEmpresa.value.validateRUT()) return;
      const nuevo: Mail = {
        to: "contacto@weightruck.cl",
        message: {
          subject: "Contacto desde la web",
          html: `
          <p><b>Nombre:</b> ${nombre.value}</p>
          <p><b>Telefono:</b> ${telefono.value}</p>
          <p><b>Email:</b> ${mail.value}</p>
          <p><b>RUT Empresa:</b> ${rutEmpresa.value}</p>
          <p><b>Tipo Empresa:</b> ${tipoEmpresa.value}</p>
        `,
        },
      };
      onClickEnviar(nuevo);
      v$.value.$reset();
      nombre.value = "";
      telefono.value = "";
      mail.value = "";
      rutEmpresa.value = "";
      tipoEmpresa.value = "";
    };

    return {
      t,
      v$,
      nombre,
      telefono,
      mail,
      rutEmpresa,
      tipoEmpresa,
      tiposEmpresa,
      erroresNombre,
      erroresTelefono,
      erroresEmail,
      erroresRutEmpresa,
      erroresTipoEmpresa,
      enviar,
    };
  },
});
