
//composition
import {defineComponent, computed} from "vue";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "SearchTextField",
  components: {
    SvgIcon
  },
  emits: [
    "input"
  ],
  props: {
    placeholder: {
      type: String,
      required: true
    },
    value: {
      type:String,
      required: true
    }
  },
  setup(props, ctx) {
    const buscar = computed({
      get() {
        return props.value;
      },
      set(value) {
        ctx.emit("input", value);
      }
    });

    return {
      buscar
    };
  }
})
