
// vue
import { defineComponent, computed, ref, reactive } from "vue";
// composables
import { useI18n } from "@/composables/i18n";
// helpers
import { imageToWebp } from "@/helpers/image";
// tipos
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";
import { DataFormularioVehiculo } from "@/typings/components/vehiculos/formulario";
import {
  Combustible,
  EstadoVehiculo,
  TipoVehiculo,
  Vehiculo,
} from "@/typings/store/plugins/easyFirestore/vehiculos";
// componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import SubirImagen from "@/components/SubirImagen.vue";
// validaciones
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default defineComponent({
  components: { SvgIcon, SubirImagen },
  props: {
    vehiculo: {
      default: null,
      type: Object as () => Vehiculo | null,
    },
    cargando: {
      default: false,
      type: Boolean,
    },
    usuario: {
      default: null,
      type: Object as () => Usuario | null,
    },
  },
  created(): void {
    if (this.vehiculo) {
      this.id = this.vehiculo.id ?? "";
      this.patente = this.vehiculo.patente ?? "";
      this.tipo = this.vehiculo.tipo ?? null;
      this.marca = this.vehiculo.marca ?? "";
      this.modelo = this.vehiculo.modelo ?? "";
      this.año = this.vehiculo.año?.toString() ?? "";
      this.combustible = this.vehiculo.combustible ?? null;
      this.numeroChasis = this.vehiculo.numeroChasis ?? "";
      this.numeroMotor = this.vehiculo.numeroMotor ?? "";
      this.estado = this.vehiculo.estado ?? "habilitado";
    }
  },
  emits: ["click:guardar"],
  setup(props, ctx) {
    const { t } = useI18n();

    const id = ref("");
    const patente = ref("");
    const tipo = ref<TipoVehiculo | null>(null);
    const marca = ref("");
    const modelo = ref("");
    const año = ref("");
    const combustible = ref<Combustible | null>(null);
    const numeroChasis = ref("");
    const numeroMotor = ref("");
    const file = ref<File | null>(null);
    const estado = ref<EstadoVehiculo>("habilitado");

    const tipos = computed((): Array<{ text: string; value: TipoVehiculo }> => {
      return [
        {
          text: t("transporte.tab.flota.formulario.tipo.values[0]") as string,
          value: "auto",
        },
        {
          text: t("transporte.tab.flota.formulario.tipo.values[1]") as string,
          value: "camioneta",
        },
        {
          text: t("transporte.tab.flota.formulario.tipo.values[2]") as string,
          value: "camion",
        },
      ];
    });

    const combustibles = computed(
      (): Array<{ text: string; value: Combustible }> => {
        return [
          {
            text: t(
              "transporte.tab.flota.formulario.combustible.values[0]"
            ) as string,
            value: "bencina",
          },
          {
            text: t(
              "transporte.tab.flota.formulario.combustible.values[1]"
            ) as string,
            value: "diesel",
          },
        ];
      }
    );

    const titulo = computed((): string => {
      const index = props.vehiculo ? 0 : 1;
      return t(`transporte.tab.flota.formulario.title.${index}`) as string;
    });

    const textoBoton = computed((): string => {
      const index = props.vehiculo ? 0 : 1;
      return t(
        `transporte.tab.flota.formulario.boton.text[${index}]`
      ) as string;
    });

    const foto = computed((): string => {
      return file.value
        ? URL.createObjectURL(file.value)
        : props.vehiculo?.foto?.url ?? "";
    });

    const state = reactive({
      patente,
      tipo,
      combustible,
      marca,
      modelo,
      año,
      numeroChasis,
      numeroMotor,
    });

    const rules = {
      patente: { required },
      tipo: { required },
      combustible: { required },
      marca: { required },
      modelo: { required },
      año: { required },
      numeroChasis: { required },
      numeroMotor: { required },
    };

    const v$ = useVuelidate(rules, state);

    const erroresPatente = (): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.patente.$dirty) return errores;
      if (v$.value.patente.required.$invalid)
        errores.push(
          t(
            "transporte.tab.flota.formulario.patente.error-messages[0]"
          ) as string
        );
      const regex = /^([A-Z]{2}[1-9][0-9]{3})$|^([A-Z]{4}[1-9][0-9])$/gi;
      if (!regex.test(patente.value))
        errores.push(
          t(
            "transporte.tab.flota.formulario.patente.error-messages[0]"
          ) as string
        );
      return errores;
    };

    const erroresTipo = (): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.tipo.$dirty) return errores;
      if (v$.value.tipo.required.$invalid)
        errores.push(
          t("transporte.tab.flota.formulario.tipo.error-message") as string
        );
      return errores;
    };

    const erroresMarca = (): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.marca.$dirty) return errores;
      if (v$.value.marca.required.$invalid)
        errores.push(
          t("transporte.tab.flota.formulario.marca.error-message") as string
        );
      return errores;
    };

    const erroresModelo = (): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.modelo.$dirty) return errores;
      if (v$.value.modelo.required.$invalid)
        errores.push(
          t("transporte.tab.flota.formulario.modelo.error-message") as string
        );
      return errores;
    };

    const erroresAño = (): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.año.$dirty) return errores;
      if (v$.value.año.required.$invalid)
        errores.push(
          t("transporte.tab.flota.formulario.year.error-message") as string
        );
      return errores;
    };

    const erroresCombustible = (): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.combustible.$dirty) return errores;
      if (v$.value.combustible.required.$invalid)
        errores.push(
          t(
            "transporte.tab.flota.formulario.combustible.error-message"
          ) as string
        );
      return errores;
    };

    const erroresNumeroChasis = (): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.numeroChasis.$dirty) return errores;
      if (v$.value.numeroChasis.required.$invalid)
        errores.push(
          t(
            "transporte.tab.flota.formulario.numero-chasis.error-messages[0]"
          ) as string
        );
      if (numeroChasis.value.length > 17)
        errores.push(
          t(
            "transporte.tab.flota.formulario.numero-chasis.error-messages[1]"
          ) as string
        );
      return errores;
    };

    const erroresNumeroMotor = (): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.numeroMotor.$dirty) return errores;
      if (v$.value.numeroMotor.required.$invalid)
        errores.push(
          t(
            "transporte.tab.flota.formulario.numero-motor.error-messages[0]"
          ) as string
        );
      if (numeroMotor.value.length > 17)
        errores.push(
          t(
            "transporte.tab.flota.formulario.numero-motor.error-messages[1]"
          ) as string
        );
      return errores;
    };

    const guardar = async (): Promise<void> => {
      v$.value.$touch();
      const regexPatente = /^([A-Z]{2}[1-9][0-9]{3})$|^([A-Z]{4}[1-9][0-9])$/gi;
      if (
        v$.value.$invalid ||
        !regexPatente.test(patente.value) ||
        numeroChasis.value.length > 17 ||
        numeroMotor.value.length > 17
      )
        return;
      const vehiculo: Vehiculo = {
        patente: patente.value,
        tipo: tipo.value ?? "camion",
        tara: -1,
        alertaPeso: -1,
        marca: marca.value,
        modelo: modelo.value,
        año: año.value.toInt(),
        combustible: combustible.value ?? "bencina",
        numeroChasis: numeroChasis.value,
        numeroMotor: numeroMotor.value,
        foto: null,
        estado: estado.value,
      };
      if (id.value) vehiculo.id = id.value;
      const data: DataFormularioVehiculo = { vehiculo };
      if (file.value) {
        const imagenRaw = file.value;
        const fecha = new Date();
        const extencion = imagenRaw.type.split("/")[1];
        let nombre = `${fecha.valueOf()}.${extencion}`;
        let imagen = await imageToWebp(imagenRaw);
        if (imagen) {
          nombre = `${fecha.valueOf()}.webp`;
        } else {
          imagen = imagenRaw;
        }
        const fechaCarpeta = new Date(fecha);
        fechaCarpeta.setHours(0, 0, 0, 0);
        const nombreCarpeta = `${fechaCarpeta.valueOf()}`;
        const ref = `vehiculos/${nombreCarpeta}/${nombre}`;
        data.archivo = {
          ref: ref,
          file: imagen,
          nombre: nombre,
        };
      }
      ctx.emit("click:guardar", data);
    };

    return {
      t,
      v$,
      id,
      patente,
      tipo,
      marca,
      modelo,
      año,
      combustible,
      numeroChasis,
      numeroMotor,
      file,
      estado,
      erroresPatente,
      erroresTipo,
      erroresMarca,
      erroresModelo,
      erroresAño,
      erroresCombustible,
      erroresNumeroChasis,
      erroresNumeroMotor,
      guardar,
      titulo,
      textoBoton,
      foto,
      tipos,
      combustibles,
    };
  },
});
