
//decoradores
import { Component, Prop, Vue } from "vue-property-decorator";
//tipos
import { Vehiculo } from "@/typings/store/plugins/easyFirestore/vehiculos";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: { SvgIcon },
})
export default class MenuInfoVehiculo extends Vue {
  @Prop() readonly vehiculo!: Vehiculo | null;
  @Prop({ type: Boolean }) readonly top!: boolean;
  @Prop() readonly parent!: string;

  width = 0;

  get activatorCard(): HTMLElement | null {
    return (
      (this.$refs["activator-card"] as (Vue & { $el: HTMLElement }) | null)
        ?.$el ?? null
    );
  }

  get noValueMessage(): string {
    return this.$t(`${this.parent}.menu.no-value-message`) as string;
  }

  get patente(): string {
    return this.vehiculo?.patente || this.noValueMessage;
  }

  get tipo(): string {
    return this.vehiculo?.tipo || this.noValueMessage;
  }

  get combustible(): string {
    return this.vehiculo?.combustible || this.noValueMessage;
  }

  get marca(): string {
    return this.vehiculo?.marca || this.noValueMessage;
  }

  get modelo(): string {
    return this.vehiculo?.modelo || this.noValueMessage;
  }

  get año(): string {
    return this.vehiculo?.año?.toString() || this.noValueMessage;
  }

  get numeroChasis(): string {
    return this.vehiculo?.numeroChasis || this.noValueMessage;
  }

  get numeroMotor(): string {
    return this.vehiculo?.numeroMotor || this.noValueMessage;
  }

  openMenu(): void {
    const bounds = this.activatorCard?.getBoundingClientRect();
    this.width = bounds?.width ?? 0;
  }
}
