
// Vue
import {Component, Emit, Prop, Vue} from "vue-property-decorator";

// Tipos
import {Barredor} from "@/typings/store/plugins/easyFirestore/barredores";
import {PingRastreador} from "@/typings/store/plugins/easyFirestore/pingsRastreador";

// Componentes
import {BarredorSinMovimiento, HorarioBarredor} from "@/typings/components/barredores/barredores";

@Component
export default class MarkerUltimaPosicion extends Vue {
  @Prop() readonly zoom!: number;
  @Prop() readonly historico!: boolean;
  @Prop() readonly barredor!: Barredor;
  @Prop() readonly horario!: HorarioBarredor;
  @Prop() readonly seleccionados!: Barredor[];
  @Prop() readonly pings!: Array<PingRastreador>;
  @Prop() readonly ultimaPosicion!: PingRastreador;

  flagInfoWindow = false;

  get infoWindow(): boolean {
    return !this.flagNoSeleccionado && this.flagInfoWindow;
  }

  set infoWindow(value: boolean) {
    this.flagInfoWindow = value;
  }

  get flagNoSeleccionado(): boolean {
    return !this.seleccionados.some(seleccionado => seleccionado.id === this.barredor.id);
  }

  get flagHorario(): boolean {
    if (!this.horario) return false;
    const hoy = new Date();
    return this.horario.fechaInicio <= hoy && this.horario.fechaFin >= hoy;
  }

  get sinMovimiento(): BarredorSinMovimiento {
    const result: BarredorSinMovimiento = {
      quieto: true,
      tiempo: "00:00",
    };
    if (this.historico) return result;
    const pings = Array.from(this.pings);
    const ultimaPosicion = pings[pings.length - 1];
    if (ultimaPosicion) {
      result.quieto = ultimaPosicion.velocidad < 1;
      if (result.quieto) {
        pings.reverse();
        const ultimoMovimiento = pings.find((ping) => ping.velocidad >= 1);
        const msFin = (ultimoMovimiento ?? pings[0]).fecha.toDate().getTime();
        const ms = Math.abs(new Date().getTime() - msFin);
        result.tiempo = this.msToTime(ms, false);
      }
    }
    return result;
  }

  get icono(): string {
    if (this.flagHorario) {
      if (this.flagNoSeleccionado) {
        return this.circle;
      } else {
        if (this.sinMovimiento.quieto) {
          return require(`@/assets/svg/icons/fas/fas-street-view-circle${this.zoom > 15 ? "" : "-animated"}.svg`);
        } else {
          return require(`@/assets/svg/icons/fas/fas-running-circle${this.zoom > 15 ? "" : "-animated"}.svg`);
        }
      }
    } else {
      if (this.flagNoSeleccionado) {
        return this.circle;
      } else {
        return this.circleNumber;
      }
    }
  }

  get circleNumber(): string {
    const numero = this.barredor.numero;
    const fill = this.barredor.color;
    const template = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
      <circle r="90" cx="100" cy="100" fill="${fill}" stroke-width="20" stroke="#FFFFFF"/>
      <text x="50%" y="50%" font-family="Metropolis" text-anchor="middle" font-size="75" font-weight="bold" dominant-baseline="middle" fill="#FFFFFF">
        ${numero}
      </text>
    </svg>`;
    return "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(template);
  }

  get circle(): string {
    const fill = this.barredor.color;
    const template = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
      <circle r="90" cx="100" cy="100" fill="${fill}" stroke-width="20" stroke="#FFFFFF"/>
    </svg>`;
    return "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(template);
  }

  get size(): number {
    return this.flagNoSeleccionado ? 14 : 32;
  }

  get name(): string {
    return this.barredor.nombre;
  }

  get estado(): string {
    if (this.sinMovimiento.quieto) {
      const tiempo = this.sinMovimiento.tiempo;
      return this.$t("barredores.mapa.estado.0", {msg: tiempo}) as string;
    }
    return this.$t("barredores.mapa.estado.1") as string;
  }

  @Emit("click:seleccionar")
  onClickSeleccionar(): void {
    return;
  }

  seleccionar(): void {
    this.infoWindow = true;
    this.onClickSeleccionar();
  }
}
