
//decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
//tipos
import { PingRastreador } from "@/typings/store/plugins/easyFirestore/pingsRastreador";

@Component
export default class RutaMonitoreo extends Vue {
  @Prop() readonly pingsFiltrados!: Array<PingRastreador>;
  @Prop() readonly pingActual!: PingRastreador | null;

  get hora(): string {
    return this.pingActual?.fecha?.toDate()?.format("HH:mm:ss") ?? "";
  }

  get velocidad(): string {
    return this.pingActual?.velocidad?.toFixed(2) ?? "";
  }

  get distanciaUltimoPing(): string {
    const index = this.pingsFiltrados.findIndex(
      (ping) => ping.id === this.pingActual?.id
    );
    if (index >= 0) {
      const anterior = this.pingsFiltrados[index - 1];
      if (anterior) {
        const posicionActual = this.pingActual?.posicion;
        const posicionAnterior = anterior.posicion;
        if (posicionActual && posicionAnterior) {
          const distancia =
            this.distanceBetween(posicionActual, posicionAnterior) * 1000;
          return distancia.toFixed(2);
        }
      }
    }
    return "0";
  }

  get tiempoUltimoPing(): string {
    const index = this.pingsFiltrados.findIndex(
      (ping) => ping.id === this.pingActual?.id
    );
    if (index >= 0) {
      const anterior = this.pingsFiltrados[index - 1];
      if (anterior) {
        const fechaActual = this.pingActual?.fecha?.toDate();
        const fechaAnterior = anterior.fecha?.toDate();
        if (fechaActual && fechaAnterior) {
          const diferencia = fechaActual.getTime() - fechaAnterior.getTime();
          return this.msToTime(diferencia);
        }
      }
    }
    return "00:00:00";
  }

  get distanciaAcumulada(): string {
    const indexActual = this.pingsFiltrados.findIndex(
      (ping) => ping.id === this.pingActual?.id
    );
    const pings = this.pingsFiltrados.slice(0, indexActual + 2);
    let distancia = 0;
    let index = 0;
    for (const ping of pings) {
      const anterior = pings[index - 1];
      if (anterior) {
        distancia += this.distanceBetween(ping.posicion, anterior.posicion);
      }
      index++;
    }
    return distancia.toFixed(2);
  }

  get distanciaTotal(): string {
    let distanciaTotal = 0;
    let index = 0;
    for (const ping of this.pingsFiltrados) {
      const anterior = this.pingsFiltrados[index - 1];
      if (anterior) {
        distanciaTotal += this.distanceBetween(
          ping.posicion,
          anterior.posicion
        );
      }
      index++;
    }
    return distanciaTotal.toFixed(2);
  }

  get esUltimo(): boolean {
    const index = this.pingsFiltrados.findIndex(
      (ping) => ping.id === this.pingActual?.id
    );
    return index === this.pingsFiltrados.length - 1;
  }

  @Emit("click:eliminar-ruta-completa")
  onClickEliminarRutaCompleta(): void {
    return;
  }

  @Emit("click:eliminar-ping")
  onClickEliminarPing(): PingRastreador | null {
    return this.pingActual;
  }
}
