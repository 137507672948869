
//composition
import { defineComponent, computed } from "vue";
//i18n
import i18n from "@/plugins/i18n";
//tipos
import { Cliente } from "@/typings/store/plugins/easyFirestore/clientes";
import { Permisos } from "@/typings/store/modules/permisos";
//componentes
import SubirImagen from "@/components/SubirImagen.vue";
import InformationText from "@/components/custom/InformationText.vue";
import ActionButton from "@/components/custom/ActionButton.vue";

export default defineComponent({
  name: "DetalleCliente",
  components: {
    ActionButton,
    InformationText,
    SubirImagen,
  },
  emits: ["click:editar", "click:eliminar"],
  props: {
    cliente: {
      type: Object as () => Cliente,
      required: true,
    },
    permisos: {
      type: Object as () => Permisos,
      required: true,
    },
  },
  setup(props, ctx) {
    const noValueMessage = i18n.t("clientes.detalle.no-value-message") as string;

    const mostrarBotonEditar = props.permisos.clientes !== "ver";
    const mostrarBotonEliminar = props.permisos.clientes === "eliminar";

    const id = computed(() => props.cliente.id || noValueMessage);
    const nombre = computed(() => props.cliente.nombre || noValueMessage);
    const rut = computed(() => props.cliente.rut || noValueMessage);
    const tipo = computed(() => props.cliente.tipo || noValueMessage);
    const giro = computed(() => props.cliente.giro || noValueMessage);
    const ciudad = computed(() => props.cliente.ciudad || noValueMessage);
    const direccion = computed(() => props.cliente.direccion || noValueMessage);
    const contacto = computed(() => props.cliente.contacto || noValueMessage);
    const correo = computed(() => props.cliente.correo || noValueMessage);
    const estado = computed(() => props.cliente.estado || noValueMessage);

    const onClickEditar = () => {
      ctx.emit("click:editar");
    };

    const onClickEliminar = () => {
      ctx.emit("click:eliminar");
    };

    return {
      mostrarBotonEditar,
      mostrarBotonEliminar,
      id,
      nombre,
      rut,
      tipo,
      giro,
      ciudad,
      direccion,
      contacto,
      correo,
      estado,
      onClickEditar,
      onClickEliminar,
    };
  },
});
