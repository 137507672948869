
// Vue
import {defineComponent, onUnmounted, ref} from "vue";

// Tipos
import {Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";
import {Empresa} from "@/typings/store/plugins/easyFirestore/empresas";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "UserInformation",
  components: {
    SvgIcon,
  },
  props: {
    usuario: {
      required: true,
      type: Object as () => Usuario,
    },
    empresa: {
      required: true,
      type: Object as () => Empresa,
    },
  },
  setup() {
    //Data

    const opened = ref(false);

    const userInformationMenu = ref<HTMLDivElement | null>(null);

    // Methods

    const handleClick = (e: MouseEvent) => {
      if (opened.value && userInformationMenu.value && !userInformationMenu.value.contains(e.target as Node)) {
        opened.value = false;
      }
    };

    window.addEventListener('click', handleClick);


    onUnmounted(() => {
      window.removeEventListener('click', handleClick);
    });

    return {
      // Data
      opened,
      userInformationMenu,
    };
  },
});
