
//decoradores
import { Component, Emit, Vue } from "vue-property-decorator";

@Component
export default class DialogoSinFotos extends Vue {
  @Emit("click:cerrar")
  onClickCerrar(): void {
    return;
  }
}
