
// Vue
import {computed, defineComponent} from "vue";

//helpers
import routeGuard from "@/helpers/routeGuard";

// Composables
import {useUsuario} from "@/composables/usuario";
import {useRoute, useRouter} from "vue-router/composables";

// Vuetify
import vuetify from "@/plugins/vuetify";

// Tipos
import {Ruta} from "@/typings/store/modules/rutas";

// Componentes
import BotonHome from "@/components/home/BotonHome.vue";

export default defineComponent({
  name: "VistaHome",
  components: {
    BotonHome,
  },
  beforeRouteEnter(_to,_from,next){
    next(routeGuard);
  }, 
  created(): void {
    if (!routeGuard()) {
      return;
    }
  },
  setup() {
    
    // Composables
    
    const route = useRoute();
    const router = useRouter();
    const {getRutas} = useUsuario();

    // Computed

    const rutas = computed(() => getRutas.value.filter(ruta => {
      const mobile = vuetify.framework.breakpoint.mdAndDown;
      return mobile ? ruta.mobile : ruta.desktop;
    }));

    // Methods

    const onRouteClick = (ruta: Ruta) => {
      if (ruta.to?.name && route.name !== ruta.to.name) {
        router.push(ruta.to);
      }
    };

    return {
      // Computed
      rutas,
      // Methods
      onRouteClick,
    };
  },
});
