
//decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
//tipos
import { Posicion } from "@/typings/store/plugins/easyFirestore/pingsRastreador";
import { RutaBarredor } from "@/typings/store/plugins/easyFirestore/rutasBarredor";

@Component
export default class InformacionRutaBarredor extends Vue {
  @Prop() readonly rutaBarredor!: RutaBarredor;

  get nombre(): string {
    return this.rutaBarredor.nombre;
  }

  get color(): string {
    return this.rutaBarredor.color;
  }

  get path(): Array<Posicion> {
    return this.rutaBarredor.posiciones;
  }

  get ultima(): Posicion {
    return this.path[this.path.length - 1];
  }

  @Emit("click:cerrar")
  onClickCerrar(): void {
    return;
  }
}
