
// decoradores
import { Component, Vue, Prop } from "vue-property-decorator";
// tipos
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";
//componentes
import SubirImagen from "@/components/SubirImagen.vue";

@Component({
  components: {
    SubirImagen,
  },
})
export default class InfoUsuario extends Vue {
  @Prop() readonly usuario!: Usuario | null;

  get foto(): string {
    return this.usuario?.foto?.url || "";
  }

  get nombres(): string {
    return (
      this.usuario?.nombres || (this.$t("users.info.item.no-data") as string)
    );
  }

  get apellidos(): string {
    return (
      this.usuario?.apellidos || (this.$t("users.info.item.no-data") as string)
    );
  }

  get rut(): string {
    return this.usuario?.rut || (this.$t("users.info.item.no-data") as string);
  }

  get cargo(): string {
    return (
      this.usuario?.cargo || (this.$t("users.info.item.no-data") as string)
    );
  }

  get correo(): string {
    return (
      this.usuario?.correo || (this.$t("users.info.item.no-data") as string)
    );
  }

  get telefono(): string {
    return (
      this.usuario?.telefono || (this.$t("users.info.item.no-data") as string)
    );
  }

  get cliente(): string {
    return (
      this.usuario?.cliente?.nombre ||
      (this.$t("users.info.item.no-data") as string)
    );
  }

  get servicios(): string {
    const items = 1;
    const servicios = this.usuario?.servicios?.map((servicio, index, servicios) => {
        if (index < items) {
          return servicio.nombre;
        } else if (index === items) {
          const otros = servicios.length - index;
          if (otros === 1) {
            return `${otros} ${this.$t("users.info.item.services.other")}`;
          } else {
            return `${otros} ${this.$t("users.info.item.services.others")}`;
          }
        } else {
          return "";
        }
    }).filter((servicio) => servicio !== "").join(", ");
    return servicios || (this.$t("users.info.item.no-data") as string);
  }

  get tipo(): string {
    return this.usuario?.tipo || (this.$t("users.info.item.no-data") as string);
  }
}
