
// vue
import { defineComponent, computed, ref, reactive } from "vue";
// composables
import { useI18n } from "@/composables/i18n";
//tipos
import {
  Servicio,
  TipoServicio,
} from "@/typings/store/plugins/easyFirestore/servicios";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
// validaciones
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default defineComponent({
  components: {
    SvgIcon,
  },
  props: {
    servicio: {
      default: null,
      type: Object as () => Servicio | null,
    },
    cargando: {
      default: false,
      type: Boolean,
    },
  },
  created(): void {
    const servicio = this.servicio;
    if (servicio?.id) {
      this.id = servicio.id;
      this.nombre = servicio.nombre ?? "";
      this.tipo = servicio.tipo ?? null;
      this.descripcion = servicio.descripcion ?? "";
    }
  },
  emits: ["click:guardar"],
  setup(props, ctx) {
    const { t } = useI18n();

    const id = ref<string | null>(null);
    const nombre = ref("");
    const tipo = ref<TipoServicio | null>(null);
    const descripcion = ref("");
    const flag = ref(true);

    const tipos = computed((): Array<{ text: string; value: TipoServicio }> => {
      return [
        {
          text: t("servicios.formulario.tipo.values.0") as string,
          value: "Egreso",
        },
        {
          text: t("servicios.formulario.tipo.values.1") as string,
          value: "Ingreso",
        },
      ];
    });

    const titulo = computed((): string => {
      const index = props.servicio ? 1 : 0;
      return t(`servicios.formulario.title.${index}`) as string;
    });

    const textoBoton = computed((): string => {
      const index = props.servicio ? 0 : 1;
      return t(`servicios.formulario.boton.text.${index}`) as string;
    });

    const state = reactive({
      nombre,
      tipo,
    });

    const rules = {
      nombre: { required },
      tipo: { required },
    };

    const v$ = useVuelidate(rules, state);

    const nombreErrors = computed((): Array<string> => {
      const errors: Array<string> = [];
      if (!v$.value.nombre.$dirty) return errors;
      if (v$.value.nombre.required.$invalid)
        errors.push(
          t("servicios.formulario.nombre.error-messages[0]") as string
        );
      if (nombre.value.length > 20)
        errors.push(
          t("servicios.formulario.nombre.error-messages[1]") as string
        );
      return errors;
    });

    const tipoErrors = computed((): Array<string> => {
      const errors: Array<string> = [];
      if (!v$.value.tipo.$dirty) return errors;
      if (v$.value.tipo.required.$invalid)
        errors.push(t("servicios.formulario.tipo.error-message") as string);
      return errors;
    });

    const crearDisabled = computed((): boolean => {
      if (props.servicio) return false;
      return !nombre.value || !tipo.value || nombreErrors.value.length > 0;
    });

    const guardar = (): void => {
      v$.value.$touch();
      if (v$.value.$invalid || !tipo.value) {
        return;
      }
      flag.value = false;
      const data: Servicio = {
        nombre: nombre.value.toLowerCase(),
        tipo: tipo.value,
        descripcion: descripcion.value.toLowerCase(),
      };
      if (props.servicio) data.id = props.servicio.id;

      ctx.emit("click:guardar", data);
    };

    return {
      t,
      v$,
      id,
      nombre,
      tipo,
      descripcion,
      flag,
      tipos,
      titulo,
      textoBoton,
      nombreErrors,
      tipoErrors,
      crearDisabled,
      guardar,
    };
  },
});
