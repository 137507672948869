
//composition
import { computed, defineComponent } from "vue";
//i18n
import i18n from "@/plugins/i18n";
//tipos
import { FiltrosPlan } from "@/typings/components/planes/filtros";
import {
  EstadoPlan,
  Plan,
  TipoPagoPlan,
  TipoPlan,
} from "@/typings/store/plugins/easyFirestore/planes";
//componentes
import SearchTextField from "@/components/custom/SearchTextField.vue";
import FilterSelect from "@/components/custom/FilterSelect.vue";
import AddButton from "@/components/custom/AddButton.vue";

export default defineComponent({
  name: "FiltrosPlanes",
  components: {
    AddButton,
    FilterSelect,
    SearchTextField,
  },
  props: {
    value: {
      type: Object as () => FiltrosPlan,
      required: true,
    },
    plan: {
      type: Object as () => Plan | null,
    },
  },
  emits: ["click:agregar", "input"],
  setup(props, ctx) {
    const buscar = computed({
      get() {
        return props.value.buscar;
      },
      set(value: string) {
        const filtros = props.value;
        filtros.buscar = value;
        ctx.emit("input", props.value);
      },
    });

    const estado = computed({
      get() {
        return props.value.estado;
      },
      set(value: EstadoPlan | null) {
        const filtros = props.value;
        filtros.estado = value;
        ctx.emit("input", props.value);
      },
    });

    const estados = computed(() => {
      return [
        {
          text: i18n.t("planes.filtros.estado.values.0"),
          value: "habilitado",
        },
        {
          text: i18n.t("planes.filtros.estado.values.1"),
          value: "deshabilitado",
        },
      ];
    });

    const tipo = computed({
      get() {
        return props.value.tipo;
      },
      set(value: TipoPlan | null) {
        const filtros = props.value;
        filtros.tipo = value;
        ctx.emit("input", props.value);
      },
    });

    const tipos = computed(() => {
      return [
        {
          text: i18n.t("planes.filtros.tipo.values.0"),
          value: "plan a",
        },
        {
          text: i18n.t("planes.filtros.tipo.values.1"),
          value: "plan b",
        },
        {
          text: i18n.t("planes.filtros.tipo.values.2"),
          value: "plan c",
        },
        {
          text: i18n.t("planes.filtros.tipo.values.3"),
          value: "plan d",
        },
      ];
    });

    const tipoPago = computed({
      get() {
        return props.value.tipoPago;
      },
      set(value: TipoPagoPlan | null) {
        const filtros = props.value;
        filtros.tipoPago = value;
        ctx.emit("input", props.value);
      },
    });

    const tiposPago = computed(() => {
      return [
        {
          text: i18n.t("planes.filtros.tipo-pago.values.0"),
          value: "mensual",
        },
        {
          text: i18n.t("planes.filtros.tipo-pago.values.1"),
          value: "anual",
        },
      ];
    });

    const onClickAgregar = () => {
      ctx.emit("click:agregar");
    };

    return {
      buscar,
      estado,
      estados,
      tipo,
      tipos,
      tipoPago,
      tiposPago,
      onClickAgregar,
    };
  },
});
