
import { Component, Vue } from "vue-property-decorator";
import { PageFlip } from "page-flip";

@Component
export default class Revista extends Vue {
  currentPage = 1;
  pageCount = 1;
  pageFlip: PageFlip | null = null;
  horizontal = false;
  fullscreen = false;

  created(): void {
    document.addEventListener("keydown", this.keypressHandler);
    document.addEventListener("fullscreenchange", this.fullscreenChangeHandler);
  }

  async mounted(): Promise<void> {
    let width = this.mobile ? this.width : this.width * 0.375;
    let height = width * 1.125;
    let usePortrait = this.mobile;
    if (height > this.height - 28) {
      usePortrait = false;
      height = this.height - 28;
      width = height / 1.125;
      this.horizontal = true;
    }
    this.pageFlip = new PageFlip(this.flipbook, {
      width: width,
      height: height,
      size: "fixed",
      usePortrait: usePortrait,
      flippingTime: 750,
      showCover: true,
      maxShadowOpacity: 0.75,
    });
    this.pageFlip?.loadFromHTML(document.querySelectorAll(".imagen"));
    this.pageFlip?.on("flip", (event) => {
      this.currentPage = event.data + 1;
    });
    this.pageCount = this.pageFlip.getPageCount();
  }

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mobile;
  }

  get width(): number {
    return this.$vuetify.breakpoint.width;
  }

  get height(): number {
    return this.$vuetify.breakpoint.height;
  }

  get flipbook(): HTMLElement {
    return this.$refs.flipbook as HTMLElement;
  }

  get parentContainer(): HTMLElement {
    return this.$refs.parentContainer as HTMLElement;
  }

  changeFullscreen(): void {
    if (this.fullscreen) {
      document.exitFullscreen();
      this.fullscreen = false;
    } else {
      this.parentContainer.requestFullscreen();
      this.fullscreen = true;
    }
  }

  fullscreenChangeHandler(): void {
    this.fullscreen = document.fullscreenElement !== null;
  }

  nextPage(): void {
    this.pageFlip?.flipNext("top");
  }

  previousPage(): void {
    this.pageFlip?.flipPrev("top");
  }

  keypressHandler(event: KeyboardEvent): void {
    if (event.key === "ArrowRight") {
      this.nextPage();
    } else if (event.key === "ArrowLeft") {
      this.previousPage();
    } else if (event.key.toLowerCase() === "f") {
      this.changeFullscreen();
    }
  }

  destroyed(): void {
    document.removeEventListener("keypress", this.keypressHandler);
    document.removeEventListener(
      "fullscreenchange",
      this.fullscreenChangeHandler
    );
    this.pageFlip?.destroy();
  }
}
