
//decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
//tipos
import { Permisos } from "@/typings/store/modules/permisos";
import { Servicio } from "@/typings/store/plugins/easyFirestore/servicios";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: {
    SvgIcon,
  },
})
export default class DetalleServicio extends Vue {
  @Prop() readonly servicio!: Servicio;
  @Prop() readonly permisos!: Permisos;

  get mostrarBotonEditar(): boolean {
    return this.permisos.servicios !== "ver";
  }

  get mostrarBotonEliminar(): boolean {
    return this.permisos.servicios === "eliminar";
  }

  get noValueMessage(): string {
    return this.$t("servicios.detalle.no-value-message") as string;
  }

  get nombre(): string {
    return this.servicio.nombre || this.noValueMessage;
  }

  get tipo(): string {
    return this.servicio.tipo || this.noValueMessage;
  }

  get descripcion(): string {
    return this.servicio.descripcion || this.noValueMessage;
  }

  @Emit("click:editar")
  onClickEditar(): void {
    return;
  }

  @Emit("click:eliminar")
  onClickEliminar(): void {
    return;
  }
}
