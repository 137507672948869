
//composition
import {defineComponent} from "vue";

export default defineComponent({
  name: "TableItem",
  props: {
    value: {
      type: String,
      required: true
    },
    classes: {
      type: String,
      default: ""
    }
  }
})
