
// vue
import { defineComponent, computed, ref } from "vue";
// composables
import { useI18n } from "@/composables/i18n";
//tipos
import { Chofer } from "@/typings/store/plugins/easyFirestore/choferes";
import {
  Pesaje,
  PesajeData,
} from "@/typings/store/plugins/easyFirestore/pesajes";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
// validaciones
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default defineComponent({
  components: {
    SvgIcon,
  },
  props: {
    value: {
      required: true,
      type: Object as () => PesajeData,
    },
    pesaje: {
      default: null,
      type: Object as () => Pesaje | null,
    },
    arrayChoferes: {
      type: Array as () => Chofer[],
    },
  },
  created(): void {
    if (this.pesaje?.estado === "salida") {
      this.tab = 1;
    } else {
      this.tab = 0;
    }
  },
  emits: ["click:guardar", "input"],
  setup(props, ctx) {
    const { t } = useI18n();

    const editarEntrada = ref(true);
    const tab = ref(0);
    const menuFechaEntrada = ref(false);
    const menuFechaSalida = ref(false);

    const pesajeData = computed({
      get: () => props.value,
      set: (value: PesajeData) => ctx.emit("input", value),
    });

    const disabledTabSalida = computed((): boolean => {
      const pesoValido =
        !!props.pesaje?.entrada?.peso || !!pesajeData.value.pesoEntrada;
      const choferValido =
        !!props.pesaje?.entrada?.chofer || !!pesajeData.value.choferEntrada;
      const fechaValida =
        !!props.pesaje?.entrada?.fecha || !!pesajeData.value.fechaEntrada;
      return !pesoValido || !choferValido || !fechaValida;
    });

    const fechaEntrada = computed({
      get: () => {
        const fecha =
          pesajeData.value.fechaEntrada || props.pesaje?.entrada?.fecha;
        return (fecha ? fecha.toDate() : new Date()).format("YYYY-MM-DD");
      },
      set: (value) => {
        const fecha = value.toDate();
        const hoy = new Date();
        fecha.setHours(
          hoy.getHours(),
          hoy.getMinutes(),
          hoy.getSeconds(),
          hoy.getMilliseconds()
        );
        const nuevo = Object.assign({}, pesajeData.value, {
          fechaEntrada: fecha.toISOString(),
        });
        pesajeData.value = nuevo;
      },
    });

    const horaEntrada = computed({
      get: () => {
        const fecha =
          pesajeData.value.fechaEntrada || props.pesaje?.entrada?.fecha;
        return (fecha ? fecha.toDate() : new Date()).format("HH:mm");
      },
      set: (value) => {
        const [hora, minutos] = value.split(":");
        if (hora && hora.length === 2 && minutos && minutos.length === 2) {
          const fechaRaw =
            pesajeData.value.fechaEntrada ??
            (props.pesaje?.entrada?.fecha || "");
          const fecha = fechaRaw?.toDate();
          fecha.setHours(parseInt(hora, 10));
          fecha.setMinutes(parseInt(minutos, 10));
          const nuevo = Object.assign({}, pesajeData.value, {
            fechaEntrada: fecha.toISOString(),
          });
          pesajeData.value = nuevo;
        }
      },
    });

    const pesoEntrada = computed({
      get: () => {
        const peso =
          pesajeData.value.pesoEntrada ?? (props.pesaje?.entrada?.peso || 0);
        return peso;
      },
      set: (value) => {
        const nuevo = Object.assign({}, pesajeData.value, {
          pesoEntrada: value,
        });
        pesajeData.value = nuevo;
      },
    });

    const choferEntrada = computed({
      get: () => {
        const chofer =
          pesajeData.value.choferEntrada ||
          props.pesaje?.entrada?.chofer ||
          null;
        return chofer;
      },
      set: (value) => {
        let chofer: Chofer | null;
        if (typeof value === "string") {
          chofer = {
            nombre: (value as string).toLowerCase(),
            rut: "",
            cargo: "",
            fechaNacimiento: "",
            fechaContratacion: "",
            contacto: "",
            estado: "habilitado",
            foto: null,
          };
        } else {
          chofer = Object.assign({}, value);
        }
        const nuevo = Object.assign({}, pesajeData.value, {
          choferEntrada: chofer,
        });
        pesajeData.value = nuevo;
      },
    });

    const observacionEntrada = computed({
      get: () => {
        const observacion =
          pesajeData.value.observacionEntrada ||
          props.pesaje?.entrada?.observacion ||
          "";
        return observacion;
      },
      set: (value) => {
        const nuevo = Object.assign({}, pesajeData.value, {
          observacionEntrada: value,
        });
        pesajeData.value = nuevo;
      },
    });

    const fechaSalida = computed({
      get: () => {
        const fecha =
          pesajeData.value.fechaSalida || props.pesaje?.salida?.fecha;
        return fecha ? fecha.toDate().format("YYYY-MM-DD") : "";
      },
      set: (value) => {
        const fecha = value.toDate();
        const nuevo = Object.assign({}, pesajeData.value, {
          fechaSalida: fecha.toISOString(),
        });
        pesajeData.value = nuevo;
      },
    });

    const horaSalida = computed({
      get: () => {
        const fecha =
          pesajeData.value.fechaSalida || props.pesaje?.salida?.fecha;
        return fecha ? fecha.toDate().format("HH:mm") : "";
      },
      set: (value) => {
        const [hora, minutos] = value.split(":");
        if (hora && hora.length === 2 && minutos && minutos.length === 2) {
          const fechaRaw =
            pesajeData.value.fechaSalida ?? (props.pesaje?.salida?.fecha || "");
          const fecha = fechaRaw.toDate();
          fecha.setHours(parseInt(hora, 10));
          fecha.setMinutes(parseInt(minutos, 10));
          const nuevo = Object.assign({}, pesajeData.value, {
            fechaSalida: fecha.toISOString(),
          });
          pesajeData.value = nuevo;
        }
      },
    });

    const pesoSalida = computed({
      get: () => {
        const peso =
          pesajeData.value.pesoSalida ?? (props.pesaje?.salida?.peso || 0);
        return peso;
      },
      set: (value) => {
        const peso = value || 0;
        const nuevo = Object.assign({}, pesajeData.value, {
          pesoSalida: peso,
        });
        pesajeData.value = nuevo;
      },
    });

    const choferSalida = computed({
      get: () => {
        const chofer =
          pesajeData.value.choferSalida || props.pesaje?.salida?.chofer || null;
        return chofer;
      },
      set: (value) => {
        let chofer: Chofer | null;
        if (typeof value == "string") {
          chofer = {
            nombre: (value as string).toLowerCase(),
            rut: "",
            cargo: "",
            fechaNacimiento: "",
            fechaContratacion: "",
            contacto: "",
            estado: "habilitado",
            foto: null,
          };
        } else {
          chofer = Object.assign({}, value);
        }
        const nuevo = Object.assign({}, pesajeData.value, {
          choferSalida: chofer,
        });
        pesajeData.value = nuevo;
      },
    });

    const observacionSalida = computed({
      get: () => {
        const observacion =
          pesajeData.value.observacionSalida ||
          props.pesaje?.salida?.observacion ||
          "";
        return observacion;
      },
      set: (value) => {
        const nuevo = Object.assign({}, pesajeData.value, {
          observacionSalida: value,
        });
        pesajeData.value = nuevo;
      },
    });

    const state = {
      fechaEntrada,
      horaEntrada,
      pesoEntrada,
      choferEntrada,
      fechaSalida,
      horaSalida,
      pesoSalida,
      choferSalida,
    };

    const rules = {
      fechaEntrada: { required },
      horaEntrada: { required },
      pesoEntrada: { required },
      choferEntrada: { required },
      fechaSalida: { required },
      horaSalida: { required },
      pesoSalida: { required },
      choferSalida: { required },
    };

    const v$ = useVuelidate(rules, state, { $scope: false });

    const erroresFechaEntrada = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.fechaEntrada.$dirty) return errores;
      if (v$.value.fechaEntrada.required.$invalid) {
        const error = t(
          "pesajes.formulario.tab.entrada.fecha.error-message"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresHoraEntrada = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.horaEntrada.$dirty) return errores;
      if (v$.value.horaEntrada.required.$invalid) {
        const error = t(
          "pesajes.formulario.tab.entrada.hora.error-message"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresPesoEntrada = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.pesoEntrada.$dirty) return errores;
      if (v$.value.pesoEntrada.required.$invalid) {
        const error = t(
          "pesajes.formulario.tab.entrada.peso.error-message"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresChoferEntrada = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.choferEntrada.$dirty) return errores;
      if (v$.value.choferEntrada.required.$invalid) {
        const error = t(
          "pesajes.formulario.tab.entrada.chofer.error-message"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresFechaSalida = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.fechaSalida.$dirty) return errores;
      if (v$.value.fechaSalida.required.$invalid) {
        const error = t(
          "pesajes.formulario.tab.salida.fecha.error-message"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresHoraSalida = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.horaSalida.$dirty) return errores;
      if (v$.value.horaSalida.required.$invalid) {
        const error = t(
          "pesajes.formulario.tab.salida.hora.error-message"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresPesoSalida = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.pesoSalida.$dirty) return errores;
      if (v$.value.pesoSalida.required.$invalid) {
        const error = t(
          "pesajes.formulario.tab.salida.peso.error-message"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresChoferSalida = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.choferSalida.$dirty) return errores;
      if (v$.value.choferSalida.required.$invalid) {
        const error = t(
          "pesajes.formulario.tab.salida.chofer.error-message"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const onGuardar = (): void => {
      ctx.emit("click:guardar");
    };

    const guardar = (): void => {
      v$.value.$touch();
      onGuardar();
    };

    return {
      t,
      v$,
      editarEntrada,
      tab,
      menuFechaEntrada,
      menuFechaSalida,
      disabledTabSalida,
      fechaEntrada,
      horaEntrada,
      pesoEntrada,
      choferEntrada,
      observacionEntrada,
      fechaSalida,
      horaSalida,
      pesoSalida,
      choferSalida,
      observacionSalida,
      erroresFechaEntrada,
      erroresHoraEntrada,
      erroresPesoEntrada,
      erroresChoferEntrada,
      erroresFechaSalida,
      erroresHoraSalida,
      erroresPesoSalida,
      erroresChoferSalida,
      guardar,
    };
  },
});
