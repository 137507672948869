
//decoradores
import { Component, Prop, Vue } from "vue-property-decorator";
//tipos
import { Item } from "@/typings/components/reportes/tabla";

@Component
export default class ItemCirculo extends Vue {
  @Prop() readonly item!: Item;

  get color(): string {
    const { pesaje } = this.item;
    return pesaje.estado === "finalizado" ? "primary" : "text";
  }

  get texto(): string {
    const { pesaje } = this.item;
    const index = pesaje.estado === "finalizado" ? 0 : 1;
    return this.$t("reportes.tabla.estado." + index) as string;
  }
}
