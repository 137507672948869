
// Vue
import { defineComponent } from "vue";

// i18n
import i18n from "@/plugins/i18n";

export default defineComponent({
  name: "ConfirmLogout",
  emits: ["click:no", "click:yes"],
  setup(_props, ctx) {
    // Methods

    const t = (key: string) => i18n.t(`app-core.confirmar-logout.${key}`) as string;

    // Emits

    const onClickYes = () => {
      ctx.emit("click:yes");
    };

    const onClickNo = () => {
      ctx.emit("click:no");
    };

    return {
      // Methods
      t,
      // Emits
      onClickNo,
      onClickYes,
    };
  },
});
