
//decoradores
import { Component, Prop, VModel, Vue, Watch } from "vue-property-decorator";
//tipos
import { Cliente } from "@/typings/store/plugins/easyFirestore/clientes";
import { Vehiculo } from "@/typings/store/plugins/easyFirestore/vehiculos";
import { Servicio } from "@/typings/store/plugins/easyFirestore/servicios";
import { FiltrosPesaje } from "@/typings/components/pesajes/filtros";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: {
    SvgIcon,
  },
})
export default class FiltrosPesajes extends Vue {
  @VModel() filtros!: FiltrosPesaje;
  @Prop() readonly vehiculos!: Array<Vehiculo>;
  @Prop() readonly servicios!: Array<Servicio>;
  @Prop() readonly clientes!: Array<Cliente>;

  menu = false;

  get search(): string {
    return this.filtros.search;
  }

  set search(value: string) {
    this.filtros.search = value;
  }

  get fechas(): Array<string> {
    return this.filtros.fechas;
  }

  set fechas(value: Array<string>) {
    this.filtros.fechas = value;
  }

  get fechasMostrar(): string {
    return this.fechas
      .map((fecha) => fecha.toDate().format("DD/MM/YY"))
      .join(" ~ ");
  }

  get filtroVehiculo(): Vehiculo | null {
    return this.filtros.vehiculo;
  }

  set filtroVehiculo(value: Vehiculo | null) {
    this.filtros.vehiculo = value;
  }

  get filtroServicios(): Array<Servicio> {
    return this.filtros.servicios;
  }

  set filtroServicios(value: Array<Servicio>) {
    this.filtros.servicios = value;
  }

  get filtroClientes(): Array<Cliente> {
    return this.filtros.clientes;
  }

  set filtroClientes(value: Array<Cliente>) {
    this.filtros.clientes = value;
  }

  get filtroErrores(): boolean {
    return this.filtros.errores;
  }

  set filtroErrores(value: boolean) {
    this.filtros.errores = value;
  }

  get textoFiltroClientes(): string {
    const length = this.filtroClientes.length;
    if (length === 1) {
      return this.filtroClientes[0].nombre;
    } else {
      return this.$tc("pesajes.filtro.clientes.selected", length);
    }
  }

  get textoFiltroServicios(): string {
    const length = this.filtroServicios.length;
    if (length === 1) {
      return this.filtroServicios[0].nombre;
    } else {
      return this.$tc("pesajes.filtro.servicios.selected", length);
    }
  }

  @Watch("fechas")
  onFechasChange(): void {
    const [fechaInicio, fechaFin] = this.fechas.map((fecha) => fecha.toDate());
    if (fechaInicio && fechaFin && fechaInicio > fechaFin) {
      this.fechas = this.fechas.reverse();
    }
  }
}
