
//decoradores
import { Component, Vue } from "vue-property-decorator";

@Component
export default class BannerPagina extends Vue {
  get isMobile(): boolean {
    return this.$vuetify.breakpoint.mobile;
  }
}
