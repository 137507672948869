
// Vue
import {defineComponent} from "vue";

// Tipos
import {Barredor} from "@/typings/store/plugins/easyFirestore/barredores";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "ItemDialogo",
  components: {
    SvgIcon,
  },
  props: {
    color: {
      type: String,
      require: true,
    },
    barredor: {
      required: true,
      type: Object as () => Barredor,
    },
  },
});
