
//decoradores
import { Vue, Component } from "vue-property-decorator";
//vuex
import { mapActions, mapGetters } from "vuex";
// Helpers
import routeGuard from "@/helpers/routeGuard";
//tipos
import { FiltrosServicio } from "@/typings/components/servicios/filtros";
import {
  Servicio,
  Servicios,
} from "@/typings/store/plugins/easyFirestore/servicios";
//componentes
import SuccessDialog from "@/components/custom/SuccessDialog.vue";
import TablaServicios from "@/components/servicios/Tabla.vue";
import DetalleServicio from "@/components/servicios/Detalle.vue";
import FiltrosServicios from "@/components/servicios/Filtros.vue";
import FormularioServicio from "@/components/servicios/FormularioServicio.vue";
import ConfirmationDialog from "@/components/custom/ConfirmationDialog.vue";

@Component({
  computed: mapGetters({
    getServicios: "servicios/get",
    getRutasUsuario: "usuario/getRutas",
    getPermisosUsuario: "usuario/getPermisos",
  }),
  methods: mapActions({
    setServicio: "servicios/set",
    deleteServicio: "servicios/delete",
  }),
  beforeRouteEnter(_to, _from, next) {
    next(routeGuard);
  },
  components: {
    SuccessDialog,
    TablaServicios,
    DetalleServicio,
    FiltrosServicios,
    FormularioServicio,
    ConfirmationDialog,
  },
})
export default class VistaServicios extends Vue {
  created(): void {
    if (!routeGuard()) {
      return;
    }
  }
  servicio: Servicio | null = null;
  cargando = false;
  dialogoFormulario = false;
  dialogoInfo = false;
  dialogoConfirmacion: {
    model: boolean;
    texto: string;
    si: (() => void) | null;
    no: (() => void) | null;
  } = {
    model: false,
    texto: "",
    si: null,
    no: null,
  };
  dialogoExito = {
    model: false,
    mensaje: "",
    icono: "",
  };
  filtros: FiltrosServicio = {
    tipo: null,
    search: "",
  };

  get servicios(): Servicios {
    return this.getServicios;
  }

  get items(): Array<Servicio> {
    const servicios = Object.values(this.servicios).filter((servicio) =>
      this.filtros.tipo ? servicio.tipo === this.filtros.tipo : true
    );
    servicios.sort((a, b) => a.nombre.localeCompare(b.nombre));
    return servicios;
  }

  get codigos(): Array<string> {
    return [];
  }

  abrirNuevo(): void {
    this.servicio = null;
    this.dialogoFormulario = true;
    this.dialogoInfo = false;
  }

  clickAbrirInformacion(servicio: Servicio): void {
    if (this.dialogoFormulario && this.servicio) {
      this.abrirDialogoSinGuardar(servicio);
    } else {
      this.abrirInformacion(servicio);
    }
  }

  abrirDialogoSinGuardar(servicio: Servicio): void {
    this.dialogoConfirmacion = {
      model: true,
      texto: this.$t(
        "servicios.mensaje-confirmacion.message.save-changes"
      ) as string,
      si: () => {
        this.abrirInformacion(servicio);
        this.cerrarDialogoConfirmacion();
      },
      no: () => {
        this.cerrarDialogoConfirmacion();
      },
    };
  }

  abrirInformacion(servicio: Servicio): void {
    const data = this.servicios[servicio.id ?? ""];
    if (servicio.id === this.servicio?.id) {
      this.servicio = null;
      this.dialogoInfo = false;
    } else if (data) {
      this.servicio = data;
      this.dialogoInfo = true;
      this.dialogoFormulario = false;
    }
  }

  abrirEditar(): void {
    this.dialogoFormulario = true;
    this.dialogoInfo = false;
  }

  abrirDialogoEliminar(): void {
    this.dialogoConfirmacion = {
      model: true,
      texto: this.$t("servicios.mensaje-confirmacion.message.delete") as string,
      si: () => {
        this.clickEliminar();
      },
      no: () => {
        this.cerrarDialogoConfirmacion();
      },
    };
  }

  clickEliminar(): void {
    const servicio = this.servicio;
    if (servicio) {
      this.deleteServicio(servicio.id ?? "");
      this.servicio = null;
      this.dialogoInfo = false;
      this.cerrarDialogoConfirmacion();
    }
  }

  async clickGuardar(servicio: Servicio): Promise<void> {
    this.cargando = true;
    await this.setServicio(servicio);
    this.cargando = false;
    this.dialogoFormulario = false;
    if (this.servicio) {
      this.abrirDialogoExito(
        this.$t("servicios.dialogo-exito.message.save") as string,
        "ag-save"
      );
    } else {
      this.abrirDialogoExito(
        this.$t("servicios.dialogo-exito.message.create") as string,
        "servicios"
      );
    }
    this.servicio = null;
  }

  abrirDialogoExito(mensaje: string, icono: string): void {
    this.dialogoExito = {
      model: true,
      mensaje: mensaje,
      icono: icono,
    };
  }

  cerrarDialogoExito(): void {
    this.dialogoExito = {
      model: false,
      mensaje: "",
      icono: "",
    };
  }

  cerrarDialogoConfirmacion(): void {
    this.dialogoConfirmacion = {
      model: false,
      texto: "",
      si: null,
      no: null,
    };
  }
}
