
// composition-api
import { computed, defineComponent, reactive, ref } from "vue";

// helpers
import routeGuard from "@/helpers/routeGuard";
//pdfmake
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { ContentBase, TDocumentDefinitions } from "pdfmake/interfaces";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
//excel
import Exceljs from "exceljs";
//tipos
import { Chofer } from "@/typings/store/plugins/easyFirestore/choferes";
import { FiltrosReporte } from "@/typings/components/reportes/filtros";
import { Pesaje } from "@/typings/store/plugins/easyFirestore/pesajes";
import { Desecho } from "@/typings/store/plugins/easyFirestore/desechos";
import { Cliente } from "@/typings/store/plugins/easyFirestore/clientes";
import { Vehiculo } from "@/typings/store/plugins/easyFirestore/vehiculos";
import { Servicio } from "@/typings/store/plugins/easyFirestore/servicios";
// composables
import { useI18n } from "@/composables/i18n";
import { useKgM3 } from "@/composables/kgM3";
import { useMixins } from "@/composables/mixins";
import { useUsuario } from "@/composables/usuario";
import { usePesajes } from "@/composables/pesajes";
import { useUsuarios } from "@/composables/usuarios";
import { useDesechos } from "@/composables/desechos";
import { useClientes } from "@/composables/clientes";
import { useArchivos } from "@/composables/archivos";
import { useVehiculos } from "@/composables/vehiculos";
import { useServicios } from "@/composables/servicios";
// componentes
import DetallePesaje from "@/components/reportes/DetalleReportes.vue";
import TablaReportes from "@/components/reportes/Tabla.vue";
import GaleriaImagenes from "@/components/reportes/GaleriaImagenes.vue";
import FiltrosReportes from "@/components/reportes/Filtros.vue";
import ConfirmationDialog from "@/components/custom/ConfirmationDialog.vue";
import DialogoGenerandoReporte from "@/components/pesajes/DialogoGenerandoReporte.vue";
import { log } from "@/helpers/env";

type PrintAllDocuments = TDocumentDefinitions & { content: Array<ContentBase> };

type Item = Array<string | number>;

type ItemsPdf = Array<Item>;

interface DialogoConfirmacion {
  model: boolean;
  texto: string;
  si: (() => void) | null;
  no: (() => void) | null;
}

export default defineComponent({
  name: "VistaReportes",
  components: {
    DetallePesaje,
    TablaReportes,
    GaleriaImagenes,
    FiltrosReportes,
    ConfirmationDialog,
    DialogoGenerandoReporte,
  },
  beforeRouteEnter(_to, _from, next) {
    next(routeGuard);
  },
  created(): void {
    if (!routeGuard()) {
      return;
    }
    this.$eventBus.$on("click:editar-pesaje", this.mostrarInformacion);
    if (this.clienteUsuario != null)
      this.filtros.clientes.push(this.clienteUsuario);
    if (this.serviciosUsuario.length > 0) {
      this.filtros.servicios = this.serviciosUsuario;
    }
    const inicio = new Date();
    inicio.setDate(1);
    this.filtros.fechas = [
      inicio.format("YYYY-MM-DD"),
      new Date().format("YYYY-MM-DD"),
    ];
  },
  destroyed(): void {
    this.$eventBus.$off("click:editar-pesaje", this.mostrarInformacion);
  },
  setup() {
    if (log) console.time("wiews.VistaReportes.setup");
    const { t, tc } = useI18n();

    const _kgM3 = useKgM3();
    const _mixins = useMixins();
    const _usuario = useUsuario();
    const _pesajes = usePesajes();
    const _usuarios = useUsuarios();
    const _clientes = useClientes();
    const _vehiculos = useVehiculos();
    const _desechos = useDesechos();
    const _archivos = useArchivos();
    const _servicios = useServicios();

    const filtros = reactive<FiltrosReporte>({
      servicios: [],
      clientes: [],
      desecho: null,
      fechas: [],
      vehiculo: null,
    });

    const pesaje = ref<Pesaje | null>(null);

    const dialogoGenerandoReporte = reactive({
      model: false,
      progreso: 0,
      texto: "",
    });

    const mostrarGaleria = ref(false);

    const dialogoConfirmacion = reactive<DialogoConfirmacion>({
      model: false,
      texto: "",
      si: null,
      no: null,
    });

    const usuario = _usuario.get;

    const clienteUsuario = computed((): Cliente | null => {
      return usuario.value?.cliente ?? null;
    });

    const serviciosUsuario = computed((): Array<Servicio> => {
      return usuario.value?.servicios ?? [];
    });

    const pesajes = _pesajes.get;

    const arrayPesajes = _pesajes.getArray;

    const usuarios = _usuarios.get;

    const vehiculosOrdenados = computed((): Array<Vehiculo> => {
      _vehiculos.setOrdenarArray(true);
      return _vehiculos.getArrayHabilitados.value;
    });

    const clientesOrdenados = computed((): Array<Cliente> => {
      _clientes.setOrdenarArray(true);
      return _clientes.getArrayHabilitados.value;
    });

    const desechosOrdenados = computed((): Array<Desecho> => {
      _desechos.setOrdenarArray(true);
      return _desechos.getArray.value;
    });

    const archivos = _archivos.getArchivos;

    const arrayServicios = computed((): Array<Servicio> => {
      return _servicios.getArray.value;
    });

    const itemsServicios = computed((): Array<Servicio> => {
      return arrayServicios.value.filter((servicio) => {
        if (serviciosUsuario.value.length === 0) return true;
        return serviciosUsuario.value.some((item) => item.id === servicio.id);
      });
    });

    const serviciosOrdenados = computed((): Array<Servicio> => {
      const __servicios = itemsServicios.value.slice();
      __servicios.sort((a, b) => {
        if (a.nombre < b.nombre) return -1;
        if (a.nombre > b.nombre) return 1;
        return 0;
      });
      return __servicios;
    });

    const noValueMessage = t("reportes.no-value-message") as string;

    const fechaInicio = computed((): Date => {
      let fecha: Date | null = null;
      if (
        !filtros.fechas ||
        filtros.fechas.length === 0 ||
        !filtros.fechas[0]
      ) {
        fecha = new Date();
        fecha.setHours(0, 0, 0, 0);
        return fecha;
      }
      fecha = filtros.fechas[0].toDate();
      fecha.setHours(0, 0, 0, 0);
      return fecha;
    });

    const fechaFinal = computed((): Date => {
      let fecha: Date | null = null;
      if (!filtros.fechas || filtros.fechas.length < 2 || !filtros.fechas[1]) {
        if (filtros.fechas && filtros.fechas.length === 1) {
          fecha = filtros.fechas[0].toDate();
          fecha.setHours(23, 59, 59, 999);
          return fecha;
        }
        fecha = new Date();
        fecha.setHours(23, 59, 59, 999);
        return fecha;
      }
      const fechaString = filtros.fechas[1].replaceAll("-", "/");
      return new Date(fechaString + " 23:59:59");
    });

    const ordenarPesajes = (__pesajes: Pesaje[]) => {
      __pesajes.sort((p1, p2) => {
        const entradaDateP1: Date | null = p1.entrada.fecha
          ? p1.entrada.fecha.toDate()
          : null;
        const entradaDateP2: Date | null = p2.entrada.fecha
          ? p2.entrada.fecha.toDate()
          : null;
        if (!entradaDateP1) return -1;
        if (!entradaDateP2) return 1;
        return entradaDateP2.getTime() - entradaDateP1.getTime();
      });
      return __pesajes;
    };

    const datosFiltrados = computed((): Array<Pesaje> => {
      if (log) console.time("views.VistaReportes.datosFiltrados");
      const _fechaInicio = fechaInicio;
      const _fechaFin = fechaFinal;
      const __pesajes = arrayPesajes.value.filter((_pesaje) => {
        const filtroFecha = validarFecha(
          _pesaje,
          _fechaInicio.value,
          _fechaFin.value
        );
        const filtroVehiculo = filtros.vehiculo
          ? filtros.vehiculo.patente == _pesaje.vehiculo?.patente
          : true;
        const __clientes = filtros.clientes;
        const filtroCliente =
          __clientes && __clientes.length > 0
            ? __clientes.some((cliente) => validarCliente(_pesaje, cliente))
            : true;
        const filtroDesecho = filtros.desecho?.nombre
          ? _pesaje.desecho?.nombre &&
            _pesaje.desecho.nombre
              .toLowerCase()
              .includes(filtros.desecho.nombre.toLowerCase())
          : true;
        const __servicios = filtros.servicios;
        const filtroServicio =
          __servicios && __servicios.length > 0
            ? __servicios.some((servicio) => validarServicio(_pesaje, servicio))
            : true;
        const filtroEstado = _pesaje.estado !== "anulado";
        return (
          filtroFecha &&
          filtroVehiculo &&
          filtroCliente &&
          filtroDesecho &&
          filtroServicio &&
          filtroEstado
        );
      });
      ordenarPesajes(__pesajes);
      if (log) console.timeEnd("views.VistaReportes.datosFiltrados");
      return __pesajes;
    });

    const dateEntradaPesaje = (_pesaje: Pesaje) => {
      return _pesaje.entrada.fecha ? _pesaje.entrada.fecha.toDate() : null;
    };

    const dateSalidaPesaje = (_pesaje: Pesaje) => {
      return _pesaje.salida.fecha ? _pesaje.salida.fecha.toDate() : null;
    };

    const tipoRutaPesaje = (_pesaje: Pesaje) => {
      let tipoRuta = _pesaje.ruta?.tipo?.toLowerCase();

      tipoRuta =
        tipoRuta === "rural" || tipoRuta === "urbano"
          ? tipoRuta?.capitalizeFirstLetter()
          : noValueMessage;

      return tipoRuta;
    };

    const pesoNetoPesaje = (_pesaje: Pesaje) => {
      const pesoEntrada = _pesaje.entrada.peso;
      const pesoSalida = _pesaje.salida?.peso ?? 0;

      let pesoNeto = 0;

      if (pesoEntrada > 0 && pesoSalida > 0) {
        pesoNeto =
          _pesaje.servicio?.nombre?.toLowerCase() === "venta"
            ? pesoSalida - pesoEntrada
            : pesoEntrada - pesoSalida;
      }

      return pesoNeto;
    };

    const kgM3Pesaje = async (_pesaje: Pesaje) => {
      const entradaDate = dateEntradaPesaje(_pesaje);

      const fechaEntrada = entradaDate ?? new Date();
      fechaEntrada?.setHours(0, 0, 0, 0);

      let kgM3: number | null = null;

      if (_pesaje.cliente?.id) {
        const valores = _kgM3.get.value[_pesaje.cliente.id];
        if (valores) kgM3 = valores[fechaEntrada.valueOf()] ?? null;
        if (!kgM3) {
          kgM3 = await _kgM3.getKgM3By({
            cliente: _pesaje.cliente,
            fecha: fechaEntrada,
          });
        }
      }

      return kgM3;
    };

    const generarItem = async (_pesaje: Pesaje): Promise<Item> => {
      const id = _pesaje.id || noValueMessage;
      const entradaDate = dateEntradaPesaje(_pesaje);
      const salidaDate = dateSalidaPesaje(_pesaje);
      const fecha = entradaDate?.format("DD-MM-YYYY") || noValueMessage;
      const entrada = entradaDate?.format("HH:mm") || noValueMessage;
      const salida = salidaDate?.format("HH:mm") || noValueMessage;
      const patente = patenteVehiculo(_pesaje.vehiculo).toUpperCase();
      const cliente = nombreCliente(_pesaje.cliente).capitalizeAll();
      const hojaRuta = _pesaje.ruta?.nombre || noValueMessage;
      const tipoRuta = tipoRutaPesaje(_pesaje);

      const conductorEntrada = nombreChofer(
        _pesaje.entrada?.chofer
      ).capitalizeAll();

      const pesoEntrada = _pesaje.entrada.peso;

      const observacionEntrada =
        _pesaje.entrada.observacion?.capitalizeFirstLetter() || noValueMessage;

      const conductorSalida = nombreChofer(
        _pesaje.salida?.chofer
      ).capitalizeAll();

      const pesoSalida = _pesaje.salida?.peso ?? 0;

      const observacionSalida =
        _pesaje.salida.observacion?.capitalizeFirstLetter() || noValueMessage;

      const pesoNeto = pesoNetoPesaje(_pesaje);

      const desecho = nombreDesecho(_pesaje.desecho).capitalizeAll();
      const servicio = nombreServicio(_pesaje.servicio).capitalizeAll();

      const fechaEntrada = entradaDate ?? new Date();
      fechaEntrada?.setHours(0, 0, 0, 0);

      const kgM3 = await kgM3Pesaje(_pesaje);

      const m3 = parseFloat(
        _kgM3
          .getM3(pesoNeto, _pesaje.capacidad, _pesaje.cobro, kgM3 ?? 0)
          ?.toFixed(2) ?? "0"
      );

      return [
        fecha,
        entrada,
        salida,
        patente,
        cliente,
        hojaRuta,
        tipoRuta,
        observacionEntrada,
        observacionSalida,
        conductorEntrada,
        conductorSalida,
        pesoEntrada,
        pesoSalida,
        pesoNeto,
        m3,
        desecho,
        servicio,
        id,
      ];
    };

    const items = async (): Promise<ItemsPdf> => {
      dialogoGenerandoReporte.texto = t(
        "reportes.dialogo-progreso.reportes"
      ) as string;
      dialogoGenerandoReporte.model = true;
      dialogoGenerandoReporte.progreso = 0;
      let actualizandoProgreso = false;
      const _datosFiltrados = datosFiltrados.value.slice();
      const resultado: ItemsPdf = [];
      const totalRegistros = _datosFiltrados.length;
      for (const _pesaje of _datosFiltrados) {
        const item = await generarItem(_pesaje);
        resultado.push(item);
        if (!actualizandoProgreso) {
          actualizandoProgreso = true;
          setTimeout(() => {
            dialogoGenerandoReporte.progreso = Math.round(
              (resultado.length * 100) / totalRegistros
            );
            actualizandoProgreso = false;
          }, 1000);
        }
        await new Promise((resolve) => setTimeout(resolve, 40));
      }
      dialogoGenerandoReporte.model = false;
      return resultado;
    };

    const abrirGaleria = (): void => {
      mostrarGaleria.value = true;
    };

    const cerrarGaleria = (): void => {
      mostrarGaleria.value = false;
    };

    const patenteVehiculo = (val: Vehiculo | string | null): string => {
      if (typeof val === "string") return val;
      return val?.patente || noValueMessage;
    };

    const nombreCliente = (val: Cliente | string | null): string => {
      if (typeof val === "string") return val;
      return val?.nombre || noValueMessage;
    };

    const nombreDesecho = (val: Desecho | string | null): string => {
      if (typeof val === "string") return val;
      return val?.nombre || noValueMessage;
    };

    const nombreServicio = (val: Servicio | string | null): string => {
      if (typeof val === "string") return val;
      return val?.nombre || noValueMessage;
    };

    const nombreChofer = (val: Chofer | string | null): string => {
      if (typeof val === "string") return val;
      return val?.nombre || noValueMessage;
    };

    const mostrarInformacion = (_pesaje: Pesaje): void => {
      if (pesaje.value?.id === _pesaje.id) {
        pesaje.value = null;
      } else {
        pesaje.value = _pesaje;
      }
    };

    const validarCliente = (_pesaje: Pesaje, cliente: Cliente): boolean => {
      if (!cliente.id) return true;
      if (!_pesaje.cliente) return false;
      if (!_pesaje.cliente.id) return _pesaje.cliente.nombre === cliente.nombre;
      return _pesaje.cliente.id === cliente.id;
    };

    const validarServicio = (_pesaje: Pesaje, servicio: Servicio): boolean => {
      if (!servicio.id) return true;
      if (!_pesaje.servicio) return false;
      if (!_pesaje.servicio.id)
        return _pesaje.servicio.nombre === servicio.nombre;
      return _pesaje.servicio.id === servicio.id;
    };

    const validarFecha = (
      _pesaje: Pesaje,
      _fechaInicio: Date,
      _fechaFinal: Date
    ): boolean => {
      if (!_pesaje.entrada.fecha) return false;
      let fechaEntrada = _pesaje.entrada.fecha.toDate();
      return fechaEntrada >= _fechaInicio && fechaEntrada <= _fechaFinal;
    };

    const itemsPdf = (_items: ItemsPdf): ItemsPdf => {
      return _items.map((item) => item.slice(0, item.length - 1));
    };

    const crearPDF = async (): Promise<void> => {
      const _items = await items().catch((error) => {
        console.log(error);
        dialogoGenerandoReporte.model = false;
        return [] as ItemsPdf;
      });
      const dd: TDocumentDefinitions = {
        pageOrientation: "landscape",
        pageMargins: [20, 60, 20, 60],
        content: [
          {
            table: {
              widths: [
                "5.8%",
                "5.8%",
                "5.8%",
                "5.8%",
                "5.8%",
                "5.8%",
                "5.8%",
                "5.8%",
                "5.8%",
                "5.8%",
                "5.8%",
                "5.8%",
                "5.8%",
                "5.8%",
                "5.8%",
                "5.8%",
                "5.8%",
              ],
              body: [
                [
                  t("reportes.pdf.headers.0") as string,
                  t("reportes.pdf.headers.1") as string,
                  t("reportes.pdf.headers.2") as string,
                  t("reportes.pdf.headers.3") as string,
                  t("reportes.pdf.headers.4") as string,
                  t("reportes.pdf.headers.5") as string,
                  t("reportes.pdf.headers.6") as string,
                  t("reportes.pdf.headers.7") as string,
                  t("reportes.pdf.headers.8") as string,
                  t("reportes.pdf.headers.9") as string,
                  t("reportes.pdf.headers.10") as string,
                  t("reportes.pdf.headers.11") as string,
                  t("reportes.pdf.headers.12") as string,
                  t("reportes.pdf.headers.13") as string,
                  t("reportes.pdf.headers.14") as string,
                  t("reportes.pdf.headers.15") as string,
                  t("reportes.pdf.headers.16") as string,
                ],
                ...itemsPdf(_items),
                [
                  t("reportes.pdf.total") as string,
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  _items.reduce(
                    (sum, item) =>
                      sum + (typeof item[13] === "number" ? item[13] : 0),
                    0
                  ),
                  "",
                  "",
                  "",
                ],
              ],
            },
          },
        ],
        defaultStyle: {
          fontSize: 8,
        },
      };
      pdfMake
        .createPdf(dd)
        .download("Reporte " + new Date().format("DD-MM-YYYY") + ".pdf");
    };

    const crearExcel = async (): Promise<void> => {
      const _items = await items();
      const workbook = new Exceljs.Workbook();
      const sheet = workbook.addWorksheet("Pesajes", {
        properties: { defaultColWidth: 25 },
      });
      const rows = [
        [
          t("reportes.excel.headers.0") as string,
          t("reportes.excel.headers.1") as string,
          t("reportes.excel.headers.2") as string,
          t("reportes.excel.headers.3") as string,
          t("reportes.excel.headers.4") as string,
          t("reportes.excel.headers.5") as string,
          t("reportes.excel.headers.6") as string,
          t("reportes.excel.headers.7") as string,
          t("reportes.excel.headers.8") as string,
          t("reportes.excel.headers.9") as string,
          t("reportes.excel.headers.10") as string,
          t("reportes.excel.headers.11") as string,
          t("reportes.excel.headers.12") as string,
          t("reportes.excel.headers.13") as string,
          t("reportes.excel.headers.14") as string,
          t("reportes.excel.headers.15") as string,
          t("reportes.excel.headers.16") as string,
          t("reportes.excel.headers.17") as string,
        ],
        ..._items,
        [],
        [
          t("reportes.excel.total") as string,
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          _items.reduce(
            (sum, item) => sum + (typeof item[13] === "number" ? item[13] : 0),
            0
          ),
        ],
      ];
      sheet.addRows(rows);
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: "application/xlsx" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Reporte" + new Date().format("DD-MM-YYYY") + ".xlsx";
      link.click();
    };

    const createDocument = async (
      _pesaje: Pesaje
    ): Promise<TDocumentDefinitions> => {
      const fechaEntrada: Date | null = _pesaje.entrada.fecha
        ? _pesaje.entrada.fecha.toDate()
        : new Date();
      fechaEntrada.setHours(0, 0, 0, 0);
      const kgM3 = _pesaje.cliente
        ? await _kgM3.getKgM3By({
            cliente: _pesaje.cliente,
            fecha: fechaEntrada,
          })
        : 0;
      const peso =
        _pesaje.entrada.peso > 0 && _pesaje.salida.peso > 0
          ? _pesaje.entrada.peso - _pesaje.salida.peso
          : 0;
      const pesoNeto = _pesaje.servicio?.tipo === "Egreso" ? peso * -1 : peso;
      const m3 = _kgM3
        .getM3(pesoNeto, _pesaje.capacidad, _pesaje.cobro, kgM3)
        ?.toFixed(2);
      return _mixins.$printExitTicket({
        empresa: _usuario.getEmpresa.value,
        qr: `${_pesaje.id}@${_usuario.getEmpresa.value.rut}`,
        m3: m3 ?? "0",
        pesaje: _pesaje,
      });
    };

    const imprimirBoleto = async (): Promise<void> => {
      const id = pesaje.value?.id ?? "";
      const _pesaje = pesajes.value[id];
      if (_pesaje) {
        const dd = await createDocument(_pesaje);
        pdfMake.createPdf(dd).print();
      }
    };

    const cerrarDialogoConfirmacion = (): void => {
      dialogoConfirmacion.model = false;
      dialogoConfirmacion.texto = "";
      dialogoConfirmacion.si = null;
      dialogoConfirmacion.no = null;
    };

    const validarImprimirTodos = (): void => {
      dialogoConfirmacion.model = true;
      dialogoConfirmacion.texto = tc(
        "reportes.confirmacion-imprimir-todos",
        datosFiltrados.value.length
      );
      dialogoConfirmacion.si = () => {
        imprimirTodos();
        cerrarDialogoConfirmacion();
      };
      dialogoConfirmacion.no = () => {
        cerrarDialogoConfirmacion();
      };
    };

    const imprimirTodos = async (): Promise<void> => {
      dialogoGenerandoReporte.model = true;
      dialogoGenerandoReporte.progreso = 0;
      dialogoGenerandoReporte.texto = t(
        "reportes.dialogo-progreso.boletos"
      ) as string;
      let actualizandoProgreso = false;
      const ddResult: PrintAllDocuments = {
        content: [],
      };
      const __pesajes = datosFiltrados.value;
      const totalRegistros = __pesajes.length;
      for (const _pesaje of __pesajes) {
        const dd = (await createDocument(_pesaje)) as PrintAllDocuments;
        if (ddResult.content.length > 0) {
          dd.content[0].pageBreak = "before";
        }
        ddResult.content.push(...dd.content);
        if (!actualizandoProgreso) {
          actualizandoProgreso = true;
          setTimeout(() => {
            const pages = ddResult.content.length / 8;
            dialogoGenerandoReporte.progreso = Math.round(
              (pages * 100) / totalRegistros
            );
            actualizandoProgreso = false;
          }, 1000);
        }
        await new Promise((resolve) => setTimeout(resolve, 40));
      }
      dialogoGenerandoReporte.model = false;
      pdfMake.createPdf(ddResult).print();
    };

    if (log) console.timeEnd("wiews.VistaReportes.setup");

    return {
      pesaje,
      filtros,
      usuarios,
      archivos,
      datosFiltrados,
      mostrarGaleria,
      clienteUsuario,
      serviciosUsuario,
      desechosOrdenados,
      clientesOrdenados,
      vehiculosOrdenados,
      serviciosOrdenados,
      dialogoGenerandoReporte,
      dialogoConfirmacion,
      crearPDF,
      crearExcel,
      abrirGaleria,
      cerrarGaleria,
      imprimirBoleto,
      mostrarInformacion,
      validarImprimirTodos,
    };
  },
});
