
import {Component, Prop, Vue} from "vue-property-decorator";
import {ApexOptions} from "apexcharts";

@Component
export default class ColumnChart extends Vue {
  @Prop() readonly labels!: Array<string>;
  @Prop() readonly series!: Array<{ name: string; data: Array<string | number> }>;

  actualizando = false;

  get options(): ApexOptions {
    return {
      chart: {
        id: "column-chart",
        type: "bar",
        toolbar: {
          show: false
        }
      },
      yaxis: {
        labels: {
          style: {
            fontFamily: "Metropolis",
            colors: this.$vuetify.theme.themes.light.text as string,
          },
        },
      },
      xaxis: {
        labels: {
          show: false,
        },
      },
      tooltip: {
        fixed: {
          enabled: true,
          position: "right",
        },
      },
      title: {
        text: "",
      },
      dataLabels: {
        enabled: true,
      },
      labels: this.labels,
      colors: [this.$vuetify.theme.themes.light.yellow],
    };
  }
}
