
// vue
import { defineComponent, computed, ref, reactive } from "vue";
// composables
import { useI18n } from "@/composables/i18n";
// validaciones
import useVuelidate from "@vuelidate/core";
import { required, minLength, sameAs } from "@vuelidate/validators";

export default defineComponent({
  emits: ["click-btn-submit"],
  setup(props, ctx) {
    const { t } = useI18n();

    const password = ref("");
    const rePassword = ref("");

    const verPassword = ref(false);
    const verRePassword = ref(false);

    const state = reactive({
      password,
      rePassword,
    });

    const rules = {
      password: {
        required,
        minLength: minLength(6),
        sameAs: sameAs("rePassword"),
      },
      rePassword: {
        required,
        minLength: minLength(6),
        sameAs: sameAs("password"),
      },
    };

    const v$ = useVuelidate(rules, state);

    const errorPassword = computed((): string[] => {
      const error: Array<string> = [];
      if (!v$.value.password.$dirty) return error;
      v$.value.password.required ||
        error.push(
          t("login.form-activate-account.password.error.required") as string
        );
      v$.value.password.minLength ||
        error.push(
          t("login.form-activate-account.password.error.min-length") as string
        );
      v$.value.rePassword.sameAs ||
        error.push(
          t("login.form-activate-account.password.error.same-as") as string
        );
      return error;
    });

    const errorRePassword = computed((): string[] => {
      const error: Array<string> = [];
      if (!v$.value.rePassword.$dirty) return error;
      v$.value.rePassword.required ||
        error.push(
          t("login.form-activate-account.re-password.error.required") as string
        );
      v$.value.rePassword.minLength ||
        error.push(
          t(
            "login.form-activate-account.re-password.error.min-length"
          ) as string
        );
      v$.value.rePassword.sameAs ||
        error.push(
          t("login.form-activate-account.re-password.error.same-as") as string
        );
      return error;
    });

    const onClickBtnSubmit = (data: { password: string }) => {
      ctx.emit("click-btn-submit", data);
    };

    const submit = (): void => {
      v$.value.$touch();
      if (!v$.value.$invalid) {
        onClickBtnSubmit({ password: password.value });
      }
    };

    return {
      t,
      v$,
      password,
      rePassword,
      verPassword,
      verRePassword,
      errorPassword,
      errorRePassword,
      submit,
    };
  },
});
