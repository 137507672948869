
import {Component, Prop, Vue} from "vue-property-decorator";
import {ApexOptions} from "apexcharts";

@Component
export default class LineChart extends Vue {
  @Prop() readonly title!: string;
  @Prop() readonly labels!: Array<string>;
  @Prop() readonly series!: Array<{ name: string; data: Array<string | number> }>;

  actualizando = false;

  get options(): ApexOptions {
    return {
      chart: {
        id: "sparkline1",
        type: "line",
        sparkline: {
          enabled: true,
        },
        toolbar: {
          show: false,
        },
        group: "sparklines",
      },
      stroke: {
        curve: "smooth",
        width: 10,
      },
      markers: {
        size: 0,
      },
      tooltip: {
        fixed: {
          enabled: true,
          position: "right",
        },
      },
      title: {
        text: this.title,
        style: {
          fontSize: "26px",
          color: this.$vuetify.theme.themes.light.text as string,
          fontFamily: "Metropolis",
        },
      },
      dataLabels: {
        enabled: false,
      },
      labels: this.labels,
      colors: [this.$vuetify.theme.themes.light.purple],
    };
  }
}
