
//composition
import {defineComponent, computed} from "vue";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "FilterSelect",
  components: {
    SvgIcon
  },
  emits: [
    "change",
    "input"
  ],
  props: {
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    multiple: {
      type: Boolean
    },
    returnObject: {
      type: Boolean
    },
    textMultiple: {
      type: String,
      default: ""
    },
    itemText: {
      type: String,
      default: "text"
    },
    itemValue: {
      type: String,
      default: "value"
    },
    value: {
      type: [String, Number, Object]
    }
  },
  setup(props, ctx) {
    const vModel = computed({
      get() {
        return props.value;
      },
      set(value) {
        ctx.emit("input", value);
      }
    });

    const onChange = (): void => {
      ctx.emit("change", vModel.value);
    };

    const getText = (item: { [key: string]: string } | string): string => {
      if (typeof item === "object") {
        if (item[props.itemText]) {
          return item[props.itemText];
        }
      }
      return JSON.stringify(item);
    }

    return {
      vModel,
      getText,
      onChange
    };
  }
})
