
// Vue
import {computed, defineComponent} from "vue";

// Composables
import {useI18n} from "@/composables/i18n";

// Tipos
import {Pesaje} from "@/typings/store/plugins/easyFirestore/pesajes";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "ItemCirculo",
  components: {
    SvgIcon,
  },
  props: {
    pesaje: {
      required: true,
      type: Object as () => Pesaje,
    },
    errores: {
      required: true,
      type: Array as () => string[],
    },
  },
  emits: [
    "click:sincronizar",
  ],
  setup(props, ctx) {
    // Composables

    const {t} = useI18n();

    // Computed

    const circleStyle = computed(() => {
      let color = "text";
      if (props.pesaje.estado === "finalizado") {
        color = props.errores.length > 0 ? 'error' : 'primary';
      }
      return {
        'background-color': `var(--v-${color}-base)`,
      };
    });

    const estadoSincronizacion = computed(() => {
      const sincronizacion = props.pesaje.sincronizacion;
      if (!sincronizacion) return 1;
      const now = Date.now();
      const timePesaje = new Date(sincronizacion.enviado).getTime();
      const diferencia = Math.abs(now - timePesaje);
      if (diferencia <= 10000) return 0;
      if (!sincronizacion.recibido) return 2;
      return 1;
    });

    const textoTooltip = computed(() => {
      let textEstado = text(`estado.1`);
      if (props.pesaje.estado === "finalizado") {
        textEstado = props.errores.length > 0 ? props.errores.join("\n") : text("estado.0");
      }
      const textSicronizacion = text(`sincronizacion.${estadoSincronizacion.value}`);
      return `${textEstado}\n${textSicronizacion}`;
    });

    const icono = computed(() => {
      const iconos = ["far-rotate", "far-check", "far-pause"];
      return iconos[estadoSincronizacion.value];
    });

    // Methods

    const text = (key: string) => t(`pesajes.tabla.${key}`) as string;

    // Emits

    const onClickSincronizar = () => {
      if (estadoSincronizacion.value === 2) {
        ctx.emit("click:sincronizar", props.pesaje);
      }
    };

    return {
      // Computed
      icono,
      circleStyle,
      textoTooltip,
      // Emits
      onClickSincronizar,
    };
  },
});

