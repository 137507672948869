
//decoradores
import { Component, Prop, Vue } from "vue-property-decorator";
//tipos
import { Pesaje } from "@/typings/store/plugins/easyFirestore/pesajes";
//componentes
import ColumnChart from "../charts/ColumnChart.vue";

@Component({
  components: { ColumnChart },
})
export default class TotalExtendidoVechiulos extends Vue {
  @Prop() readonly pesajes!: Array<Pesaje>;
  @Prop() readonly periodo!: number;
  @Prop() readonly fechas!: Array<Date>;

  get diferenciaDias(): number {
    const [fechaInicio, fechaFin] = this.fechas;
    if (fechaFin && fechaInicio) {
      const diferencia = fechaFin.getTime() - fechaInicio.getTime();
      return Math.round(diferencia / (1000 * 60 * 60 * 24));
    }
    return 0;
  }

  get fechasValidas(): Array<Date> {
    const fechas = [];
    const [fechaInicio, fechaFin] = this.fechas;
    const fecha = new Date(fechaInicio);
    while (fecha <= fechaFin) {
      fechas.push(new Date(fecha));
      if (this.periodo === 1 || this.periodo === 2) {
        fecha.setDate(fecha.getDate() + 1);
      } else if (this.periodo === 3) {
        fecha.setDate(fecha.getDate() + 3);
      } else if (this.periodo <= 5) {
        fecha.setMonth(fecha.getMonth() + 1);
      } else {
        this.diferenciaDias <= 91
          ? fecha.setDate(fecha.getDate() + 1)
          : fecha.setMonth(fecha.getMonth() + 1);
      }
    }
    return fechas;
  }

  get labels(): Array<string> {
    return this.fechasValidas.map((fecha) => {
      if (this.periodo >= 1 && this.periodo <= 3) {
        return fecha.format("DD/MM/YYYY");
      } else if (this.periodo <= 5) {
        return fecha.format("MMMM");
      } else {
        return fecha.format(this.diferenciaDias <= 91 ? "DD/MM/YYYY" : "MMMM");
      }
    });
  }

  get series(): Array<{ name: string; data: Array<number> }> {
    const helper: { [key: number]: number } = {};
    for (const fecha of this.fechasValidas) {
      helper[fecha.getTime()] = 0;
    }
    for (const pesaje of this.pesajes) {
      const fechaPesaje = pesaje.entrada.fecha?.toDate();
      if (fechaPesaje) {
        fechaPesaje.setHours(0, 0, 0, 0);
        const fecha = this.obtenerFecha(fechaPesaje);
        if (fecha) {
          if (!helper[fecha.getTime()]) {
            helper[fecha.getTime()] = 0;
          }
          helper[fecha.getTime()]++;
        }
      }
    }
    return [
      {
        name: this.$t("monitoreo.tab.vehiculos.grafico-3.text") as string,
        data: Object.values(helper),
      },
    ];
  }

  obtenerFecha(fechaPesaje: Date): Date {
    const fecha = this.fechasValidas.find((fecha) => {
      if (this.periodo >= 1 && this.periodo <= 3) {
        return fecha.getTime() >= fechaPesaje.getTime();
      } else if (this.periodo <= 5) {
        return fecha.getMonth() === fechaPesaje.getMonth();
      } else {
        return this.diferenciaDias <= 91
          ? fecha.getTime() >= fechaPesaje.getTime()
          : fecha.getMonth() === fechaPesaje.getMonth();
      }
    });
    return fecha ?? this.fechasValidas[this.fechasValidas.length - 1];
  }
}
