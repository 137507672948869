
// Vue
import {defineComponent} from "vue";

// Tipos
import {Ruta} from "@/typings/store/modules/rutas";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "BotonHome",
  components: {SvgIcon},
  props: {
    ruta: {
      required: true,
      type: Object as () => Ruta,
    },
  },
  emits: [
    "click",
  ],
  setup(_props, ctx) {
    // Emits

    const onClick = () => {
      ctx.emit("click");
    };

    return {
      // Emits
      onClick,
    };
  },
});
