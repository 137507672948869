
//composition
import { computed, defineComponent, ref, watch } from "vue";
//helpers
import { log } from "@/helpers/env";
//tipos
import { Item } from "@/typings/components/planes/tabla";
import { Plan } from "@/typings/store/plugins/easyFirestore/planes";
//componentes
import IconButton from "@/components/custom/IconButton.vue";

export default defineComponent({
  name: "OpcionesTabla",
  components: {
    IconButton,
  },
  emits: ["click:informacion", "click:switch"],
  props: {
    item: {
      type: Object as () => Item,
      required: true,
    },
    seleccionado: {
      type: Object as () => Plan | null,
    },
  },
  setup(props, ctx) {
    const cargando = ref(false);

    const estado = computed(() => props.item.plan.estado);

    const switchValue = computed({
      get() {
        return estado.value !== "deshabilitado";
      },
      set(value) {
        if (log) {
          console.log("set switchValue", value);
        }
        ctx.emit("click:switch", props.item);
        cargando.value = true;
      },
    });

    watch(estado, () => {
      cargando.value = false;
    });

    const isSelected = computed(() => {
      return !!props.seleccionado && props.seleccionado.id === props.item.id;
    });

    const onClickInfo = () => {
      ctx.emit("click:informacion", props.item);
    };

    return {
      cargando,
      switchValue,
      isSelected,
      onClickInfo,
    };
  },
});
