
// composition-api
import { defineComponent, ref } from "vue";
// vue-qrcode-reader
import { QrcodeStream } from "vue-qrcode-reader";
//  composables
import { usePesajes } from "@/composables/pesajes";
import { useUsuario } from "@/composables/usuario";
// tipos
import { Pesaje } from "@/typings/store/plugins/easyFirestore/pesajes";

interface PesajeInner {
  vehiculo: {
    patente: string;
    tipo: string;
  };
  cliente: {
    nombre: string;
  };
  desecho: {
    nombre: string;
  };
  servicio: {
    nombre: string;
  };
  ruta: {
    nombre: string;
  };
  entrada: {
    peso: number;
    observacion: string;
    chofer: {
      nombre: string;
    };
    fecha: string;
  };
  salida: {
    peso: number;
    observacion: string;
    chofer: {
      nombre: string;
    };
    fecha: string;
  };
}

export default defineComponent({
  name:"VistaQR",
  components: {
    QrcodeStream,
  },
  setup() {
    const _usuario = useUsuario();
    const _pesajes = usePesajes();

    const pesaje = ref<PesajeInner | null>(null);
    const empresa = ref("");
    const dialogoEmpresa = ref(false);
    const scannedId = ref("");

    const pesajeToPesajeInner = (_pesaje: Pesaje) => {
      return {
        vehiculo: {
          patente: _pesaje.vehiculo?.patente ?? "",
          tipo: _pesaje.vehiculo?.tipo ?? "",
        },
        cliente: {
          nombre: _pesaje.cliente?.nombre ?? "",
        },
        desecho: {
          nombre: _pesaje.desecho?.nombre ?? "",
        },
        servicio: {
          nombre: _pesaje.servicio?.nombre ?? "",
        },
        ruta: {
          nombre: _pesaje.ruta?.nombre ?? "",
        },
        entrada: {
          peso: _pesaje.entrada?.peso ?? 0,
          observacion: _pesaje.entrada?.observacion ?? "",
          chofer: {
            nombre: _pesaje.entrada?.chofer?.nombre ?? "",
          },
          fecha: _pesaje.entrada?.fecha ?? "",
        },
        salida: {
          peso: _pesaje.salida?.peso ?? 0,
          observacion: _pesaje.salida?.observacion ?? "",
          chofer: {
            nombre: _pesaje.salida?.chofer?.nombre ?? "",
          },
          fecha: _pesaje.salida?.fecha ?? "",
        },
      };
    };

    const onDecode = async (val: string): Promise<void> => {
      const splitted = val.split("@");
      const id = splitted[0];
      const rutEmpresa: string | null =
        splitted[1] || (_usuario.getEmpresa.value.id ?? "");
      if (rutEmpresa) {
        await _pesajes.fetchAndAdd({
          pathVariables: {
            idEmpresa: rutEmpresa,
          },
          clauses: {
            where: [["id", "==", id]],
          },
        });
        const result = _pesajes.get.value[id];
        if (result) {
          pesaje.value = pesajeToPesajeInner(result);
        }
      } else {
        dialogoEmpresa.value = true;
        scannedId.value = id;
      }
    };

    const aceptarEmpresa = (): void => {
      onDecode(`${scannedId.value}@${empresa.value}`);
      dialogoEmpresa.value = false;
      scannedId.value = "";
      empresa.value = "";
    };

    return {
      pesaje,
      empresa,
      dialogoEmpresa,
      onDecode,
      aceptarEmpresa,
    };
  },
});
