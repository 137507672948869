
//composition
import { watch, ref, computed, defineComponent } from "vue";
//tipos
import { Barredor } from "@/typings/store/plugins/easyFirestore/barredores";
import { PingRastreador } from "@/typings/store/plugins/easyFirestore/pingsRastreador";
//componentes
import SinMovimiento from "@/components/barredores/monitoreo/SinMovimiento.vue";
import RutaMonitoreo from "@/components/barredores/monitoreo/Ruta.vue";

export default defineComponent({
  name: "MonitoreoBarredores",
  components: {
    SinMovimiento,
    RutaMonitoreo,
  },
  props: {
    value: {
      type: Object as () => {
        tipo: "sin-movimiento" | "ruta";
        barredor: Barredor | null;
      },
      required: true,
    },
    pingActual: {
      type: Object as () => PingRastreador | null,
    },
    barredores: {
      type: Array as () => Array<Barredor>,
      required: true,
    },
    pingsRastreador: {
      type: Array as () => Array<PingRastreador>,
      required: true,
    },
    fecha: {
      type: String as () => string,
      required: true,
    },
  },
  emits: ["click:eliminar-ping", "click:eliminar-ruta-completa", "input"],
  setup(props, ctx) {
    const tab = ref(0);

    const dataMonitoreo = computed<{
      tipo: "sin-movimiento" | "ruta";
      barredor: Barredor | null;
    }>({
      get() {
        return props.value;
      },
      set(val) {
        ctx.emit("input", val);
      },
    });

    const barredoresOrdenados=computed(() => {
      const barredores= props.barredores;
      return barredores.sort((barredorA,barredorB) => {
        return barredorA.numero - barredorB.numero
      })
    });

    const pingsFiltrados = computed(() => {
      const pings = props.pingsRastreador.filter((ping) => {
        const filtroBarredor = ping.barredores.some(
          (barredor) => barredor.id === dataMonitoreo.value.barredor?.id
        );
        const horarioBarredor = dataMonitoreo.value.barredor?.horario;
        const inicio = props.fecha.toDate();
        inicio.setHours(0, 0, 0, 0);
        const fin = props.fecha.toDate();
        fin.setHours(23, 59, 59, 999);
        if (horarioBarredor) {
          const [horarioInicio, horarioFin] = horarioBarredor.split("-");
          const [horaInicio, minutoInicio] = horarioInicio
            .split(":")
            .map(Number);
          const [horaFin, minutoFin] = horarioFin.split(":").map(Number);
          inicio.setHours(horaInicio, minutoInicio, 0, 0);
          fin.setHours(horaFin, minutoFin, 59, 999);
        }
        const fecha = ping.fecha.toDate();
        const filtroFecha = inicio <= fecha && fecha <= fin;
        return filtroBarredor && filtroFecha;
      });
      pings.sort(
        (a, b) => a.fecha.toDate().getTime() - b.fecha.toDate().getTime()
      );
      return pings;
    });

    watch(() => dataMonitoreo.value.barredor,() => {
        dataMonitoreo.value.tipo = tab.value === 0 ? "sin-movimiento" : "ruta";
      }
    );

    watch(() => tab.value, () => {
        dataMonitoreo.value.tipo = tab.value === 0 ? "sin-movimiento" : "ruta";
      },{ immediate: true }
    );

    const onClickEliminarPing = (ping: PingRastreador) => {
      ctx.emit("click:eliminar-ping", ping);
    };

    const onClickEliminarRutaCompleta = () => {
      ctx.emit("click:eliminar-ruta-completa", dataMonitoreo.value.barredor);
    };

    return {
      dataMonitoreo,
      barredoresOrdenados,
      tab,
      pingsFiltrados,
      onClickEliminarPing,
      onClickEliminarRutaCompleta,
    };
  },
});
