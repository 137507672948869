
//decoradores
import { Component, Prop, Vue } from "vue-property-decorator";
//tipos
import { Cliente } from "@/typings/store/plugins/easyFirestore/clientes";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: { SvgIcon },
})
export default class MenuInfoCliente extends Vue {
  @Prop() readonly cliente!: Cliente | null;
  @Prop({ type: Boolean }) readonly top!: boolean;
  @Prop() readonly parent!: string;

  width = 0;

  get activatorCard(): HTMLElement | null {
    return (
      (this.$refs["activator-card"] as (Vue & { $el: HTMLElement }) | null)
        ?.$el ?? null
    );
  }

  get noValueMessage(): string {
    return this.$t(`${this.parent}.menu.no-value-message`) as string;
  }

  get nombre(): string {
    return this.cliente?.nombre || this.noValueMessage;
  }

  get rut(): string {
    return this.cliente?.rut || this.noValueMessage;
  }

  get tipo(): string {
    return this.cliente?.tipo || this.noValueMessage;
  }

  get giro(): string {
    return this.cliente?.giro || this.noValueMessage;
  }

  get ciudad(): string {
    return this.cliente?.ciudad || this.noValueMessage;
  }

  get direccion(): string {
    return this.cliente?.direccion || this.noValueMessage;
  }

  get telefono(): string {
    return this.cliente?.contacto || this.noValueMessage;
  }

  get correo(): string {
    return this.cliente?.correo || this.noValueMessage;
  }

  openMenu(): void {
    const bounds = this.activatorCard?.getBoundingClientRect();
    this.width = bounds?.width ?? 0;
  }
}
