
// vue
import { defineComponent, computed, ref, watch, reactive } from "vue";
// composables
import { useI18n } from "@/composables/i18n";
// tipos
import { Cliente } from "@/typings/store/plugins/easyFirestore/clientes";
import { Servicio } from "@/typings/store/plugins/easyFirestore/servicios";
import {
  Permisos,
  PermisosTipoUsuario,
} from "@/typings/store/modules/permisos";
import {
  TipoUsuario,
  Usuario,
  UsuarioPatch,
} from "@/typings/store/plugins/easyFirestore/usuarios";
// componentes
import SubirImagen from "@/components/SubirImagen.vue";
// validaciones
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

export default defineComponent({
  components: {
    SubirImagen,
  },
  props: {
    value: {
      default: null,
      type: Object as () => UsuarioPatch | null,
    },
    tipoUsuarioPermisos: {
      optional: false,
      type: Object as () => PermisosTipoUsuario,
    },
    seleccionado: {
      default: null,
      type: Object as () => Usuario | null,
    },
    arrayClientes: {
      default: () => [],
      type: Array as () => Cliente[],
    },
    arrayServicios: {
      default: () => [],
      type: Array as () => Servicio[],
    },
  },
  created(): void {
    if (this.seleccionado?.cliente) {
      const cliente = this.seleccionado.cliente;
      this.itemsClientes = this.arrayClientes.filter(
        (item) => item.id !== cliente.id
      );
      this.itemsClientes.push(cliente);
    } else {
      this.itemsClientes = this.arrayClientes;
    }
    if (this.seleccionado?.servicios) {
      const servicios = this.seleccionado.servicios.slice();
      this.itemsServicios = this.arrayServicios.filter((item) => {
        const idsServicio = servicios.map((item) => item.id);
        return !idsServicio.includes(item.id);
      });
      this.itemsServicios.push(...servicios);
    } else {
      this.itemsServicios = this.arrayServicios;
    }
  },
  emits: ["input", "cambio-foto"],
  setup(props, ctx) {
    const { t, tc } = useI18n();

    const vModel = computed({
      get() {
        return props.value;
      },
      set(val: UsuarioPatch | null) {
        ctx.emit("input", val);
      },
    });

    const itemsClientes = ref<Cliente[]>([]);
    const itemsServicios = ref<Servicio[]>([]);

    const archivo = ref<File | null>(null);

    watch(archivo, (val: File | null) => {
      if (val) {
        ctx.emit("cambio-foto", val);
      }
    });

    const itemsTiposUsuario = computed(
      (): Array<{ value: TipoUsuario; text: string }> => {
        if (props.seleccionado?.tipo === "sudo") {
          return [
            {
              text: t("users.form.item.type.values.4") as string,
              value: "sudo",
            },
          ];
        }
        return [
          {
            text: t("users.form.item.type.values.0") as string,
            value: "administrador",
          },
          {
            text: t("users.form.item.type.values.1") as string,
            value: "supervisor",
          },
          {
            text: t("users.form.item.type.values.2") as string,
            value: "bascula",
          },
          {
            text: t("users.form.item.type.values.3") as string,
            value: "cliente",
          },
        ];
      }
    );

    const archivoURL = computed((): string | null => {
      if (archivo.value) {
        return URL.createObjectURL(archivo.value);
      }
      if (props.seleccionado?.foto) {
        return props.seleccionado.foto.url;
      }
      return null;
    });

    const titulo = computed((): string => {
      if (props.seleccionado) {
        return t("users.form.title.edit") as string;
      }
      return t("users.form.title.create") as string;
    });

    const nombres = computed({
      get() {
        return props.value?.nombres ?? props.seleccionado?.nombres ?? "";
      },
      set(value: string) {
        const valores = Object.assign({}, props.value);
        const usuario: UsuarioPatch = valores ?? {
          id: props.seleccionado?.id ?? "",
        };
        if (usuario.nombres !== value) {
          usuario.nombres = value ?? "";
          vModel.value = usuario;
        }
      },
    });

    const apellidos = computed({
      get() {
        return props.value?.apellidos ?? props.seleccionado?.apellidos ?? "";
      },
      set(value: string) {
        const valores = Object.assign({}, props.value);
        const usuario: UsuarioPatch = valores ?? {
          id: props.seleccionado?.id ?? "",
        };
        if (usuario.apellidos !== value) {
          usuario.apellidos = value ?? "";
          vModel.value = usuario;
        }
      },
    });

    const rut = computed({
      get() {
        return props.value?.rut ?? props.seleccionado?.rut ?? "";
      },
      set(value: string) {
        const valores = Object.assign({}, props.value);
        const usuario: UsuarioPatch = valores ?? {
          id: props.seleccionado?.id ?? "",
        };
        if (usuario.rut !== value) {
          usuario.rut = value ?? "";
          vModel.value = usuario;
        }
      },
    });

    const cargo = computed({
      get() {
        return props.value?.cargo ?? props.seleccionado?.cargo ?? "";
      },
      set(value: string) {
        const valores = Object.assign({}, props.value);
        const usuario: UsuarioPatch = valores ?? {
          id: props.seleccionado?.id ?? "",
        };
        if (usuario.cargo !== value) {
          usuario.cargo = value ?? "";
          vModel.value = usuario;
        }
      },
    });

    const correo = computed({
      get() {
        return props.value?.correo ?? props.seleccionado?.correo ?? "";
      },
      set(value: string) {
        const valores = Object.assign({}, props.value);
        const usuario: UsuarioPatch = valores ?? {
          id: props.seleccionado?.id ?? "",
        };
        if (usuario.correo !== value) {
          usuario.correo = value ?? "";
          vModel.value = usuario;
        }
      },
    });

    const telefono = computed({
      get() {
        return props.value?.telefono ?? props.seleccionado?.telefono ?? "";
      },
      set(value: string) {
        const valores = Object.assign({}, props.value);
        const usuario: UsuarioPatch = valores ?? {
          id: props.seleccionado?.id ?? "",
        };
        if (usuario.telefono !== value) {
          usuario.telefono = value ?? "";
          vModel.value = usuario;
        }
      },
    });

    const cliente = computed({
      get() {
        return props.value?.cliente ?? props.seleccionado?.cliente ?? null;
      },
      set(value: Cliente | null) {
        const valores = Object.assign({}, props.value);
        const usuario: UsuarioPatch = valores ?? {
          id: props.seleccionado?.id ?? "",
        };
        if (usuario.cliente?.id !== value?.id) {
          usuario.cliente = value ?? null;
          vModel.value = usuario;
        }
      },
    });

    const servicios = computed({
      get() {
        return props.value?.servicios ?? props.seleccionado?.servicios ?? [];
      },
      set(value: Servicio[]) {
        const valores = Object.assign({}, props.value);
        const usuario: UsuarioPatch = valores ?? {
          id: props.seleccionado?.id ?? "",
        };
        if (usuario.servicios !== value) {
          usuario.servicios = value ?? [];
          vModel.value = usuario;
        }
      },
    });

    const permisosPorTipo = (tipo: TipoUsuario): Permisos => {
      if (!props.tipoUsuarioPermisos) {
        return {};
      }
      return props.tipoUsuarioPermisos[tipo] ?? {};
    };

    const tipo = computed({
      get() {
        return props.value?.tipo ?? props.seleccionado?.tipo ?? "";
      },
      set(value: TipoUsuario | "") {
        if (value === "") return;
        const valores = Object.assign({}, props.value);
        const usuario: UsuarioPatch = valores ?? {
          id: props.seleccionado?.id ?? "",
        };
        if (usuario.tipo !== value) {
          usuario.tipo = value ?? "cliente";
          usuario.permisos = permisosPorTipo(usuario.tipo);
          vModel.value = usuario;
        }
      },
    });

    const readonlyCorreo = computed((): boolean => {
      return props.seleccionado !== null;
    });

    const state = reactive({
      nombres,
      apellidos,
      rut,
      cargo,
      correo,
      telefono,
      tipo,
    });

    const rules = {
      nombres: {
        required,
      },
      apellidos: {
        required,
      },
      rut: {
        required,
      },
      cargo: {
        required,
      },
      correo: {
        required,
        email,
      },
      telefono: {
        required,
      },
      tipo: {
        required,
      },
    };

    const v$ = useVuelidate(rules, state);

    const erroresNombres = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.nombres.$dirty) return errores;
      if (v$.value.nombres.required.$invalid)
        errores.push(t("users.form.item.names.error.required") as string);
      return errores;
    });

    const erroresApellidos = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.apellidos.$dirty) return errores;
      if (v$.value.apellidos.required.$invalid)
        errores.push(t("users.form.item.last-names.error.required") as string);
      return errores;
    });

    const erroresRut = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.rut.$dirty) return errores;
      if (v$.value.rut.required.$invalid)
        errores.push(t("users.form.item.uid.error.required") as string);
      return errores;
    });

    const erroresCargo = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.cargo.$dirty) return errores;
      if (v$.value.cargo.required.$invalid)
        errores.push(t("users.form.item.role.error.required") as string);
      return errores;
    });

    const erroresCorreo = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.correo.$dirty) return errores;
      if (v$.value.correo.required.$invalid)
        errores.push(t("users.form.item.email.error.required") as string);
      if (v$.value.correo.email)
        errores.push(t("users.form.item.email.error.email") as string);
      return errores;
    });

    const erroresTelefono = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.telefono.$dirty) return errores;
      if (v$.value.telefono.required.$invalid)
        errores.push(t("users.form.item.phone.error.required") as string);
      return errores;
    });

    const erroresTipo = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.tipo.$dirty) return errores;
      if (v$.value.tipo.required.$invalid)
        errores.push(t("users.form.item.type.error.required") as string);
      return errores;
    });

    return {
      t,
      tc,
      v$,
      vModel,
      itemsClientes,
      itemsServicios,
      titulo,
      archivo,
      archivoURL,
      nombres,
      apellidos,
      rut,
      cargo,
      correo,
      telefono,
      cliente,
      servicios,
      tipo,
      readonlyCorreo,
      erroresNombres,
      erroresApellidos,
      erroresRut,
      erroresCargo,
      erroresCorreo,
      erroresTelefono,
      erroresTipo,
      itemsTiposUsuario,
    };
  },
});
