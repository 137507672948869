
//decoradores
import {
  Component,
  Emit,
  Prop,
  VModel,
  Vue,
  Watch,
} from "vue-property-decorator";
// helpers
import { imageToWebp } from "@/helpers/image";
//tipos
import { Pesaje } from "@/typings/store/plugins/easyFirestore/pesajes";
import {
  Archivo,
  Archivos,
  BlobArchivo,
} from "@/typings/store/plugins/easyFirestore/archivos";
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: {
    SvgIcon,
  },
})
export default class GaleriaImagenes extends Vue {
  @VModel() imagenes!: {
    nuevas: Array<BlobArchivo>;
    eliminar: Array<Archivo>;
  };
  @Prop() readonly pesaje!: Pesaje;
  @Prop() readonly archivos!: Archivos;
  @Prop({ type: Boolean }) readonly editar!: boolean;
  @Prop() readonly usuario!: Usuario;

  created(): void {
    this.fotosPesaje = this.pesaje.fotos;
  }

  fotoSeleccionada: Archivo | BlobArchivo | null = null;
  fotosPesaje: Array<Archivo> = [];

  get fotos(): Array<Archivo | BlobArchivo> {
    const fotosPesaje = this.fotosPesaje.filter(
      (foto) =>
        !this.imagenes.eliminar.some((eliminar) => eliminar.id === foto.id)
    );
    const fotosNuevas = this.imagenes.nuevas.map((foto) => ({
      ...foto,
      url: URL.createObjectURL(foto.file),
    }));
    return [...fotosPesaje, ...fotosNuevas];
  }

  get inputFile(): HTMLInputElement {
    return this.$refs.inputFile as HTMLInputElement;
  }

  @Watch("pesaje")
  onPesajeChange(): void {
    this.fotoSeleccionada = null;
  }

  @Emit("click:cerrar")
  onClickCerrar(): void {
    return;
  }

  seleccionarFoto(foto: Archivo | BlobArchivo): void {
    this.fotoSeleccionada = foto;
  }

  clickAgregar(): void {
    this.inputFile.click();
  }

  clickEliminar(): void {
    const foto = this.fotoSeleccionada;
    if ((foto as Archivo)?.id) {
      this.imagenes.eliminar.push(foto as Archivo);
    } else {
      const index = this.imagenes.nuevas.indexOf(foto as BlobArchivo);
      this.imagenes.nuevas.splice(index, 1);
    }
    this.fotoSeleccionada = null;
  }

  async onChangeFileHandler(event: Event): Promise<void> {
    const file = (event.target as HTMLInputElement | null)?.files?.[0];
    if (file) {
      const imagenRaw = file;
      const fecha = new Date();
      const extencion = imagenRaw.type.split("/")[1];
      let nombre = `${fecha.valueOf()}.${extencion}`;
      let imagen = await imageToWebp(imagenRaw);
      if (imagen) {
        nombre = `${fecha.valueOf()}.webp`;
      } else {
        imagen = imagenRaw;
      }
      const fechaCarpeta = new Date(fecha);
      fechaCarpeta.setHours(0, 0, 0, 0);
      const nombreCarpeta = `${fechaCarpeta.valueOf()}`;
      const ref = `pesajes/${nombreCarpeta}/${nombre}`;
      this.imagenes.nuevas.push({
        ref: ref,
        file: imagen,
        nombre: nombre,
      });
    }
  }
}
