
// decoradores
import { Component, Emit, Prop, VModel, Vue } from "vue-property-decorator";
// tipos
import {
  Pesaje,
  PesajeData,
} from "@/typings/store/plugins/easyFirestore/pesajes";
import { Cliente } from "@/typings/store/plugins/easyFirestore/clientes";
import { Desecho } from "@/typings/store/plugins/easyFirestore/desechos";
import { Vehiculo } from "@/typings/store/plugins/easyFirestore/vehiculos";
import { Servicio } from "@/typings/store/plugins/easyFirestore/servicios";
import {
  EstadoRutaPesaje,
  RutaPesaje,
  TipoRutaPesaje,
} from "@/typings/store/plugins/easyFirestore/rutasPesaje";
// componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: {
    SvgIcon,
  },
})
export default class FormularioBascula extends Vue {
  @VModel() pesajeData!: PesajeData;
  @Prop() readonly pesaje!: Pesaje | null;
  @Prop() readonly arrayVehiculos!: Array<Vehiculo>;
  @Prop() readonly arrayDesechos!: Array<Desecho>;
  @Prop() readonly arrayServicios!: Array<Servicio>;
  @Prop() readonly arrayClientes!: Array<Cliente>;
  @Prop() readonly arrayRutasPesaje!: Array<RutaPesaje>;
  @Prop() readonly capacidadErrors!: Array<string>;
  @Prop() readonly vehiculoErrors!: Array<string>;
  @Prop() readonly desechoErrors!: Array<string>;
  @Prop() readonly servicioErrors!: Array<string>;
  @Prop() readonly clienteErrors!: Array<string>;
  @Prop() readonly rutaErrors!: Array<string>;
  @Prop({ type: Boolean }) readonly fijado!: boolean;
  @Prop({ type: Boolean }) readonly manual!: boolean;
  @Prop({ type: Boolean }) readonly guardando!: boolean;

  get vehiculo(): Vehiculo | null {
    const vehiculo = this.pesajeData.vehiculo || this.pesaje?.vehiculo || null;
    return vehiculo;
  }
  set vehiculo(value: Vehiculo | null) {
    let vehiculo: Vehiculo | null = null;
    if (typeof value === "string") {
      vehiculo = {
        patente: (value as string).toLowerCase(),
        tipo: "camion",
        tara: -1,
        alertaPeso: -1,
        marca: "",
        modelo: "",
        año: -1,
        combustible: "diesel",
        numeroChasis: "",
        numeroMotor: "",
        foto: null,
        estado: "habilitado",
      };
    } else {
      vehiculo = Object.assign({}, value);
    }
    this.$set(this.pesajeData, "vehiculo", vehiculo);
  }

  get desecho(): Desecho | null {
    const desecho = this.pesajeData.desecho || this.pesaje?.desecho || null;
    return desecho;
  }
  set desecho(value: Desecho | null) {
    const desecho = value ? Object.assign({}, value) : null;
    this.$set(this.pesajeData, "desecho", desecho);
  }

  get capacidad(): number {
    const capacidad = this.pesajeData.capacidad || this.pesaje?.capacidad || 0;
    return capacidad;
  }
  set capacidad(value: number) {
    const capacidad = value || 0;
    this.$set(this.pesajeData, "capacidad", capacidad);
  }

  get cobro(): number {
    const cobro = this.pesajeData.cobro || this.pesaje?.cobro || 0;
    return cobro;
  }
  set cobro(value: number) {
    const cobro = value || 0;
    this.$set(this.pesajeData, "cobro", cobro);
  }

  get servicio(): Servicio | null {
    const servicio = this.pesajeData.servicio || this.pesaje?.servicio || null;
    return servicio;
  }
  set servicio(value: Servicio | null) {
    const servicio = value ? Object.assign({}, value) : null;
    this.$set(this.pesajeData, "servicio", servicio);
  }

  get cliente(): Cliente | null {
    const cliente = this.pesajeData.cliente || this.pesaje?.cliente || null;
    return cliente;
  }
  set cliente(val: Cliente | null) {
    let cliente: Cliente | null;
    if (typeof val == "string") {
      cliente = {
        nombre: (val as string).toLowerCase(),
        rut: "",
        giro: "",
        ciudad: "",
        direccion: "",
        contacto: "",
        correo: "",
        foto: null,
        estado: "habilitado",
        tipo: "particular",
      };
    } else {
      cliente = Object.assign({}, val);
    }
    this.$set(this.pesajeData, "cliente", cliente);
  }

  get ruta(): RutaPesaje | null {
    const ruta = this.pesajeData.ruta || this.pesaje?.ruta || null;
    return ruta;
  }
  set ruta(val: RutaPesaje | null) {
    let ruta: RutaPesaje | null;
    if (typeof val == "string") {
      const data = (val as string).toLowerCase().split("-");
      const nombre = data[0] ? data[0].trim() : (val as string).toLowerCase();
      const tipo = data[1] ? data[1].trim() : "";
      const estado = "habilitado";
      ruta = {
        nombre: nombre,
        tipo: tipo as TipoRutaPesaje,
        descripcion: "",
        estado: estado as EstadoRutaPesaje,
      };
    } else {
      ruta = Object.assign({}, val);
    }
    this.$set(this.pesajeData, "ruta", ruta);
  }

  @Emit("click:limpiar")
  onClickLimpiar(): void {
    return;
  }

  @Emit("click:manual")
  onClickManual(): void {
    return;
  }

  @Emit("click:guardar")
  onClickGuardar(): void {
    return;
  }
}
