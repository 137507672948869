
// Vue
import {computed, defineComponent} from "vue";

// Helpers
import {log} from "@/helpers/env";

// Composables
import {useI18n} from "@/composables/i18n";

// Tipos
import {Pesaje} from "@/typings/store/plugins/easyFirestore/pesajes";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import DataTableFooter from "@/components/custom/DataTableFooter.vue";
import TooltipEstado from "@/components/bascula/tablaBascula/TooltipEstado.vue";

interface ItemTabla {
  salida: string;
  pesaje: Pesaje;
  estado: boolean;
  entrada: string;
  patente: string;
  pesoNeto: string;
}

export default defineComponent({
  name: "TablaBascula",
  components: {
    SvgIcon,
    TooltipEstado,
    DataTableFooter,
  },
  props: {
    pesajes: {
      required: true,
      type: Array as () => Pesaje[],
    },
    pesaje: {
      type: Object as () => Pesaje | null,
    },
  },
  emits: [
    "click:item",
    "click:imprimir",
    "click:imagenes",
    "click:sincronizar",
    "click:informacion",
  ],
  setup(props, ctx) {
    // Composables

    const {t} = useI18n();

    // Computed

    const headers = computed(() => [{
      text: "",
      value: "estado",
      sortable: false,
    }, {
      value: "entrada",
      text: text("headers.0"),
    }, {
      value: "salida",
      text: text("headers.1"),
    }, {
      value: "patente",
      text: text("headers.2"),
    }, {
      value: "pesoNeto",
      text: text("headers.3"),
    }, {
      text: "",
      sortable: false,
      value: "opciones",
    }]);

    const items = computed(() => {
      if (log) console.time("TablaBascula.items");
      const resultado = props.pesajes.map((pesaje) => {
        const estado = pesaje.estado === "finalizado";
        const patente = pesaje.vehiculo?.patente ?? "-";
        const fechaEntradaString = pesaje.entrada?.fecha;
        const entrada = fechaEntradaString ? fechaEntradaString.toDate().format("HH:mm") : "-";
        const fechaSalidaString = pesaje.salida?.fecha;
        const salida = fechaSalidaString ? fechaSalidaString.toDate().format("HH:mm") : "-";
        const mayoresCero = pesaje.entrada.peso > 0 && pesaje.salida.peso > 0;
        const peso = pesaje.entrada.peso - pesaje.salida.peso;
        const pesoFinal = pesaje.servicio?.tipo === "Egreso" ? peso * -1 : peso
        const pesoNeto = mayoresCero ? pesoFinal.toCurrency() : "-";
        return {
          estado,
          salida,
          pesaje,
          entrada,
          patente,
          pesoNeto,
        };
      });
      if (log) console.timeEnd("TablaBascula.items");
      return resultado;
    });

    // Methods

    const text = (key: string) => t(`bascula.tabla.${key}`) as string;

    const seleccionado = ({pesaje}: { pesaje: Pesaje }) => pesaje.id === props.pesaje?.id;

    const clickItem = ({pesaje}: { pesaje: Pesaje }) => {
      if (pesaje.estado !== "finalizado") {
        onClickItem(pesaje);
      }
    };

    const getClasses = ({pesaje}: ItemTabla, textTransform?: "capitalize" | "uppercase") => {
      const seleccionado = props.pesaje?.id === pesaje.id;
      const classes = ['pa-0 metropolis text-center text-body-1'];
      if (textTransform === "capitalize") classes.push("text-capitalize");
      if (textTransform === "uppercase") classes.push("text-uppercase");
      if (seleccionado) classes.push("primary--text font-weight-bold");
      return classes.join(" ");
    };

    // Emits

    const onClickImprimir = (pesaje: Pesaje) => {
      ctx.emit("click:imprimir", pesaje.id ?? "");
    };

    const onClickImagenes = (pesaje: Pesaje) => {
      ctx.emit("click:imagenes", pesaje);
    };

    const onClickInformacion = (pesaje: Pesaje) => {
      ctx.emit("click:informacion", pesaje);
    };

    const onClickItem = (pesaje: Pesaje) => {
      ctx.emit("click:item", pesaje);
    };

    const onClickSincronizar = (pesaje: Pesaje) => {
      ctx.emit("click:sincronizar", pesaje);
    };

    return {
      // Computed
      items,
      headers,
      // Methods
      text,
      clickItem,
      getClasses,
      seleccionado,
      // Emits
      onClickItem,
      onClickImprimir,
      onClickImagenes,
      onClickSincronizar,
      onClickInformacion,
    };
  },
});
