
//decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
//tipos
import { Chofer } from "@/typings/store/plugins/easyFirestore/choferes";
import { Permisos } from "@/typings/store/modules/permisos";
import { Identificador } from "@/typings/store/plugins/easyFirestore/identificadores";
import { Item } from "@/typings/components/transporte/identificadores";
//componentes
import DataTableFooter from "@/components/custom/DataTableFooter.vue";
import OpcionesTabla from "@/components/transporte/identificadores/tabla/Opciones.vue";

@Component({
  components: {
    DataTableFooter,
    OpcionesTabla,
  },
})
export default class TablaIdentificadores extends Vue {
  @Prop() readonly arrayIdentificadores!: Array<Identificador>;
  @Prop() readonly search!: string;
  @Prop() readonly identificador!: Identificador | null;
  @Prop() readonly permisos!: Permisos;

  itemsPerPage = 9;

  get mostrarSwitch(): boolean {
    return this.permisos.transporte !== "ver";
  }

  get headers(): Array<{ value: string; text: string; sortable?: boolean }> {
    return [
      {
        text: this.$t(
          "transporte.tab.identificadores.tabla.headers[0]"
        ) as string,
        value: "nombre",
      },
      {
        text: this.$t(
          "transporte.tab.identificadores.tabla.headers[1]"
        ) as string,
        value: "epc",
      },
      {
        text: this.$t(
          "transporte.tab.identificadores.tabla.headers[2]"
        ) as string,
        value: "vehiculo",
      },
      {
        text: "",
        value: "opciones",
        sortable: false,
      },
    ];
  }

  get items(): Array<Item> {
    return this.arrayIdentificadores.map((identificador) => {
      const id = identificador.id ?? "";
      const nombre = identificador.nombre;
      const epc = identificador.epc;
      const vehiculo =
        identificador.vehiculo?.patente ??
        (this.$t("transporte.tab.identificadores.no-value-message") as string);
      return {
        id,
        nombre,
        epc,
        vehiculo,
        identificador,
      };
    });
  }

  @Emit("click:switch-estado")
  onClickSwitchEstado(chofer: Identificador): Identificador {
    return chofer;
  }

  @Emit("click:informacion")
  onClickInformacion(chofer: Chofer): Chofer {
    return chofer;
  }

  getClasses({ identificador }: Item): {
    capitalize: string;
    uppercase: string;
    normal: string;
  } {
    const seleccionado = this.identificador?.id === identificador.id;
    return {
      capitalize: `pa-0 metropolis text-center text-body-1 text-capitalize ${
        seleccionado ? "primary--text font-weight-bold" : ""
      }`,
      uppercase: `pa-0 metropolis text-center text-body-1 text-uppercase ${
        seleccionado ? "primary--text font-weight-bold" : ""
      }`,
      normal: `pa-0 metropolis text-center text-body-1 ${
        seleccionado ? "primary--text font-weight-bold" : ""
      }`,
    };
  }
  clickSwitchEstado({ identificador }: Item): void {
    this.onClickSwitchEstado(identificador);
  }

  onResize(): void {
    const height = window.innerHeight;
    this.itemsPerPage = Math.floor((height * 0.96 - 439) / 50);
  }
}
